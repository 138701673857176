import React from 'react'
import InfoSelectorItem from './InfoSelectorItem'
import { LolAccountResponse } from '../../../../types/account/lol-account-response'
import { StaticImageData } from 'next/image'
import IconChampion from '../../../../public/static/product/account/svg/icon-champion.svg'
import IconSkin from '../../../../public/static/product/account/svg/icon-skin.svg'

type Props = {
  accountProduct: LolAccountResponse | null
  currentOptionState: [
    infoOption,
    React.Dispatch<React.SetStateAction<infoOption>>,
  ]
}

export type infoOption = {
  value: string
  image: StaticImageData
}

export const option: infoOption[] = [
  {
    value: 'Champions',
    image: IconChampion,
  },
  {
    value: 'Skins',
    image: IconSkin,
  },
]

export default function InfoSelector({
  accountProduct,
  currentOptionState,
}: Props) {
  const [currentOption, setCurrentOption] = currentOptionState
  return (
    <div className={'flex flex-row items-center gap-4 shrink-0'}>
      {option.map((option) => {
        return (
          <InfoSelectorItem
            key={option.value}
            checked={currentOption.value === option.value}
            onClick={() => {
              setCurrentOption(option)
            }}
            number={
              option.value === 'Champions'
                ? accountProduct?.champions
                : accountProduct?.skins
            }
            value={option.value}
            image={option.image}
          />
        )
      })}
      {/*<InfoSelectorItem*/}
      {/*  checked={true}*/}
      {/*  value={'Champions'}*/}
      {/*  number={accountProduct?.champions}*/}
      {/*/>*/}
      {/*<InfoSelectorItem*/}
      {/*  checked={false}*/}
      {/*  value={'Skins'}*/}
      {/*  number={accountProduct?.skins}*/}
      {/*/>*/}
    </div>
  )
}
