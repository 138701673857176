import { NotificationStorage } from '../../types/users/user-local-storage'

const LOCALSTORAGE_KEY = 'enable_notification'

// helper to get user from localstorage
export function getStoredNotification(): NotificationStorage | null {
  try {
    if (typeof window === 'undefined') {
      return null
    }

    const storage = localStorage.getItem(LOCALSTORAGE_KEY)
    return storage ? JSON.parse(storage) : null
  } catch (e) {
    return {
      showNotification: false,
    }
  }
}

export function setStoredNotification(user: NotificationStorage): void {
  try {
    if (typeof window === 'undefined') {
      return
    }
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(user))
  } catch (e) {}
}

export function updateStoredNotification(show: boolean): void {
  try {
    if (typeof window === 'undefined') {
      return
    }

    let storage = getStoredNotification()
    if (storage) {
      storage.showNotification = show
      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(storage))
    } else {
      setStoredNotification({ showNotification: show })
    }
  } catch (e) {}
}

export function clearStoredNotification(): void {
  try {
    if (typeof window === 'undefined') {
      return
    }
    localStorage.removeItem(LOCALSTORAGE_KEY)
  } catch (e) {}
}
