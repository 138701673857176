import { StaticImageData } from 'next/image'
import IconTrascendent from '../../../../../public/static/product/account/svg/rank/icon-trascendent.png'
import IconUltimate from '../../../../../public/static/product/account/svg/rank/icon-ultimate.png'
import IconMythic from '../../../../../public/static/product/account/svg/rank/icon-mythic.png'
import IconLegendary from '../../../../../public/static/product/account/svg/rank/icon-legendary.png'
import IconEpic from '../../../../../public/static/product/account/svg/rank/icon-epic.png'
import IconChroma from '../../../../../public/static/product/account/svg/rank/icon-chroma.png'

export interface SkinRank {
  name: string
  color: string
  image?: StaticImageData
}

export interface SkinRank {
  name: string
  color: string
  image?: StaticImageData
}

export default function useSkinCard() {
  const rankMapping = {
    kTrascendent: 'Trascendent',
    kUltimate: 'Ultimate',
    kMythic: 'Mythic',
    kLegendary: 'Legendary',
    kEpic: 'Epic',
    kChroma: 'Chroma',
    kNoRarity: 'Standard',
  }

  const ranks: SkinRank[] = [
    {
      name: 'Trascendent',
      color: 'border-skin-trascendent',
      image: IconTrascendent,
    },
    {
      name: 'Ultimate',
      color: 'border-skin-ultimate',
      image: IconUltimate,
    },
    {
      name: 'Mythic',
      color: 'border-skin-mythic',
      image: IconMythic,
    },
    {
      name: 'Legendary',
      color: 'border-skin-legendary',
      image: IconLegendary,
    },
    {
      name: 'Epic',
      color: 'border-skin-epic',
      image: IconEpic,
    },
    {
      name: 'Chroma',
      color: 'border-skin-chroma',
      image: IconChroma,
    },
    {
      name: 'Standard',
      color: 'border-skin-standard',
    },
  ]

  const defaultRank = ranks[6]

  function getMatchedRank(rankKey: string): SkinRank | undefined {
    const rankName = rankMapping[rankKey as keyof typeof rankMapping]
    return ranks.find((rank) => rank.name === rankName)
  }

  return {
    getMatchedRank,
    ranks,
    defaultRank,
  }
}
