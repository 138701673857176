import React from 'react'

export type Props = {
  text: string
  boldWord: string
  color: string
}

export default function SubtitleStringBuilder({
  text,
  boldWord,
  color,
}: Props) {
  const parts = text.split(new RegExp(`(${boldWord})`, 'g'))

  return (
    <h2 className='text-center text-lg font-medium text-darul-100 md:text-lg'>
      {parts?.map((part, index) =>
        part === boldWord ? (
          <span key={index} className={`font-bold ${color}`}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </h2>
  )
}
