import OfflineIcon from '../../../../public/static/select-mode/lobby/friendlist/icon-offline.svg'
import InSessionIcon from '../../../../public/static/select-mode/lobby/friendlist/icon-in-session.svg'
import AvailableIcon from '../../../../public/static/select-mode/lobby/friendlist/icon-available.svg'
import { UserResponse } from '../../../../types/users/user-response'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export default function useFriendListCard() {
  function statusIcon(onDuty: boolean, inSession: boolean) {
    if (inSession) {
      return InSessionIcon
    }

    if (onDuty) {
      return AvailableIcon
    }

    return OfflineIcon
  }

  function statusColor(onDuty: boolean, inSession: boolean) {
    if (inSession) {
      return 'text-nami-400'
    }

    if (onDuty) {
      return 'text-thresh-400'
    }

    return 'text-fizz-0'
  }

  const timeAgo = (inSessionSeconds: number) => {
    const diff = dayjs.duration(inSessionSeconds, 'seconds')

    if (diff.asHours() >= 1) {
      return `${Math.floor(diff.asHours())}h ago`
    } else {
      return `${Math.floor(diff.asMinutes())}m ago`
    }
  }

  function getStatus(onDuty: boolean, inSession: boolean, user?: UserResponse) {
    if (user?.presenceStatus?.status === 'offline') {
      return 'Offline'
    }

    if (inSession) {
      if (
        user?.presenceStatus?.inSessionSeconds &&
        (user?.presenceStatus?.inSessionSeconds ?? 0) !== 0
      ) {
        return `In session • ${timeAgo(user?.presenceStatus?.inSessionSeconds ?? 0)}`
      }
      return 'In session'
    }

    if (onDuty) {
      return 'Available now'
    }

    return 'Offline'
  }

  function defaultRank(gameKey: string) {
    switch (gameKey) {
      case 'league-of-legends':
        return 'Grandmaster'
      case 'valorant':
        return 'Radiant'
      case 'fortnite':
        return 'Unreal'
      case 'csgo':
        return 'Yellow: 30,000+'
      default:
        return ''
    }
  }

  // @ts-ignore
  function getRank(teammate?: UserResponse, gameKey?: string) {
    // Navigate through the nested structure to find the rank
    return teammate?.categoryProfiles
      ?.find((item) => item.isTeammate && item.categoryKey === gameKey)
      ?.categorySpecializedTags?.find((tag) => tag.parent.name === 'Rank')?.name
  }

  const tierRanksArray: {
    title: string
    type: 's' | 'a' | 'b' | 'c'
    percent: string
  }[] = [
    {
      title: 'S+',
      type: 's',
      percent: '0.1',
    },
    {
      title: 'S',
      type: 's',
      percent: '1',
    },
    {
      title: 'S-',
      type: 's',
      percent: '5',
    },
    {
      title: 'A+',
      type: 'a',
      percent: '10',
    },
    {
      title: 'A',
      type: 'a',
      percent: '12',
    },
    {
      title: 'A-',
      type: 'a',
      percent: '15',
    },
    {
      title: 'B+',
      type: 'b',
      percent: '20',
    },
    {
      title: 'B',
      type: 'b',
      percent: '22',
    },
    {
      title: 'B-',
      type: 'c',
      percent: '25',
    },
    // {
    //   title: 'C',
    //   type: 'c',
    //   percent: '35',
    // },
    // {
    //   title: 'C-',
    //   type: 'c',
    //   percent: '40',
    // },
  ]

  function findTierRankByLetter(performance_score_letter?: string) {
    return (
      tierRanksArray.find(
        (tierRank) => tierRank.title === performance_score_letter,
      ) ?? tierRanksArray[tierRanksArray.length - 1]
    )
  }

  return {
    statusIcon,
    statusColor,
    getStatus,
    getRank,
    defaultRank,
    tierRanksArray,
    findTierRankByLetter,
  }
}
