import { authFetch } from '../../common/networking/auth-fetch'

import { LegendaryFreeGamesResponse } from '../../types/loyalty/legendary-free-games.response'

export async function getLoyaltyFreeGame(
  accessToken: any,
): Promise<{ count: number }> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/loyalty-programs/free-games-count`,
  )

  return await response.json()
}
export async function getLegendaryFreeGameState(
  accessToken: any,
): Promise<LegendaryFreeGamesResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/loyalty-programs/legendary-free-games/states`,
  )

  return await response.json()
}
