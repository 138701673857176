import { GetOptions } from '@fingerprintjs/fingerprintjs-pro'
import {
  CacheKey,
  CacheManager,
  CacheStub,
  ICache,
  InMemoryCache,
  LocalStorageCache,
  SessionStorageCache,
  VisitorData,
} from '../common/cache'
import { CacheLocation } from '../common/cache/cache-location.enum'

const scriptUrl = `https://endpoints.tapin.gg/tchQXN8kqu5fuw1ywj/tch1bcj5drf1HRNmjz?apiKey=${process.env.NEXT_PUBLIC_FINGERPRINTJS_API_KEY}&loaderVersion=3.11.5`
const endpointUrl =
  'https://endpoints.tapin.gg/tchQXN8kqu5fuw1ywj/uteHCT9pbq5ywm4equ?region=us'
const inFlightRequests = new Map<string, Promise<VisitorData>>()

const cacheLocationBuilders: Record<
  CacheLocation,
  (prefix?: string) => ICache
> = {
  [CacheLocation.Memory]: () => new InMemoryCache().enclosedCache,
  [CacheLocation.LocalStorage]: (prefix) => new LocalStorageCache(prefix),
  [CacheLocation.SessionStorage]: (prefix) => new SessionStorageCache(prefix),
  [CacheLocation.NoCache]: () => new CacheStub(),
}

const doesBrowserSupportCacheLocation = (cacheLocation: CacheLocation) => {
  switch (cacheLocation) {
    case CacheLocation.SessionStorage:
      try {
        window.sessionStorage.getItem('item')
      } catch (e) {
        return false
      }
      return true
    case CacheLocation.LocalStorage:
      try {
        window.localStorage.getItem('item')
      } catch (e) {
        return false
      }
      return true
    default:
      return true
  }
}

const cacheFactory = (location: CacheLocation) => {
  return cacheLocationBuilders[location]
}

export async function fetchFingerprint() {
  try {
    // For most use cases, caching visitor IDs for 3 hour is sufficient to avoid over-utilization without affecting identification accuracy
    const cacheTime = 60 * 60 * 3
    let cache: ICache
    let cacheLocation: CacheLocation
    cacheLocation = CacheLocation.LocalStorage

    if (!doesBrowserSupportCacheLocation(cacheLocation)) {
      cacheLocation = CacheLocation.Memory
    }

    cache = cacheFactory(cacheLocation)(undefined)

    const cacheManager = new CacheManager(cache, cacheTime)

    const cacheKey = makeCacheKey({})
    const key = cacheKey.toKey()

    if (!inFlightRequests.has(key)) {
      const promise = _identify({}, false, cacheManager).finally(() => {
        inFlightRequests.delete(key)
      })
      inFlightRequests.set(key, promise)
    }
    return await inFlightRequests.get(key)
  } catch (e) {
    const data = await getFingerprintData()
    return {
      visitorId: data?.visitorId,
      requestId: data?.requestId,
      cacheHit: false,
    }
  }
}

/**
 * Makes a CacheKey object from GetOptions
 */
function makeCacheKey<TExtended extends boolean>(
  options: GetOptions<TExtended>,
) {
  return new CacheKey<TExtended>(options)
}

async function _identify<TExtended extends boolean>(
  options: GetOptions<TExtended>,
  ignoreCache = false,
  cacheManager: CacheManager,
) {
  const key = makeCacheKey(options)

  if (!ignoreCache) {
    const cacheResult = await cacheManager.get(key)

    if (cacheResult) {
      return {
        ...cacheResult,
        cacheHit: true,
      }
    }
  }

  const agentResult = await getFingerprintData()

  await cacheManager.set(key, agentResult)
  return {
    ...agentResult,
    cacheHit: false,
  }
}

async function getFingerprintData() {
  const FingerprintJS = (await import('@fingerprintjs/fingerprintjs-pro'))
    .default
  const fpPromise = FingerprintJS.load({
    apiKey: process.env.NEXT_PUBLIC_FINGERPRINTJS_API_KEY!,
    scriptUrlPattern: scriptUrl,
    endpoint: endpointUrl,
  })

  const fp = await fpPromise
  return fp.get()
}
