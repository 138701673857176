import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { getMarketplaceAccountsSkins } from '../../api/account/account'

import { LolAccountSkin } from '../../types/account/lol-account-skin'

export function useGetAccountSkins(id: string) {
  const { data } = useSession()

  return useQuery<LolAccountSkin[]>(
    ['Lol Accounts Skins', id],
    //@ts-ignore
    async () => getMarketplaceAccountsSkins(id, data?.accessToken),
  )
}
