import React, { useState } from 'react'
import Image from 'next/image'
import CloseGray from '../../../public/static/close-gray.png'
import AddGreen from '../../../public/static/add-green.png'

interface Props {
  title: string
  description: string
  hasDivider?: boolean
}

export default function FAQItem({
  title,
  description,
  hasDivider = false,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className='w-full rounded-xl bg-fizz-900 p-4 border border-fizz-600'>
      <div
        className='flex w-full cursor-pointer items-center justify-between'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h3 className='text-[15px] font-medium text-white'>{title}</h3>
        <Image
          src={isExpanded ? CloseGray : AddGreen}
          alt={isExpanded ? 'Close' : 'Open'}
          width={20}
          height={20}
          className='transition-transform duration-200'
        />
      </div>

      {isExpanded && (
        <div className='mt-4 text-fizz-100'>
          <p className='text-[15px] leading-relaxed'>{description}</p>
        </div>
      )}
    </div>
  )
}
