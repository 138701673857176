import React from 'react'
import { TitlePart } from './TitlePart'
import remarkBreaks from 'remark-breaks'
import ReactMarkdown from 'react-markdown'
import { ProductResponse } from '../../../types/products/product-response'

type Props = { product: ProductResponse }

export function DescriptionSection({ product }: Props) {
  return (
    <div>
      <TitlePart title={'DESCRIPTION'} />

      <div className={'article'}>
        <ReactMarkdown
          remarkPlugins={[remarkBreaks]}
          className={'markdown text-body-normal mt-2 text-white'}
          linkTarget={'_blank'}
        >
          {product.longFormDescription ?? ''}
        </ReactMarkdown>
      </div>
    </div>
  )
}
