'use client'

import React from 'react'
import { usePathname } from 'next/navigation'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import * as pixel from '../lib/fpixel'

const FacebookPixel = () => {
  const [loaded, setLoaded] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    if (!loaded) return

    pixel.pageview()
  }, [pathname, loaded])

  return (
    <div>
      <Script
        id='fb-pixel'
        src='/static/scripts/pixel.js'
        strategy='afterInteractive'
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}

export default FacebookPixel
