import { useQuery } from '@tanstack/react-query'
import { getMeCategoryProfileByTag } from '../../api/categories/category-tag'
import { CategoryProfileResponse } from '../../types/users/category-profile-response'
import { useSession } from 'next-auth/react'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { categoryProfiles } from '../../common/react-query/react-query-keys'

export const useGetMeCategoryProfileByTag = (key: string) => {
  const { data, status } = useSession()

  return useQuery<CategoryProfileResponse[]>(
    [categoryProfiles, key],
    //@ts-ignore
    async () => getMeCategoryProfileByTag(data?.accessToken, key),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}
