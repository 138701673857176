import { logout } from '../utils/logout'
import { getSession } from 'next-auth/react'

export function authFetch(accessToken?: any) {
  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
  }

  function request(method: string) {
    return async (url: string, body?: any) => {
      const requestOptions = await createHeader(method)

      if (body) {
        // @ts-ignore
        requestOptions.headers['Content-Type'] = 'application/json'
        requestOptions.body = JSON.stringify(body)
      }

      // @ts-ignore
      requestOptions.headers['Accept'] = 'application/json'
      return fetch(url, requestOptions).then((response) =>
        handleResponse(response, requestOptions, url),
      )
    }
  }

  async function createHeader(method: string): Promise<RequestInit> {
    if (accessToken) {
      return {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    } else {
      return {
        method: method,
        headers: {},
      }
    }
  }

  async function handleResponse(
    response: Response,
    requestOptions: RequestInit,
    url: string,
  ) {
    if (
      response.status === 401 &&
      requestOptions.headers &&
      // @ts-ignore
      requestOptions.headers['Authorization']
    ) {
      try {
        const refreshedSession = await getSession()
        // @ts-ignore
        if (refreshedSession && refreshedSession.access_token) {
          // @ts-ignore
          requestOptions.headers['Authorization'] =
            // @ts-ignore
            `Bearer ${refreshedSession.access_token}`
          const response = await fetch(url, requestOptions) // Retry with new token

          if (!response.ok) {
            if (
              [401].includes(response.status) &&
              requestOptions.headers &&
              //@ts-ignore
              requestOptions.headers.Authorization
            ) {
              await logout()
              location.href = '/'
            }
            throw await response.json()
          }

          return response
        }
      } catch (error) {
        throw error
      }
    }

    if (!response.ok) {
      throw await response.json()
    }

    return response
  }

  // async function handleResponse(
  //   response: Response,
  //   requestOptions: RequestInit,
  // ) {
  //   if (!response.ok) {
  //     if (
  //       [401].includes(response.status) &&
  //       requestOptions.headers &&
  //       //@ts-ignore
  //       requestOptions.headers.Authorization
  //     ) {
  //       await logout()
  //       location.href = '/'
  //     }
  //     throw await response.json()
  //   }
  //
  //   return response
  // }
}
