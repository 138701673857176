import React from 'react'
import StripePaymentButton from './StripePaymentButton'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useSession } from 'next-auth/react'
import { useRecoilState } from 'recoil'
import { isDefaultMethodLoadedState } from '../../common/atoms/checkout'
import { useTranslation } from 'next-i18next'
import { AddonResponse } from '../../types/addons/responses/AddonResponse'
import ConfirmButton from '../select-game/game-lobby/confirmbtn/ConfirmButton'

type Props = {
  onSubmit: () => void
  isLoading: boolean
  title?: string
  theme?: string
  total?: number
  showAnimatedEffect?: boolean
  addons?: AddonResponse[]
  hexagonalButton?: boolean
  waitingForInvite?: boolean
}

export default function PaymentButton({
  onSubmit,
  title,
  theme = 'light',
  total,
  isLoading,
  showAnimatedEffect = false,
  addons = undefined,
  hexagonalButton,
  waitingForInvite,
}: Props) {
  const { t: booking } = useTranslation('booking')
  const { status } = useSession()
  const [isDefaultMethodLoaded] = useRecoilState(isDefaultMethodLoadedState)
  const buttonText = `${title ?? booking('confirmButtonTitle')} •`
  if (
    hexagonalButton &&
    !isDefaultMethodLoaded &&
    status !== SessionStatusEnum.UNAUTHENTICATED
  ) {
    return (
      <ConfirmButton
        onClick={() => {
          if (isLoading) return
          onSubmit()
        }}
        buttonText={buttonText}
        isLoading={isLoading}
      />
    )
  }

  if (!isDefaultMethodLoaded && status !== SessionStatusEnum.UNAUTHENTICATED) {
    return (
      <button className={'btn-v2-primary w-full font-fjalla text-xl'}>
        {buttonText}
      </button>
    )
  }

  return (
    <div className={'flex w-full flex-col gap-2.5'}>
      <StripePaymentButton
        onSubmit={() => {
          onSubmit()
        }}
        isLoading={isLoading}
        total={total}
        theme={theme}
        title={title}
        showAnimatedEffect={showAnimatedEffect}
        addons={addons}
        hexagonalButton={hexagonalButton}
        waitingForInvite={waitingForInvite}
      />
    </div>
  )
}
