import React from 'react'
import { useRecoilState } from 'recoil'
import {
  isOpenLogInModalState,
  isOpenSignUpModalState,
} from '../../common/atoms/checkout'
import { useTranslation } from 'next-i18next'

export function AuthButtonsV2() {
  const [, setIsOpenSignUp] = useRecoilState(isOpenSignUpModalState)
  const [, setIsOpenLogin] = useRecoilState(isOpenLogInModalState)
  const { t } = useTranslation('common')

  return (
    <div className='z-30 flex flex-row gap-2'>
      <button
        type='submit'
        className={
          'btn-v2-white h-12 border-none w-24 uppercase hover:opacity-60 md:w-28'
        }
        onClick={(e) => {
          setIsOpenLogin(true)
        }}
      >
        {t('loginPageTitle')}
      </button>

      <button
        type='submit'
        className={
          'btn-v2-primary h-12 w-24 uppercase hover:opacity-60 md:w-28'
        }
        onClick={(e) => {
          setIsOpenSignUp(true)
        }}
      >
        {t('signUpPageTitle')}
      </button>
    </div>
  )
}
