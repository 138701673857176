import { authFetch } from '../../common/networking/auth-fetch'
import { MarketingOffersResponse } from '../../types/marketing/response/MarketingOffersResponse'
import { BlackFridayResponse } from '../../types/marketing/response/BlackFridayResponse'

export async function getMarketingOffers(
  accessToken: any,
): Promise<MarketingOffersResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/marketing/offers`,
  )
  return await response.json()
}

export async function putMarketingOffersById(
  accessToken: any,
  id: string,
): Promise<Response> {
  return await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/marketing/offers/${id}`,
  )
}

export async function getBlackFriday(): Promise<BlackFridayResponse> {
  const response = await authFetch().get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/marketing/bf/count-down`,
  )
  return await response.json()
}
