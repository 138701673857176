import React from 'react'
import { RecoilState, useRecoilState } from 'recoil'

type Props = {
  isOpenState?: RecoilState<boolean>
  isOpenStateLocal?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  btnColor?: string
}

export default function ModalCloseButton({
  isOpenState,
  isOpenStateLocal,
  btnColor = '',
}: Props) {
  // / Use RecoilState if available, otherwise use local state

  const [, setIsOpen] = isOpenState // eslint-disable-next-line react-hooks/rules-of-hooks
    ? useRecoilState(isOpenState)
    : isOpenStateLocal || [false, () => {}]

  // If neither state is provided, don't render the component
  if (!isOpenState && !isOpenStateLocal) {
    return null
  }

  return (
    <div className='absolute right-0 top-0 pr-5 pt-5'>
      <button
        type='button'
        className='focus:outline-none focus:ring-0'
        onClick={() => setIsOpen(false)}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
        >
          <path
            d='M9.33325 9.33331L15.9999 16M22.6666 22.6666L15.9999 16M15.9999 16L22.6666 9.33331M15.9999 16L9.33325 22.6666'
            stroke='#050608'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            className={` ${btnColor}`}
          />
        </svg>
      </button>
    </div>
  )
}
