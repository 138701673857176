import React from 'react'
import { ProductResponse } from '../../../types/products/product-response'
import { TitlePart } from './TitlePart'
import { BenefitPart } from './BenefitPart'

type Props = { product: ProductResponse }

export function BenefitSection({ product }: Props) {
  return (
    <div>
      <TitlePart title={'WHAT YOU GET'} />

      <div
        className={
          'mt-2 grid w-full grid-cols-1 gap-x-6 gap-y-2.5 sm:grid-cols-1'
        }
      >
        {product?.whatYouGet?.map((item) => (
          <BenefitPart key={item} title={item} />
        ))}
      </div>
    </div>
  )
}
