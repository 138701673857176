import { CategoryProfileResponse } from '../../types/users/category-profile-response'

export function server(profiles: CategoryProfileResponse[] | undefined) {
  return (
    profiles
      ?.find((item) => {
        return item.isSelected
      })
      ?.categorySpecializedTags?.find((item) => {
        return item.parent.name === 'Regions'
      })?.name ?? 'undefined'
  )
}

export function ign(profiles: CategoryProfileResponse[] | undefined) {
  return (
    profiles?.find((item) => {
      return item.isSelected
    })?.username ?? 'undefined'
  )
}
