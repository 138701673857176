import React from 'react'
import { useRecoilState } from 'recoil'
import { isBlinkingState } from './FriendListSection'

type Props = {
  title: string
  isOpen: boolean
  onClick?: () => void
}
export default function GroupHeader({ title, isOpen, onClick }: Props) {
  const [isBlinking] = useRecoilState(isBlinkingState)

  return (
    <div
      className={`h-[45px] px-3  flex flex-row w-full gap-2.5 items-center cursor-pointer ${isBlinking ? 'blinking' : 'hover:bg-fizz-500 bg-fizz-600'} transition duration-300`}
      onClick={(e) => {
        e.preventDefault()
        onClick && onClick()
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='17'
        viewBox='0 0 16 17'
        fill='none'
        className={`${!isOpen && 'rotate-180'}`}
      >
        <path d='M8 11.5L2 5.5H14L8 11.5Z' fill='white' />
      </svg>

      <p className={'text-white text-body-bold'}>{title}</p>
    </div>
  )
}
