export const FAQItems = [
  {
    questionName: 'How long does it take to match with someone?',
    acceptedAnswerText:
      'You can see the live estimate under the game mode you’re interested in. Often, it’s under 2 minutes. Every order on TapIn is **on-demand only. We don’t want to make you wait or schedule a game, ever**. Your time is more valuable.',
  },
  {
    questionName:
      'If I don’t like my teammate, can I change it to someone else?',
    acceptedAnswerText:
      'Yes! You will be given at least 2 complimentary re-roll every time you order. Re-rolls will send your order back in queue and be matched again, quickly, with someone new and better for you.',
  },
  {
    questionName: 'What if I lose my game?',
    acceptedAnswerText:
      'We cannot guarantee the outcome of your game as there are too many factors involved, including your own skill. However, most users get a **much higher win rate using TapIn than without in our compete game mode**. In hangout or chill mode, winning is generally not the goal.',
  },
  {
    questionName: 'What is your cancellation policy?',
    acceptedAnswerText:
      'If your order takes longer than 4 minutes to match, you are eligible to cancel your order for free with no penalty. If you are not satisfied with your teammate after your game, please contact us and we will try our best to either give you another game or find you a new teammate.',
  },
  {
    questionName: 'How do I recharge my balance?',
    acceptedAnswerText:
      'You can pay per game by charging your card automatically in your wallet or pre pay credits by buying them from our credits store. Currently, we offer bonus credits if you buy larger packages, starting at $50. **We highly recommend buying credits if you play often.**',
  },
]

export const FAQItems2 = [
  {
    questionName: 'What happens if I’m not satisfied with my session?',
    acceptedAnswerText:
      'We aim for complete satisfaction with every session. However, if you feel your experience did not meet expectations, Tapin.gg offers options such as a free redo of the session or a partial refund. We value your feedback and continuously work to enhance our services. For any concerns, our 24/7 customer support is here to assist you immediately.',
  },
  {
    questionName: 'Is it safe to play with a Tapin teammate?',
    acceptedAnswerText:
      "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
  },
  {
    questionName: 'How long does it take to match with someone?',
    acceptedAnswerText:
      'You can see the live estimate under the game mode you’re interested in. Often, it’s under 2 minutes. Every order on TapIn is **on-demand only. We don’t want to make you wait or schedule a game, ever**. Your time is more valuable.',
  },
  {
    questionName:
      'If I don’t like my teammate, can I change it to someone else?',
    acceptedAnswerText:
      'Yes! You will be given at least 2 complimentary re-roll every time you order. Re-rolls will send your order back in queue and be matched again, quickly, with someone new and better for you.',
  },
  {
    questionName: 'What is your cancellation policy?',
    acceptedAnswerText:
      'If your order takes longer than 4 minutes to match, you are eligible to cancel your order for free with no penalty. If you are not satisfied with your teammate after your game, please contact us and we will try our best to either give you another game or find you a new teammate.',
  },
]
