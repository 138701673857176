import ChampionSelector from '../dashboard/settings/games/modal/ChampionSelector'
import React from 'react'
import { useGetChampions } from '../../hooks/champion/useChampion'
import { useRecoilState } from 'recoil'
import { selectedChampionsState } from '../../common/atoms/checkout'

type Props = {
  categoryKey?: string
}

export default function ChampionsAddonSection({ categoryKey }: Props) {
  const { data: champions } = useGetChampions(categoryKey)
  const [, setChampions] = useRecoilState(selectedChampionsState)

  return (
    <div className={'pb-1'}>
      <h6 className={'text-body-medium self-start text-[#F87B20]'}>
        Select your champion
      </h6>

      <div className={'mt-1.5'}></div>
      <ChampionSelector
        champions={champions ?? []}
        current={undefined}
        onChange={(champion) => {
          if (champion) setChampions([champion])
        }}
      />
    </div>
  )
}
