import React from 'react'

type Props = {
  title: string
  type?: 's' | 'a' | 'b' | 'c'
  isNew?: boolean
}
export default function FriendCardTag({ isNew, title, type = 's' }: Props) {
  function tagColor() {
    switch (type) {
      case 's':
        return 'fill-gnar-400'
      case 'a':
        return 'fill-ashe-400'
      case 'b':
        return 'fill-thresh-300'
      case 'c':
        return 'fill-veigar-300'
      default:
        return 'fill-thresh-300'
    }
  }

  if (isNew) {
    return (
      <svg
        width='40'
        height='46'
        viewBox='0 0 40 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4 0.5H36C37.933 0.5 39.5 2.067 39.5 4V34.3433C39.5 35.7777 38.6248 37.0669 37.2916 37.5962L21.2916 43.9492C20.462 44.2786 19.538 44.2786 18.7084 43.9492L2.70839 37.5962C1.37523 37.0669 0.5 35.7777 0.5 34.3433V4C0.5 2.067 2.067 0.5 4 0.5Z'
          fill='#0F1219'
          stroke='url(#paint0_linear_2342_4431)'
        />
        <path
          d='M14.6641 27.1504C14.0306 27.1504 13.5065 27.0638 13.0918 26.8906C12.6771 26.7174 12.3695 26.4668 12.1689 26.1387C11.973 25.8105 11.8454 25.4072 11.7861 24.9287C11.7588 24.7145 11.7451 24.4867 11.7451 24.2451C11.7451 23.9489 11.7656 23.6344 11.8066 23.3018L12.6064 15.666H14.8213L13.9941 23.582C13.9759 23.7643 13.9622 23.9238 13.9531 24.0605C13.9486 24.1289 13.9486 24.1995 13.9531 24.2725C13.9531 24.3408 13.9531 24.416 13.9531 24.498C13.9622 24.653 13.985 24.7852 14.0215 24.8945C14.0579 25.0039 14.1081 25.1064 14.1719 25.2021C14.2357 25.2933 14.3245 25.3617 14.4385 25.4072C14.5524 25.4528 14.6868 25.4756 14.8418 25.4756C15.0241 25.4756 15.1836 25.4391 15.3203 25.3662C15.4616 25.2933 15.5732 25.2021 15.6553 25.0928C15.7419 24.9834 15.8148 24.8398 15.874 24.6621C15.9333 24.4844 15.9766 24.3158 16.0039 24.1562C16.0312 23.9967 16.0563 23.8053 16.0791 23.582L16.9062 15.666H19.1279L18.3213 23.3018C18.2666 23.8076 18.1891 24.2542 18.0889 24.6416C17.9886 25.0244 17.8473 25.3799 17.665 25.708C17.4827 26.0316 17.2572 26.2959 16.9883 26.501C16.724 26.7061 16.3981 26.8656 16.0107 26.9795C15.6234 27.0934 15.1745 27.1504 14.6641 27.1504ZM19.5381 27L20.7275 15.666H23.5508C24.0293 15.666 24.4486 15.6956 24.8086 15.7549C25.1686 15.8096 25.4967 15.9076 25.793 16.0488C26.0892 16.1901 26.3262 16.3724 26.5039 16.5957C26.6862 16.8236 26.8138 17.1107 26.8867 17.457C26.9323 17.6667 26.9528 17.8968 26.9482 18.1475C26.9482 18.3161 26.9391 18.4915 26.9209 18.6738C26.848 19.3848 26.6725 19.9704 26.3945 20.4307C26.1165 20.891 25.7109 21.235 25.1777 21.4629L26.2715 27H24.0225L23.1611 21.873H22.3408L21.8008 27H19.5381ZM22.4844 20.4922H23.0449C23.61 20.4922 24.0247 20.3623 24.2891 20.1025C24.5534 19.8428 24.7174 19.4189 24.7812 18.8311C24.7949 18.6898 24.8018 18.5576 24.8018 18.4346C24.8063 18.0654 24.7402 17.7852 24.6035 17.5938C24.4212 17.3385 24.0612 17.2087 23.5234 17.2041H22.833L22.4844 20.4922Z'
          fill='url(#paint1_linear_2342_4431)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_2342_4431'
            x1='20'
            y1='0'
            x2='20'
            y2='45'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.14' stop-color='#4E535F' />
            <stop offset='0.47' stop-color='#CEDBF9' />
            <stop offset='0.605' stop-color='#A2ADC5' />
            <stop offset='1' stop-color='#4E535F' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_2342_4431'
            x1='19.5'
            y1='14'
            x2='19.5'
            y2='28'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.33' stop-color='#A2ADC5' />
            <stop offset='1' stop-color='#4E535F' />
          </linearGradient>
        </defs>
      </svg>
    )
  }

  return (
    <div className={'relative'}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='40'
        height='45'
        viewBox='0 0 40 45'
        fill='none'
      >
        <path
          className={`${tagColor()}`}
          d='M36 0H4C1.79086 0 0 1.79086 0 4V34.3433C0 35.9826 1.00026 37.456 2.52387 38.0609L18.5239 44.4139C19.472 44.7903 20.528 44.7903 21.4761 44.4139L37.4761 38.0609C38.9997 37.456 40 35.9826 40 34.3433V4C40 1.79086 38.2091 0 36 0Z'
          fill='#F4A400'
        />
      </svg>

      <p
        className={
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-black font-semibold text-[22px] font-oswald'
        }
        style={{
          verticalAlign: 'middle',
          transform: 'translateY(-5%) translateY(-50%) translateX(-50%)',
        }}
      >
        {title}
      </p>
    </div>
  )
}
