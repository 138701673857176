export function formatDuration(duration: number, quantity: number): string {
  if (duration >= 99999) {
    if (quantity === 1) {
      return `${quantity} game`
    }
    return `${quantity} games`
  }

  const minutes = Math.floor(duration / 60)

  let result = ''

  if (minutes > 0) {
    result += `${minutes}min`
  }

  return `${quantity} x ${result.trim()}`
}

export function formatDuration_dash(duration: number): string {
  if (duration >= 100800) return '/game'
  const minutes = Math.floor(duration / 60)
  let result = ''
  result = `/ ${minutes}min`
  return result
}
