import React from 'react'

interface Props {
  text?: string
  textBackgroundColor?: string
  backgroundColor?: string
  textColor?: string
}

export function Divider(props: Props) {
  const {
    text = '',
    textBackgroundColor = '',
    backgroundColor = 'border-gray2 dark:border-gray2-dark',
    textColor = 'text-color-gray3',
  } = props

  return (
    <div className='relative'>
      <div className='relative flex items-center'>
        <div className={`w-full border-t ${backgroundColor}`} />
      </div>

      <div className='absolute inset-x-0 -top-1.5 flex justify-center'>
        <span className={`px-2 ${textBackgroundColor} ${textColor} text-xs`}>
          {text}
        </span>
      </div>
    </div>
  )
}

export default Divider
