import React, { useEffect, useState } from 'react'
import RadioButton from './radio/RadioButton'
import { AddonResponse } from '../../../types/addons/responses/AddonResponse'
import { useRecoilState } from 'recoil'
import { selectedProductAddOnsState } from '../../../common/atoms/checkout'

type Props = {
  addon: AddonResponse
  addons: AddonResponse[]
  myAddons?: AddonResponse[]
}
export default function RadioSection({ addon, addons, myAddons }: Props) {
  const [, setSelectedAddOns] = useRecoilState(selectedProductAddOnsState)
  const optionsSelectedState = useState<{ id: number; value: string }>(
    {} as any,
  )
  const [selectedOption, setSelectedOption] = optionsSelectedState

  useEffect(() => {
    const options = addons
    if (options && options.length > 0) {
      const myOption = addons?.find((option) =>
        myAddons?.some((itemA) => itemA?.id === option?.id),
      )

      if (myOption) {
        setSelectedOption({
          id: myOption.id,
          value: myOption.title,
        })
      } else {
        const option = options[0]
        setSelectedOption({
          id: option.id,
          value: option.title,
        })
      }
    }
  }, [])

  useEffect(() => {
    setSelectedAddOns((currentAddOns) => {
      let newAddOns = currentAddOns.filter(
        (addOn) => !addons.some((option) => option.id === addOn.id),
      )
      const selectItem = addons.find(
        (option) => option.id === selectedOption.id,
      )
      if (selectItem) newAddOns = [...newAddOns, selectItem]
      return newAddOns.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i,
      )
    })
  }, [selectedOption])

  return (
    <div className={'w-full'}>
      <div className={'flex flex-col gap-2'}>
        <div className={'flex flex-row items-center gap-2'}>
          <h6 className={'text-body-medium self-start text-[#F87B20]'}>
            {addon.title}
          </h6>
        </div>

        <div className={'mt-1.5'}>
          <div className={'flex flex-wrap gap-1.5'}>
            {addons?.map((item) => {
              return (
                <RadioButton
                  key={item.id + item.title}
                  id={item.id}
                  value={item.title}
                  selectedState={optionsSelectedState}
                  onSelected={() => {}}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
