import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { getCurrencies } from '../../api/currencies/currencies'
import { CurrencyResponse } from '../../types/currencies/responses/currency-response'

export function useGetCurrencies(currencyId?: string | null | undefined) {
  const { data, status } = useSession()

  return useQuery<CurrencyResponse[]>(
    ['currencies', status],
    //@ts-ignore
    async () => getCurrencies(data?.accessToken, currencyId),
    {
      enabled:
        status === SessionStatusEnum.AUTHENTICATED ||
        status === SessionStatusEnum.UNAUTHENTICATED,
    },
  )
}
