import React, { useEffect } from 'react'
import { Switch } from '@headlessui/react'

import { atom, useRecoilState } from 'recoil'

import { useUpdateUser } from '../../../hooks/users/useUpdateUser'
import PricePart from '../PricePart'
import { useUser } from '../../../hooks/users/useUser'

type Props = { saveMoney?: number }

export const isOpenVoiceChat = atom<boolean>({
  key: 'isOpenVoiceChat',
  default: true,
})

export default function VoiceChatPart({ saveMoney }: Props) {
  const [checked, setCheck] = useRecoilState(isOpenVoiceChat)
  const { mutate, isSuccess } = useUpdateUser()
  const { user, refetch } = useUser()

  useEffect(() => {
    if (user) {
      setCheck(user.enableVoiceChat ?? true)
    }
  }, [user])

  useEffect(() => {
    if (isSuccess) {
      refetch()
    }
  }, [isSuccess])

  return (
    <div className={'flex flex-row items-center justify-between h-8 mt-2.5'}>
      <div className={'flex flex-row items-center gap-2'}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M14.5 7.06344C14.5 6.2655 14.5714 4.78706 13.5503 3.11562C12.4 1.23194 10.3412 0 8 0C4.40609 0 1.5 2.91475 1.5 6.5V7.06344C0.638125 7.28594 0 8.06969 0 9V11.5C0 12.6028 0.897187 13.5 2 13.5H3C3 13.7762 3.22375 14 3.5 14C3.77625 14 4 13.7762 4 13.5C4 13.4409 4 7.82459 4 7C4 6.72375 3.77625 6.5 3.5 6.5C3.22375 6.5 3 6.72375 3 7H2.5C2.5 6.26034 2.46197 5.31066 3.05063 4.09875C3.73019 4.41438 4.57203 4.28831 5.13188 3.71219C6.74969 2.04778 9.36797 2.14369 10.8697 3.71375C11.4393 4.3 12.2848 4.40763 12.9497 4.09875C13.5388 5.31256 13.5 6.28509 13.5 7H13C13 6.72375 12.7762 6.5 12.5 6.5C12.2238 6.5 12 6.72375 12 7V13.5C12 13.7762 12.2238 14 12.5 14C12.7762 14 13 13.7762 13 13.5H13.4366C13.2141 14.3619 12.4303 15 11.5 15H9.5C9.5 14.4486 9.05141 14 8.5 14C7.94859 14 7.5 14.4486 7.5 15C7.5 15.5514 7.94859 16 8.5 16H11.5C13.0031 16 14.2516 14.8888 14.4669 13.4447C15.3453 13.2341 16 12.4419 16 11.5V9C16 8.06969 15.3619 7.28594 14.5 7.06344Z'
            fill='white'
          />
        </svg>

        <p className={'text-white text-body-bold'}>Voice Chat</p>
      </div>

      <div className={'flex flex-row items-center gap-2.5'}>
        <div className={'text-xs-bold text-bung-500'}>
          {checked ? (
            <p>ON</p>
          ) : (
            <div className={'flex flex-col items-end'}>
              <p>OFF</p>
              {saveMoney && saveMoney > 0 && (
                <div className={'flex flex-row items-center gap-1 text-white'}>
                  <span>SAVE</span>
                  <PricePart price={saveMoney} symbolClassName={'text-xs'} />
                </div>
              )}
            </div>
          )}
        </div>

        <Switch
          checked={checked}
          onChange={(checked: boolean) => {
            setCheck(checked)
            mutate({ enableVoiceChat: checked })
          }}
          className={` ${
            checked ? 'bg-bung-500' : 'bg-bung-800 '
          } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out`}
        >
          <span
            aria-hidden='true'
            className={`${
              checked ? 'translate-x-5' : 'translate-x-0'
            } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
    </div>
  )
}
