import React from 'react'

interface Props {
  id: number
  checked: boolean
  onChange: (id: number) => void

  option?: string
  price?: number
  hidePrice?: boolean
  priceString?: string
}

export default function CheckBoxItemV2({
  id,
  checked,
  onChange,
  option,
  price,
  hidePrice,
  priceString,
}: Props) {
  const handleCheckBoxChange = () => {
    onChange(id)
  }

  return (
    <div
      className={
        'flex cursor-pointer select-none flex-row items-center justify-between'
      }
      onClick={handleCheckBoxChange}
    >
      <div className={'flex flex-row items-center gap-2'}>
        <div className={'cursor-pointer text-white'}>{option}</div>
      </div>

      <div className={'flex flex-row items-center gap-2'}>
        {!hidePrice && (
          <div className={'cursor-pointer text-white'}>+{priceString}</div>
        )}
        <div
          className={'flex cursor-pointer select-none flex-row items-center'}
        >
          <input
            className='peer relative h-4 w-4 shrink-0 appearance-none border-0 bg-fizz-600 text-bung-400 checked:border-0 focus:ring-0 focus:ring-offset-0'
            type='checkbox'
            id={id + ''}
            checked={checked}
          />
          <svg
            className={
              'pointer-events-none absolute h-4 w-4 fill-none  stroke-white  peer-checked:stroke-fizz-1000'
            }
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M5.8 10L8.5 13L15 6.6'
              stroke='#242B39'
              strokeWidth='2.5'
            />
            <path
              d='M5.5 10L8.5 13L15 6.6'
              stroke='#242B39'
              strokeWidth='2.5'
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
