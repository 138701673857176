import React from 'react'
import { useQuantity } from '../../hooks/inputs/useQuantity'
import { useCreateTotalAmount } from '../../hooks/checkout/useCreateTotalAmount'

type Props = {
  min: number
  max: number
  theme?: 'light' | 'dark'
}

export default function QuantityInputV3({ min, max, theme = 'dark' }: Props) {
  const { quantity, onChangeN, setQuantity } = useQuantity({ max })
  const { data: total } = useCreateTotalAmount()

  function getTextOnTheme(theme: string) {
    if (theme === 'light') {
      return ' text-fizz-1000 '
    } else {
      return ' text-white '
    }
  }

  return (
    <div
      className={'mt-3 mb-2 flex flex-row items-center justify-between'}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div className={'flex flex-col gap-1 '}>
        <div className={'flex flex-row items-center gap-1'}>
          <p className={`text-body-medium ${getTextOnTheme(theme)}`}>
            {/*Quantity: x{quantity}*/}
            Quantity:
          </p>

          {total?.quantityDiscountPercentage !== null &&
            total?.quantityDiscountPercentage !== undefined &&
            total?.quantityDiscountPercentage > 0 && (
              <p className={'text-thresh-200 text-body-medium'}>
                (Save {total?.quantityDiscountPercentage}%)
              </p>
            )}
        </div>

        {/*<p*/}
        {/*  className={`${*/}
        {/*    theme === 'light' ? 'text-fizz-1000  ' : 'text-fizz-0'*/}
        {/*  } text-sm mt-0.5`}*/}
        {/*>*/}
        {/*  💡Buy 3 or more, save money!*/}
        {/*</p>*/}
      </div>

      <div className='flex w-fit flex-row bg-transparent'>
        <div
          className={`border-1 flex h-10 w-10 flex-col items-center justify-center rounded-full border ${
            theme === 'light' ? 'border-fizz-1000' : ' border-darul-200'
          } `}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            const newQuantity = quantity - 1
            if (newQuantity > 0) {
              setQuantity(newQuantity)
            }
          }}
        >
          <button
            className={`text-2xl-light ${
              quantity > 1
                ? `${theme === 'light' ? ' text-fizz-1000 ' : 'text-white '}`
                : 'text-darul-200'
            }`}
          >
            -
          </button>
        </div>

        <input
          type='number'
          id='quantity'
          onInput={onChangeN}
          value={quantity}
          className={`text-body-medium pointer-events-none w-20 border border-none bg-transparent text-center ${
            theme === 'light' ? 'text-fizz-1000' : 'text-white'
          } focus:border-none focus:ring-0`}
        />

        <div
          className={`border-1 flex h-10 w-10 flex-col items-center justify-center rounded-full border ${
            theme === 'light' ? 'border-fizz-1000' : ' border-darul-200'
          } `}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            const newQuantity = +quantity + 1
            if (newQuantity < +max + 1) {
              setQuantity(newQuantity)
            }
          }}
        >
          <button
            className={`text-2xl-light ${
              quantity < max
                ? `${theme === 'light' ? 'text-fizz-1000' : ' text-white '}`
                : 'text-darul-200'
            }`}
          >
            +
          </button>
        </div>
      </div>
    </div>
  )
}
