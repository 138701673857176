import React from 'react'
import { useGetLegendaryFreeGameState } from '../../../hooks/loyal/useLoyalty'
import { useUser } from '../../../hooks/users/useUser'
import { loyaltyProgramUrl } from '../../../common/urls/loyalty-program.urls'
import { useRouter } from 'next/router'
import { isNil } from '../../../common/utils/common.utils'

export default function FreeGameBonusHint() {
  const { data } = useGetLegendaryFreeGameState()
  const { user } = useUser()
  const router = useRouter()

  if (
    !data ||
    isNil(user?.rewardProgram) ||
    user?.rewardProgram?.tier?.discountPercentage === 0 ||
    isNil(user?.rewardProgram?.tier?.discountPercentage)
  ) {
    return null
  }

  // const nextLevelDiff =
  //   data.currentProgress >= data.totalProgress
  //     ? 1
  //     : data.totalProgress - data.currentProgress

  // if ((nextLevelDiff ?? 0) >= 20) {
  //   return null
  // }

  return (
    <button
      className={'w-fit flex flex-col'}
      onClick={(e) => {
        e.preventDefault()
        router.push(loyaltyProgramUrl)
      }}
    >
      <div className='border rounded-full border-bung-500 text-bung-500 px-2 py-0.5 text-xs-normal w-fit'>
        {user?.rewardProgram?.tier?.rank}{' '}
        {user?.rewardProgram?.tier?.discountPercentage}% off
      </div>
    </button>
  )
}
