import React from 'react'
import Image, { StaticImageData } from 'next/image'
import TrustpilotIcon from '../../../public/static/trustpilot-5-stars.png'

type Props = {
  title: string
  author: string
  description: string
  date: string
  image?: StaticImageData
}

export function TrustPilotReviewPart({
  title,
  author,
  date,
  description,
  image,
}: Props) {
  return (
    <a
      href='https://www.trustpilot.com/review/tapin.gg'
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className={'mr-3 flex h-56 w-88 flex-col gap-2 bg-fizz-900 p-5'}>
        <div className={'flex w-full flex-row justify-between gap-2'}>
          <div className={'flex flex-row items-center gap-3'}>
            <div className={'relative h-10 w-10 rounded-full'}>
              <Image
                src={image ?? process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!}
                alt={'Benefit icon'}
                className={'rounded-full object-cover'}
                fill={true}
              />
            </div>

            <div className={'flex flex-col justify-between'}>
              <div className={'text-body-medium text-white'}>{author}</div>

              {/*<div className={'text-secondary-normal text-darul-100'}>*/}
              {/*  {date}*/}
              {/*</div>*/}
            </div>
          </div>

          <Image
            className={'self-start'}
            src={TrustpilotIcon}
            alt={'Trustpilot stars'}
            width={100}
            height={18.4}
          />
        </div>

        <div className={'text-body-medium text-white'}>{title}</div>

        <div className={'text-body-normal text-darul-100 line-clamp-4'}>
          {description}
        </div>
      </div>
    </a>
  )
}
