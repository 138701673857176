export default {
  titleTemplate: 'Tapin | %s',
  openGraph: {
    url: 'https://tapin.gg',
    title:
      "World's First On-Demand Teammate Finder | LoL Ranked Duos | Valorant Ranked Duos",
    description:
      'Play and learn from the best pro teammates for LoL and Valorant. No waiting, average wait time under 2 minutes to play.',
    images: [
      {
        url: 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/referral-04.png',
        width: 1200,
        height: 630,
        type: 'image/png',
      },
    ],
  },
}
