import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { readGiftingMessages } from '../../api/tips/tips'

export function usePutReadGiftingMessages() {
  const { data } = useSession()

  return useMutation(async () =>
    // @ts-ignore
    readGiftingMessages(data?.accessToken),
  )
}
