import Image from 'next/image'
import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { ProductResponse } from '../../types/products/product-response'
import ClockGrayIcon from '../../public/static/clock_gray.svg'
import { formatDuration_dash } from '../../common/utils/url.utils'
import Divider from '../divider/Divider'
import TwitchIcon from '../../public/static/social/twitch.png'
import ClockIcon from '../../public/static/clock-nami-400.png'
import { waitingTime } from '../../common/utils/time.utils'
import { useLocalizedPrice } from '../../hooks/currencies/useLocalizedPrice'
import PricePart from './PricePart'
import InSessionIcon from '../../public/static/product/steamer/icon_in_session.png'
import LiveIcon from '../../public/static/product/steamer/icon_live.png'
import AvailableIcon from '../../public/static/product/steamer/icon_available.png'

type Props = {
  product: ProductResponse
  isLoading: boolean
  imageUrl: string
  available?: boolean
  isLive?: boolean
  isForceLive?: boolean
}

export default function StreamerCard({
  product,
  isLoading,
  imageUrl,
  available = false,
  isLive,
  isForceLive,
}: Props) {
  const [estTime] = useState(`${waitingTime(product, false, false)} min away`)
  const { getLocalizedPrice } = useLocalizedPrice()
  let price = getLocalizedPrice(product?.localizedPrices?.charge)?.price ?? 0.0

  function statusIcon() {
    if (!available) {
      return InSessionIcon
    }

    if (!isLive && isForceLive) {
      return AvailableIcon
    }

    return LiveIcon
  }

  function borderColor() {
    if (!available) {
      return 'border-nami-400'
    }

    if (!isLive && isForceLive) {
      return 'border-thresh-500'
    }

    return 'border-swain-400'
  }

  function statusText() {
    if (!available) {
      return 'IN SESSION'
    }

    if (!isLive && isForceLive) {
      return 'AVAILABLE'
    }

    return 'LIVE NOW'
  }

  function bgColor() {
    if (!available) {
      return ' bg-nami-400 '
    }

    if (!isLive && isForceLive) {
      return 'bg-thresh-500'
    }

    return ' bg-swain-500 '
  }

  return (
    <RadioGroup.Option
      key={product.uniqueId}
      value={product}
      className={({ checked }) =>
        `w-full ${checked ? 'bg-[#212900]' : ''} relative cursor-pointer ${
          available ? '' : 'opacity-70'
        } select-none`
      }
      disabled={!available}
    >
      {({ checked }) => (
        <div
          className={
            'my-2.5 flex w-full flex-row items-center justify-between pl-3 pr-1 gap-1.5 text-start'
          }
        >
          <div
            className={`h-4 w-4 shrink-0 select-none rounded-full
            ${
              checked
                ? 'border-2 border-bung-400 bg-[#03060D]'
                : 'border-2 border-darul-500 '
            }
          `}
          />

          <div
            className={`ml-3.5 relative h-14 w-14 rounded-full border-2 shrink-0 mb-1  ${borderColor()}`}
          >
            <div
              className={
                'absolute top-0 right-0 translate-x-1/2  h-5 w-5 rounded-full'
              }
            >
              <Image
                src={statusIcon()}
                className={'object-contain'}
                fill={true}
                alt={'status icon'}
              />
            </div>
            <div
              className={
                'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-12 w-12 rounded-full'
              }
            >
              <Image
                src={imageUrl}
                alt={product.title}
                fill={true}
                className={'shrink-0 object-cover rounded-full'}
              />
            </div>

            <div
              className={`absolute ${bgColor()} py-0.5 px-1.5 bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 text-xs-semibold whitespace-nowrap rounded-full text-white`}
            >
              {statusText()}
            </div>
          </div>

          <div className={'flex flex-col w-full ml-3.5 mr-3'}>
            <div
              className={
                ' flex w-full flex-row items-start justify-between gap-1.5'
              }
            >
              <div className={'flex flex-col'}>
                <div className={'flex flex-row items-center gap-1.5'}>
                  <h2 className={'text-lg-bold text-color-gray5'}>
                    {product.streamer?.stream?.username}
                  </h2>

                  {!available && (
                    <Image src={ClockIcon} alt={''} width={16} height={16} />
                  )}
                </div>

                {product.streamer?.subtitle && (
                  <p className={'text-xs-normal text-white'}>
                    {product.streamer?.subtitle}
                  </p>
                )}

                <p className={'text-secondary-normal text-fizz-0'}>
                  {isLive
                    ? `Play with ${product.streamer?.stream?.username} on live stream!`
                    : 'Play with our featured teammate!'}
                  {/*{`Play with ${product.streamer?.stream?.username} ${isLive ? 'on live stream' : 'in private match'}!`}*/}
                </p>
              </div>

              <div className={'flex shrink-0 flex-col items-end truncate'}>
                <div
                  className={
                    'font-medium flex flex-row items-center justify-center'
                  }
                >
                  <span
                    className={`text-body-medium text-color-gray5 ${
                      isLoading ? 'opacity-0' : 'opacity-100'
                    }`}
                  >
                    <PricePart price={price} symbolClassName='mr-0.5 text-xs' />
                  </span>

                  <span className={'text-caption-medium text-color-gray5'}>
                    {`${
                      product.displayUnit
                        ? `/${product.displayUnit}`
                        : formatDuration_dash(product?.duration ?? 100800)
                    }`}
                  </span>
                </div>

                <div className={'mt-1 flex flex-row items-center gap-1'}>
                  <div className={'relative h-3 w-3'}>
                    <Image
                      src={ClockGrayIcon}
                      fill={true}
                      className={'object-contain'}
                      alt={'Clock icon'}
                    />
                  </div>
                  <p className={'text-secondary-normal truncate text-fizz-0'}>
                    {estTime}
                  </p>
                </div>
              </div>
            </div>

            {product.streamer?.stream?.url && (
              <div className={'w-full flex flex-col'}>
                <div className={'my-2.5'}>
                  <Divider />
                </div>

                <div className={'flex flex-row justify-between'}>
                  <div className={'flex flex-row items-center shrink-0 gap-2'}>
                    <div className={'relative h-5 w-5 shrink-0'}>
                      <Image
                        src={TwitchIcon}
                        fill={true}
                        className={'object-container'}
                        alt={'Warning icon'}
                      />
                    </div>

                    <a
                      target={'_blank'}
                      href={product.streamer?.stream?.url}
                      className={
                        'text-xs font-extrabold text-white whitespace-nowrap'
                      }
                      rel='noreferrer'
                    >
                      /{product.streamer?.stream?.username ?? ''}
                    </a>
                  </div>

                  {isLive ? (
                    <p className={'text-xs-semibold text-[#B9B1FF]'}>
                      {product.streamer?.stream.current_viewer_count} viewers
                    </p>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </RadioGroup.Option>
  )
}
