import React from 'react'
import YellowCrownIcon from '../../public/static/crown-yellow.png'
import Image from 'next/image'
import { formatAmountWithCurrency } from '../../common/utils/currency.utils'
import { useRecoilState } from 'recoil'
import { defaultCurrencyState } from '../../common/atoms/atoms'
import GetProButton from '../buttons/GetProButton'
import { useRouter } from 'next/router'
import { plansUrl } from '../../common/urls/homeUrl'

interface SavingsBannerProps {
  amount: number
  isPro?: boolean
}

const SubscriptionBanner: React.FC<SavingsBannerProps> = ({
  amount,
  isPro,
}) => {
  const [currency] = useRecoilState(defaultCurrencyState)
  const router = useRouter()

  return (
    <div
      onClick={() => {
        if (!isPro) {
          router.push(plansUrl)
        }
      }}
      className={`${isPro ? '' : 'cursor-pointer'} bg-gradient-to-r from-[#F9B7004D] to-[#936C004D] border border-gnar-400 text-amber-300 pl-4 h-16 rounded-lg flex items-center space-x-3`}
    >
      <Image src={YellowCrownIcon} alt={'crown'} width={24} height={24} />
      {isPro && (
        <span className='text-secondary-medium text-white'>
          You're saving{' '}
          <span className={'text-[#F9B700]'}>
            {formatAmountWithCurrency(amount, currency, 2)}
          </span>{' '}
          with Tapin PRO subscription!
        </span>
      )}

      {!isPro && (
        <span className='text-secondary-medium text-white flex-grow'>
          Save{' '}
          <span className={'text-[#F9B700]'}>
            {formatAmountWithCurrency(amount, currency, 2)}
          </span>{' '}
          with TapIn PRO, and get more benefits!
        </span>
      )}

      {!isPro && <GetProButton />}
    </div>
  )
}

export default SubscriptionBanner
