import { UserResponse } from '../../types/users/user-response'
import { queryKeys } from '../../common/react-query/constants'
import { useEffect, useState } from 'react'
import { getMe } from '../../api/users/users'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'

interface UseUser {
  user: UserResponse | null | undefined
  refetch: () => void
}

export function useUser(): UseUser {
  const { data: session, status } = useSession()

  // call useQuery to update user data from server
  const { data, refetch } = useQuery<UserResponse | null>(
    [queryKeys.user],
    //@ts-ignore
    async () => getMe(session?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )

  const [user, setUser] = useState<UserResponse | null | undefined>(null)

  useEffect(() => setUser(data), [data])

  return { user, refetch }
}
