import { useRecoilState } from 'recoil'
import {
  promoState,
  quantityState,
  selectedProductState,
} from '../../common/atoms/checkout'
import { useEffect } from 'react'
import {
  ErrorResponse,
  readableError,
} from '../../types/errors/responses/error-response'
import { useGetPromoCode } from '../stripe/useGetPromoCode'
import { PromoCodeResponse } from '../../types/stripe/responses/promo-code-response'
import { setStoredPromoCodeV2 } from '../cache/promo-code-storage-v2'

export function usePromoCode() {
  const [selectedProduct] = useRecoilState(selectedProductState)
  const [quantity] = useRecoilState(quantityState)
  const [promoCode] = useRecoilState(promoState)
  const { mutate: getPromoCode, error: getPromoCodeError } = useGetPromoCode()

  useEffect(() => {
    if (promoCode?.code && selectedProduct?.id && quantity) {
      getPromoCode(
        {
          promoCode: promoCode?.code,
          productId: selectedProduct?.id,
          quantity: quantity,
          shouldThrowError: true,
        },
        {
          onSuccess: (data: PromoCodeResponse) => {
            if (data) {
              setStoredPromoCodeV2(data)
            }
          },
        },
      )
    }
  }, [promoCode, selectedProduct, quantity])

  const hasPromoCode = function hasPromoCode(): boolean {
    return promoCode !== null
  }

  const promoCodeWarning = function promoCodeWarning(): string {
    if (getPromoCodeError)
      return readableError(getPromoCodeError as ErrorResponse)
    return ''
  }

  const hasPromoCodeAndWarning = function hasPromoCodeAndhasWarning(): boolean {
    return getPromoCodeError !== null
  }

  return {
    hasPromoCode,
    promoCodeWarning,
    hasPromoCodeAndWarning,
  }
}
