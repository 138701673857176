import React from 'react'
import Image from 'next/image'
import iconEmpty from '../../../../public/static/product/account/icon-empty.png'
import iconEmptySkin from '../../../../public/static/product/account/icon-empty-skin.png'

type Props = {
  type?: 'skin' | 'champion'
}

export default function EmptySection({ type }: Props) {
  const image = type === 'skin' ? iconEmptySkin : iconEmpty
  const text = type === 'skin' ? 'skins' : 'champions'
  return (
    <div className={'flex flex-col items-center flex-1 mt-10'}>
      <div className={'relative w-60 h-60 shrink-0'}>
        <Image src={image} alt={'Empty Icon'} fill={true} />
      </div>
      <p className={'text-fizz-400 text-body-medium'}>No {text} available.</p>
    </div>
  )
}
