import { StaticImageData } from 'next/image'
import IconUnrank from '../../public/static/loyalty/icon_unrank.svg'
import IconMember from '../../public/static/loyalty/icon_member.svg'
import IconExplorer from '../../public/static/loyalty/icon_explorer.svg'
import IconElite from '../../public/static/loyalty/icon_elite.svg'
import IconPrestige from '../../public/static/loyalty/icon_prestige.svg'
import IconLegendary from '../../public/static/loyalty/icon_legendary.svg'
import IconLifetimeLegendary from '../../public/static/loyalty/icon_lifetime_legenday.svg'
import BackgroundMember from '../../public/static/loyalty/background/background_member.png'
import BackgroundExplorer from '../../public/static/loyalty/background/background_explorer.png'
import BackgroundElite from '../../public/static/loyalty/background/background_elite.png'
import BackgroundPrestige from '../../public/static/loyalty/background/background_prestige.png'
import BackgroundLegendary from '../../public/static/loyalty/background/background_legendary.png'
import BackgroundLifetimeLegendary from '../../public/static/loyalty/background/background_lifetime_legendary.png'
import HorizontalBackgroundMember from '../../public/static/loyalty/background/horizontal/background_member.png'
import HorizontalBackgroundExplorer from '../../public/static/loyalty/background/horizontal/background_explorer.png'
import HorizontalBackgroundElite from '../../public/static/loyalty/background/horizontal/background_elite.png'
import HorizontalBackgroundPrestige from '../../public/static/loyalty/background/horizontal/background_prestige.png'
import HorizontalBackgroundLegendary from '../../public/static/loyalty/background/horizontal/background_legendary.png'
import HorizontalBackgroundLifetimeLegendary from '../../public/static/loyalty/background/horizontal/background_lifetime_legendary.png'
import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import {
  getLegendaryFreeGameState,
  getLoyaltyFreeGame,
} from '../../api/loyalty-programs/loyalty'
import { LegendaryFreeGamesResponse } from '../../types/loyalty/legendary-free-games.response'
import HorizontalBackgroundUnRank from '../../public/static/select-mode/lobby/background_teammate_hor.png'

import BackgroundUnRank from '../../public/static/select-mode/lobby/background_teammate.png'

export interface Loyalty {
  id?: number
  key?: string
  name: string
  benefits: string[]
  icon: StaticImageData
  cardBackground?: StaticImageData
  horizontalCardBackground?: StaticImageData
  textColor?: string
  position: number
  cashback: number
  games: number
  requiredGames: number
  freeGames: number
  discordBadge: boolean
  freeGameEach20Sessions?: boolean
  priorityQueue: boolean
  lifetimeBenefits: boolean
  discount?: number
}
export const useGetFreeGameCount = () => {
  const { data, status } = useSession()

  return useQuery<{ count: number }>(
    ['free_games'],
    //@ts-ignore
    async () => getLoyaltyFreeGame(data?.accessToken),

    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}

export const useGetLegendaryFreeGameState = () => {
  const { data, status } = useSession()

  return useQuery<LegendaryFreeGamesResponse>(
    ['legendary_free_games'],
    //@ts-ignore
    async () => getLegendaryFreeGameState(data?.accessToken),

    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}
export default function useLoyalty() {
  const { data } = useGetFreeGameCount()

  const unranked = {
    name: 'UNRANKED',
    icon: IconUnrank,
    cardBackground: BackgroundUnRank,
    horizontalCardBackground: HorizontalBackgroundUnRank,
    benefits: [],
    games: 0,
    cashback: 0,
    requiredGames: 30,
    freeGames: 0,
    position: 0,
    discordBadge: false,
    priorityQueue: false,
    lifetimeBenefits: false,
  }

  const loyaltyMap: { [key: string]: Loyalty } = {
    MEMBER: {
      id: 1,
      name: 'MEMBER',
      icon: IconMember,
      cardBackground: BackgroundMember,
      horizontalCardBackground: HorizontalBackgroundMember,
      benefits: [],
      games: 1,
      cashback: 1,
      requiredGames: 25,
      freeGames: 0,
      position: 16.66,
      priorityQueue: false,
      discordBadge: false,
      lifetimeBenefits: false,
      textColor: ' text-rank-member ',
      discount: 0,
    },
    EXPLORER: {
      id: 2,
      name: 'EXPLORER',
      icon: IconExplorer,
      cardBackground: BackgroundExplorer,
      horizontalCardBackground: HorizontalBackgroundExplorer,
      position: 33.33,
      benefits: [],
      games: 15,
      cashback: 2,
      requiredGames: 20,
      freeGames: 0,
      priorityQueue: false,
      discordBadge: false,
      lifetimeBenefits: false,
      textColor: ' text-rank-explorer ',
      discount: 3,
    },
    ELITE: {
      id: 3,
      name: 'ELITE',
      icon: IconElite,
      cardBackground: BackgroundElite,
      horizontalCardBackground: HorizontalBackgroundElite,
      position: 50.0,
      benefits: [],
      games: 30,
      cashback: 1,
      requiredGames: 15,
      freeGames: 1,
      priorityQueue: true,
      discordBadge: false,
      lifetimeBenefits: false,
      textColor: ' text-rank-elite ',
      discount: 5,
    },
    PRESTIGE: {
      id: 4,
      name: 'PRESTIGE',
      icon: IconPrestige,
      cardBackground: BackgroundPrestige,
      horizontalCardBackground: HorizontalBackgroundPrestige,
      position: 66.33,
      benefits: [],
      games: 45,
      cashback: 4,
      requiredGames: 10,
      freeGames: 1,
      priorityQueue: true,
      discordBadge: false,
      lifetimeBenefits: false,
      textColor: ' text-rank-prestige ',
      discount: 7,
    },
    LEGENDARY: {
      id: 5,
      name: 'LEGENDARY',
      icon: IconLegendary,
      cardBackground: BackgroundLegendary,
      horizontalCardBackground: HorizontalBackgroundLegendary,
      position: 83,
      benefits: [],
      games: 60,
      cashback: 5,
      requiredGames: 10,
      freeGames: 2,
      discordBadge: true,
      priorityQueue: true,
      freeGameEach20Sessions: true,
      lifetimeBenefits: false,
      textColor: ' text-rank-legendary ',
      discount: 10,
    },
    LIFETIMELEGENDARY: {
      id: 6,
      name: 'LIFETIMELEGENDARY',
      icon: IconLifetimeLegendary,
      cardBackground: BackgroundLifetimeLegendary,
      horizontalCardBackground: HorizontalBackgroundLifetimeLegendary,
      benefits: [],
      games: 2000,
      position: 100,
      cashback: 5,
      requiredGames: 10,
      freeGames: 10,
      freeGameEach20Sessions: true,
      priorityQueue: true,
      discordBadge: true,
      lifetimeBenefits: true,
      textColor: ' text-rank-lifetimeLegendary ',
      discount: 12,
    },
  }

  const loyaltys: Loyalty[] = [
    {
      id: 1,
      name: 'MEMBER',
      icon: IconMember,
      cardBackground: BackgroundMember,
      horizontalCardBackground: HorizontalBackgroundMember,
      benefits: [],
      games: 0,
      cashback: 1,
      requiredGames: 30,
      freeGames: 0,
      position: 0,
      priorityQueue: false,
      discordBadge: false,
      lifetimeBenefits: false,
      discount: 0,
    },
    {
      id: 2,
      name: 'EXPLORER',
      icon: IconExplorer,
      cardBackground: BackgroundExplorer,
      horizontalCardBackground: HorizontalBackgroundExplorer,
      position: 20,
      benefits: [],
      games: 15,
      cashback: 2,
      requiredGames: 25,
      freeGames: 0,
      priorityQueue: false,
      discordBadge: false,
      lifetimeBenefits: false,
      discount: 3,
    },
    {
      id: 3,
      name: 'ELITE',
      icon: IconElite,
      cardBackground: BackgroundElite,
      horizontalCardBackground: HorizontalBackgroundElite,
      position: 40,
      benefits: [],
      games: 30,
      cashback: 3,
      requiredGames: 20,
      freeGames: 1,
      priorityQueue: true,
      discordBadge: false,
      lifetimeBenefits: false,
      discount: 5,
    },
    {
      id: 4,
      name: 'PRESTIGE',
      icon: IconPrestige,
      cardBackground: BackgroundPrestige,
      horizontalCardBackground: HorizontalBackgroundPrestige,
      position: 60,
      benefits: [],
      games: 45,
      cashback: 4,
      requiredGames: 15,
      freeGames: 1,
      priorityQueue: true,
      discordBadge: false,
      lifetimeBenefits: false,
      discount: 7,
    },
    {
      id: 5,
      name: 'LEGENDARY',
      icon: IconLegendary,
      cardBackground: BackgroundLegendary,
      horizontalCardBackground: HorizontalBackgroundLegendary,
      position: 80,
      benefits: [],
      games: 60,
      cashback: 5,
      requiredGames: 10,
      freeGames: 2,
      priorityQueue: true,
      discordBadge: true,
      freeGameEach20Sessions: true,
      lifetimeBenefits: false,
      discount: 10,
    },
    {
      id: 6,
      key: 'LIFETIMELEGENDARY',
      name: 'LIFETIME LEGENDARY',
      icon: IconLifetimeLegendary,
      cardBackground: BackgroundLifetimeLegendary,
      horizontalCardBackground: HorizontalBackgroundLifetimeLegendary,
      benefits: [],
      games: 1000,
      position: 100,
      cashback: 5,
      requiredGames: 10,
      freeGames: 10,
      priorityQueue: true,
      discordBadge: true,
      freeGameEach20Sessions: true,
      lifetimeBenefits: true,
      discount: 12,
    },
  ]

  const benefits = [
    // { key: 'freeGames', name: 'Free Games' },
    { key: 'priorityQueue', name: 'Priority Queue' },
    // { key: 'freeGameEach20Sessions,', name: 'Free Game Each 20 Sessions' },
    { key: 'discordBadge', name: 'Discord Badge' },

    { key: 'lifetimeBenefits', name: 'Lifetime Benefits' },
  ]
  function calculateProgressBarPercent(value: number): number {
    if (value <= 0) {
      return 0
    } else if (value <= 15) {
      return (value / 15) * 20
    } else if (value <= 30) {
      return 20 + ((value - 15) / 15) * 20
    } else if (value <= 45) {
      return 40 + ((value - 30) / 15) * 20
    } else if (value <= 60) {
      return 60 + ((value - 45) / 15) * 20
    } else {
      // 对于超过60的值，使用60到1000的区间进行计算
      return 80 + ((value - 60) / 940) * 20
    }
  }

  function calculateDifferenceToNextLevel(value: number): number {
    if (value <= 0) {
      return 1
    } else if (value <= 15) {
      return 15 - value
    } else if (value <= 30) {
      return 30 - value
    } else if (value <= 45) {
      return 45 - value
    } else if (value <= 60) {
      return 60 - value
    } else if (value < 1000) {
      return 1000 - value
    } else {
      return 0
    }
  }

  function getRankBackGround(
    rank: string | undefined,
    horizontal?: boolean,
  ): StaticImageData | string {
    if (!rank) {
      return unranked.icon
    }
    const adjustedRank = rank.replace(/ /g, '').trim().toUpperCase()
    if (horizontal) {
      return (
        loyaltyMap[adjustedRank]?.horizontalCardBackground ??
        loyaltyMap['MEMBER']?.horizontalCardBackground!
      )
    }

    return (
      loyaltyMap[adjustedRank]?.cardBackground ??
      loyaltyMap['MEMBER']?.cardBackground!
    )
  }

  const getRankImage = (rank: string | undefined): StaticImageData => {
    if (!rank) {
      return unranked.icon
    }
    const adjustedRank = rank.replace(/ /g, '').trim().toUpperCase()
    return loyaltyMap[adjustedRank]?.icon ?? unranked.icon
  }

  return {
    loyaltyMap,
    loyaltys,
    benefits,
    unranked: unranked,
    calculateProgressBarPercent,
    calculateDifferenceToNextLevel,
    getRankBackGround,
    getRankImage,
    freeGames: data,
  }
}
