import React from 'react'
import { useRecoilState } from 'recoil'
import { selectedProductState } from '../../common/atoms/checkout'
import {
  defaultCurrencyState,
  isOpenCoachingState,
  isOpenLPShieldModalState,
  isOpenModeModalState,
  selectedGameState,
} from '../../common/atoms/atoms'
import { RadioGroup } from '@headlessui/react'
import groupBy from 'lodash.groupby'
import ProductCardV2 from './ProductCardV2'
import { useGetProductSteamer } from '../../hooks/product/useGetProductSteamer'
import StreamerCard from './StreamerCard'
import { SessionStatusEnum } from '../../common/enums/streamer-status'
import { useGetProductLocalizedPrices } from '../../hooks/checkout/useGetProductLocalizedPrices'
import useProduct from '../../hooks/product/useProduct'
import { isCompanionMode } from '../../common/utils/product.utils'
import { GameTeammatesV2 } from '../../api/creators/getGamingTeammates'
import Image from 'next/image'
import LimitedTimeIcon from '../../public/static/limited-time.png'

type Props = {
  eGirlTeammates: GameTeammatesV2
}

export default function ProductSectionV2({ eGirlTeammates }: Props) {
  const [selectedProduct, setSelectedProduct] =
    useRecoilState(selectedProductState)
  const [game] = useRecoilState(selectedGameState)
  const { data: prices, isLoading } = useGetProductLocalizedPrices()
  const { data: streamerProduct, isLoading: streamerisLoading } =
    useGetProductSteamer(game?.key)
  const [, setIsOpenCoaching] = useRecoilState(isOpenCoachingState)
  const [, setIsOpenModeModal] = useRecoilState(isOpenModeModalState)
  const [, setOpenLPShieldModal] = useRecoilState(isOpenLPShieldModalState)
  const [currency] = useRecoilState(defaultCurrencyState)
  const { filterTeammates, filterDecoration } = useProduct(
    eGirlTeammates,
    game?.key,
  )

  return (
    <div>
      <RadioGroup
        //@ts-ignore
        by={'uniqueId'}
        value={selectedProduct}
        onChange={(product) => {
          if (product?.mode === 'marketplace_account') {
            setSelectedProduct(null)
            return
          }

          setSelectedProduct(product)
        }}
      >
        <div className='grid w-full grid-cols-1 gap-1'>
          {streamerProduct?.filter((item) => {
            return item.streamer?.status !== SessionStatusEnum.OFFLINE
          }) &&
            streamerProduct?.filter((item) => {
              return item.streamer?.status !== SessionStatusEnum.OFFLINE
            }).length > 0 && (
              <div className={'flex w-full flex-col'}>
                <div
                  className={
                    'text-body-medium mb-1.5 flex flex-row items-center gap-3 text-white'
                  }
                >
                  <h3>Streamers</h3>
                </div>

                {streamerProduct
                  ?.filter((item) => {
                    return item.streamer?.status !== SessionStatusEnum.OFFLINE
                  })
                  ?.map((item) => {
                    if (item.streamer?.status !== SessionStatusEnum.AVAILABLE) {
                      return (
                        <div
                          className='tooltip w-full'
                          data-tip='Streamer is currently booked. Please wait until the next session is available.'
                        >
                          <StreamerCard
                            key={
                              item.id +
                              (item.streamer?.discord_xid ?? '') +
                              game.key
                            }
                            product={item}
                            isLoading={streamerisLoading}
                            imageUrl={
                              item.streamer?.imageUrl ??
                              process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                            }
                            available={
                              item.streamer?.status ===
                              SessionStatusEnum.AVAILABLE
                            }
                            isLive={item.streamer?.stream?.is_live === 1}
                            isForceLive={
                              item.streamer?.stream?.force_live_iff_on_duty ===
                              1
                            }
                          />
                        </div>
                      )
                    }
                    return (
                      <StreamerCard
                        key={
                          item.id +
                          (item.streamer?.discord_xid ?? '') +
                          game.key
                        }
                        product={item}
                        isLoading={streamerisLoading}
                        imageUrl={
                          item.streamer?.imageUrl ??
                          process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                        }
                        available={
                          item.streamer?.status === SessionStatusEnum.AVAILABLE
                        }
                        isLive={item.streamer?.stream?.is_live === 1}
                        isForceLive={
                          item.streamer?.stream?.force_live_iff_on_duty === 1
                        }
                      />
                    )
                  })}
              </div>
            )}
          {game.key === 'egirls' &&
            Object.entries(groupBy(game?.products, 'category.name')).map(
              ([categoryName, products]) => (
                <div key={categoryName} className={'flex w-full flex-col'}>
                  <h3 className={'text-body-medium mb-1.5 text-white'}>
                    {categoryName === undefined ||
                    categoryName === null ||
                    categoryName === 'undefined'
                      ? 'Fun'
                      : categoryName}
                  </h3>
                  {products.map((product) => (
                    <ProductCardV2
                      prices={prices}
                      key={product.id}
                      product={product}
                      isLoading={isLoading}
                      currencyId={currency?.id ?? 1}
                      imageUrl={
                        product.imageAsset?.imageUrl ??
                        process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                      }
                      teammate={
                        game?.key !== 'egirls' &&
                        isCompanionMode(product.mode, product.slug)
                          ? filterTeammates(
                              product.slug,
                              product.categoryKey,
                              game?.key,
                            )
                          : null
                      }
                      decoration={filterDecoration(product.mode, product.slug)}
                      game={game}
                    />
                  ))}
                </div>
              ),
            )}

          {game.key !== 'egirls' &&
            Object.entries(
              groupBy(
                game?.products?.map((product) => ({
                  ...product,
                  // Check for both xmas and bundle in slug
                  mode: product.slug?.includes('xmas')
                    ? 'xmas'
                    : product.slug?.includes('bundle')
                      ? 'bundle'
                      : product.mode,
                })),
                'mode',
              ),
            )?.map(([title, products]) => {
              // @ts-ignore
              return products?.map((product, index) =>
                index === 0 ? (
                  <div key={product.id} className={'flex w-full flex-col'}>
                    <div
                      className={
                        'text-body-medium mb-1.5 flex flex-row items-center gap-3 text-white capitalize'
                      }
                    >
                      <h3>
                        {title === 'rankup'
                          ? 'Rank Up'
                          : title === 'quest'
                            ? 'Quest'
                            : title === 'xmas'
                              ? 'Bundle'
                              : title === 'competitive'
                                ? 'Team Up'
                                : title === 'companion'
                                  ? 'Social'
                                  : title === 'coaching'
                                    ? 'Training'
                                    : title === 'bundle'
                                      ? 'Bundle'
                                      : title}
                      </h3>

                      {title === 'coaching' && (
                        <div
                          className={
                            'flex h-5 w-5 cursor-pointer select-none items-center justify-center rounded-full bg-darul-400 font-sans text-white'
                          }
                          onClick={(e) => {
                            e.preventDefault()
                            setIsOpenCoaching(true)
                          }}
                        >
                          ?
                        </div>
                      )}

                      {title === 'competitive' && (
                        <div
                          className={
                            'flex h-5 w-5 cursor-pointer select-none items-center justify-center rounded-full bg-darul-400 font-sans text-white'
                          }
                          onClick={(e) => {
                            e.preventDefault()
                            setIsOpenModeModal(true)
                          }}
                        >
                          ?
                        </div>
                      )}

                      {title === 'rankup' && (
                        <div
                          className={
                            'flex h-5 w-5 cursor-pointer select-none items-center justify-center rounded-full bg-darul-400 font-sans text-white'
                          }
                          onClick={(e) => {
                            e.preventDefault()
                            setOpenLPShieldModal(true)
                          }}
                        >
                          ?
                        </div>
                      )}

                      {/*{title === 'xmas' && (*/}
                      {/*  <div className={'relative h-5 w-28'}>*/}
                      {/*    <Image*/}
                      {/*      src={LimitedTimeIcon}*/}
                      {/*      alt={''}*/}
                      {/*      className={'object-contain'}*/}
                      {/*      fill={true}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </div>

                    <ProductCardV2
                      prices={prices}
                      key={product.id}
                      product={product}
                      isLoading={isLoading}
                      currencyId={currency?.id ?? 1}
                      imageUrl={
                        product.imageAsset?.imageUrl ??
                        process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                      }
                      teammate={
                        game?.key !== 'egirls' &&
                        isCompanionMode(product.mode, product.slug)
                          ? filterTeammates(
                              product.slug,
                              product.categoryKey,
                              game?.key,
                            )
                          : null
                      }
                      decoration={filterDecoration(product.mode, product.slug)}
                      game={game}
                    />
                  </div>
                ) : (
                  <ProductCardV2
                    prices={prices}
                    key={product.id}
                    product={product}
                    isLoading={isLoading}
                    currencyId={currency?.id ?? 1}
                    imageUrl={
                      product.imageAsset?.imageUrl ??
                      process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                    }
                    teammate={
                      game?.key !== 'egirls' &&
                      isCompanionMode(product.mode, product.slug)
                        ? filterTeammates(
                            product.slug,
                            product.categoryKey,
                            game?.key,
                          )
                        : null
                    }
                    decoration={filterDecoration(product.mode, product.slug)}
                    game={game}
                  />
                ),
              )
            })}
        </div>
      </RadioGroup>
    </div>
  )
}
