import React, { useRef, useState } from 'react'
import { UserResponse } from '../../../../types/users/user-response'
import Image from 'next/image'
import useFriendListCard from './useFriendListCard'
import FriendCardControl from './FriendCardControl'
import { MitrensRecommendSupplier } from '../../../../types/teams/responses/team-friendlist-response'
import { useRecoilState } from 'recoil'
import { useLocalizedPrice } from '../../../../hooks/currencies/useLocalizedPrice'
import { formatAmountWithCurrency } from '../../../../common/utils/currency.utils'
import { RequestTeamTeammateStatusEnum } from '../../../../common/enums/request-team-teammate-status.enum'
import { isBlinkingState } from './FriendListSection'
import FriendCardTag from './rank/FriendCardTag'
import { Tooltip, TooltipRefProps } from 'react-tooltip'
import RankInfoSection from './rank/RankInfoSection'
import { GameCategories } from '../../../../common/enums/game-categories'
import { isNil } from '../../../../common/utils/common.utils'
import { TeamTeammate } from '../../../../types/teams/responses/team-teammate-response'
import dynamic from 'next/dynamic'

const FriendInfoModal = dynamic(() => import('./modal/FriendInfoModal'), {
  ssr: true,
})

const FriendInfoContent = dynamic(() => import('./FriendInfoContent'), {
  ssr: true,
})

type Props = {
  status?: string
  onClickInvite?: () => void
  onClickRemove?: () => void
  creator?: UserResponse
  mitrensRecommendSupplier?: MitrensRecommendSupplier
  isLoading?: boolean
  smallScreen?: boolean
  categoryKey?: string
  teammate?: TeamTeammate
  productId?: number
}

export default function FriendListCard({
  status,
  onClickInvite,
  onClickRemove,
  mitrensRecommendSupplier,
  isLoading,
  categoryKey,
  smallScreen,
  teammate,
  productId,
}: Props) {
  const { statusIcon, statusColor, getRank, getStatus, findTierRankByLetter } =
    useFriendListCard()
  const { getLocalizedPrice } = useLocalizedPrice()
  const [showInfo, setShowInfo] = useState(false)
  const [showRank, setShowRank] = useState(false)
  const openState = useState(false)
  const [isOpenInfo, setIsOpenInfo] = openState
  const rank = getRank(mitrensRecommendSupplier?.user, categoryKey)
  const localizedOffset = mitrensRecommendSupplier?.localizedOffset
  const offsets =
    localizedOffset && localizedOffset.length > 0
      ? localizedOffset[0].offset
      : []
  const offset = getLocalizedPrice(offsets)
  const [isBlinking] = useRecoilState(isBlinkingState)
  function getButtonText() {
    if (
      teammate &&
      teammate?.status === RequestTeamTeammateStatusEnum.WAITING
    ) {
      return 'Inviting'
    }

    if (
      teammate &&
      teammate?.status === RequestTeamTeammateStatusEnum.ACCEPTED
    ) {
      return 'Invited'
    }

    if (offset?.price && offset.price > 0) {
      return `Invite +${formatAmountWithCurrency(offset.price, offset.currency)}`
    } else {
      return 'Invite'
    }
  }

  function getButtonType() {
    if (
      teammate &&
      teammate?.status === RequestTeamTeammateStatusEnum.WAITING
    ) {
      return 'Inviting'
    }

    if (
      teammate &&
      teammate?.status === RequestTeamTeammateStatusEnum.ACCEPTED
    ) {
      return 'Invited'
    }

    return 'Invite'
  }
  const tooltipRef1 = useRef<TooltipRefProps>(null)

  return (
    <div className={'flex flex-col'}>
      {!smallScreen && showInfo && (
        <Tooltip
          ref={tooltipRef1}
          id={`my-tooltip ${mitrensRecommendSupplier?.supplier_id}`}
          place={'left-start'}
          style={{ backgroundColor: '#1A1E29', borderRadius: '8px' }}
          opacity={1}
          isOpen={showInfo}
        >
          <div
            className={
              'pb-5 pt-1 px-4 w-full sm:w-108 md:w-108 lg:w-128 relative'
            }
          >
            <FriendInfoContent
              mitrensRecommendSupplier={mitrensRecommendSupplier}
              categoryKey={categoryKey}
              productId={productId}
            />
          </div>
        </Tooltip>
      )}

      {!smallScreen && (
        <Tooltip
          id={`my-tooltip ${mitrensRecommendSupplier?.supplier_id}`}
          place={'left-start'}
          style={{
            backgroundColor: '#242B39',
            borderRadius: '8px',
            padding: '0',
          }}
          opacity={1}
          isOpen={showRank}
        >
          <RankInfoSection />
        </Tooltip>
      )}

      <a
        data-tooltip-id={`my-tooltip ${mitrensRecommendSupplier?.supplier_id}`}
        className={`relative border-b-[0.5px] border-fizz-500 flex flex-row items-center p-4 cursor-pointer h-[94px] ${isBlinking ? 'blinking' : 'bg-fizz-800  hover:bg-fizz-700 transition duration-300'}`}
        onClick={() => {
          if (smallScreen) {
            setIsOpenInfo(true)
          }
        }}
        onMouseEnter={(e) => {
          e.stopPropagation()
          setShowInfo(true)
        }}
        onMouseLeave={(e) => {
          setShowInfo(false)
        }}
      >
        {categoryKey === GameCategories.leagueOfLegends &&
          (productId === 12 || productId === 13) && (
            <div
              onMouseEnter={(e) => {
                e.stopPropagation()
                try {
                  tooltipRef1?.current?.close()
                  setShowRank(true)
                  setShowInfo(false)
                } catch (e) {}
              }}
              onMouseLeave={(e) => {
                try {
                  setShowRank(false)
                } catch (e) {}
              }}
            >
              <FriendCardTag
                type={
                  findTierRankByLetter(
                    mitrensRecommendSupplier?.performance_score_letter,
                  )?.type ?? ''
                }
                title={
                  findTierRankByLetter(
                    mitrensRecommendSupplier?.performance_score_letter,
                  )?.title ?? ''
                }
                isNew={isNil(
                  mitrensRecommendSupplier?.performance_score_letter,
                )}
              />
            </div>
          )}

        <div
          className={`relative h-14 w-14 rounded-full border-2 shrink-0 border-fizz-600 ${categoryKey === GameCategories.leagueOfLegends && 'ml-3'}`}
        >
          <div
            className={
              'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-12 w-12 rounded-full'
            }
          >
            <Image
              src={
                mitrensRecommendSupplier?.user?.avatar?.imageUrl ??
                process.env.NEXT_PUBLIC_DEFAULT_CUSTOMER_AVATAR_URL!
              }
              className={'rounded-full object-cover'}
              fill={true}
              alt={'Avatar image'}
            />
          </div>

          <div
            className={
              'absolute right-0 bottom-0 translate-x-1/3  h-4 w-4 rounded-full'
            }
          >
            <Image
              src={statusIcon(
                mitrensRecommendSupplier?.on_duty ?? false,
                mitrensRecommendSupplier?.in_session ?? false,
              )}
              className={'object-contain'}
              fill={true}
              alt={'status icon'}
            />
          </div>
        </div>

        <div className={'flex flex-col ml-4 grow'}>
          <p className={'text-white text-secondary-bold w-24 sm:w-32 truncate'}>
            {mitrensRecommendSupplier?.supplier_name ??
              mitrensRecommendSupplier?.user?.username ??
              mitrensRecommendSupplier?.user?.fullName ??
              'Teammate'}
          </p>

          <div
            className={`text-secondary-medium ${statusColor(
              mitrensRecommendSupplier?.on_duty ?? false,
              mitrensRecommendSupplier?.in_session ?? false,
            )}`}
          >
            {getStatus(
              mitrensRecommendSupplier?.on_duty ?? false,
              mitrensRecommendSupplier?.in_session ?? false,
              mitrensRecommendSupplier?.user,
            ) ?? 'Offline'}
          </div>

          {rank &&
            rank !== '' &&
            (categoryKey !== GameCategories.leagueOfLegends ||
              !(productId === 12 || productId === 13)) && (
              <div className={'text-secondary-normal text-fizz-100'}>
                {rank}
              </div>
            )}
        </div>

        {mitrensRecommendSupplier?.on_duty === true && (
          <FriendCardControl
            status={getButtonType() as 'Inviting' | 'Invited' | 'Invite'}
            onClickInvite={onClickInvite}
            onClickRemove={onClickRemove}
            buttonText={getButtonText() + ''}
            isDisabled={mitrensRecommendSupplier?.in_session === true}
            isLoading={isLoading}
          />
        )}

        {isOpenInfo && (
          <FriendInfoModal
            mitrensRecommendSupplier={mitrensRecommendSupplier}
            isOpenState={openState}
            categoryKey={categoryKey}
            productId={productId}
          />
        )}
      </a>
    </div>
  )
}
