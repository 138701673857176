import { PutUpdateUserRequest } from '../../types/users/requests/put-update-user-request'
import { putUpdateMe } from '../../api/users/users'
import { useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

export function useUpdateUser() {
  const { data } = useSession()

  return useMutation<Response, Error, PutUpdateUserRequest>(
    async (request: PutUpdateUserRequest) =>
      // @ts-ignore
      putUpdateMe(data?.accessToken, request),
  )
}
