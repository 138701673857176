import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import GroupHeader from './GroupHeader'
import FriendListCard from './FriendListCard'
import { MitrensRecommendSupplier } from '../../../../types/teams/responses/team-friendlist-response'
import { useInviteTeammateToTeam } from '../../../../hooks/teams/useInviteTeammateToTeam'
import { useGetActiveTeam } from '../../../../hooks/teams/useGetActiveTeam'
import { toast } from 'react-toastify'
import { readableError } from '../../../../types/errors/responses/error-response'
import { useRecoilState } from 'recoil'
import { isOpenFriendListSection } from './FriendListSection'
import { TeamResponse } from '../../../../types/teams/responses/team-response'

type Props = {
  team?: TeamResponse
  title: string
  mitrensRecommendSupplier?: MitrensRecommendSupplier[]
  smallScreen?: boolean
  isOpen: boolean
  maxHeight: string
  toggleOpen: () => void // Added for toggling isOpen from parent
}

export default function CollapseChild({
  team,
  title,
  mitrensRecommendSupplier,
  smallScreen,
  isOpen,
  maxHeight,
  toggleOpen,
}: Props) {
  const [isOpenFriendList, setIsOpenFriendList] = useRecoilState(
    isOpenFriendListSection,
  )
  const {
    mutate: invite,
    isLoading: inviteLoading,
    isSuccess: inviteSuccess,
    error: inviteError,
  } = useInviteTeammateToTeam()
  const { refetch: refetchActiveTeam } = useGetActiveTeam()

  const containerStyles = {
    maxHeight: maxHeight,
    transition: 'max-height 0.2s ease-out',
    overflow: 'hidden',
  }

  useEffect(() => {
    if (inviteSuccess) {
      refetchActiveTeam()
    }
  }, [inviteSuccess])

  useEffect(() => {
    if (inviteError) {
      refetchActiveTeam()
      toast.error(readableError(inviteError))
    }
  }, [inviteError])

  return (
    <div style={containerStyles}>
      <GroupHeader title={title} isOpen={isOpen} onClick={toggleOpen} />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ overflowY: 'auto', maxHeight: 'calc(100% - 45px)' }}
            className='flex flex-col no-scrollbar'
          >
            {mitrensRecommendSupplier?.map((item) => (
              <FriendListCard
                key={item.supplier_id}
                mitrensRecommendSupplier={item}
                onClickInvite={() => {
                  if (team?.id) {
                    invite({
                      teamId: team?.id,
                      teammateId: item.supplier_id + '',
                    })
                  }
                  if (smallScreen && isOpenFriendList) {
                    setIsOpenFriendList(false)
                  }
                }}
                isLoading={inviteLoading}
                smallScreen={smallScreen}
                categoryKey={team?.categoryKey}
                teammate={team?.teammates.find((teamTeammate) => {
                  return teamTeammate.userId === item.supplier_id
                })}
                productId={team?.productId}
              />
            ))}

            {/*{(mitrensRecommendSupplier?.length ?? 0) > 0 && (*/}
            {/*  <div className={'pb-8'}></div>*/}
            {/*)}*/}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
