import { authFetch } from '../../common/networking/auth-fetch'
import { RequestPaginationResponse } from '../../types/requests/responses/request-pagination-response'

export async function getRequests(
  accessToken: any,
): Promise<RequestPaginationResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/fans/requests`,
  )
  return await response.json()
}
