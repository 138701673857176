import React, { ReactElement, ReactNode, useState } from 'react'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { NextPage } from 'next'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { Hydrate, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'next-themes'
import { start } from '../lib/bugsnag'
import { manrope, oswald } from '../common/fonts/fonts'
import { selectedGameState } from '../common/atoms/atoms'
import { selectedProductState } from '../common/atoms/checkout'
import 'swiper/css'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { GoogleAnalytics } from '@next/third-parties/google'
import FacebookPixel from '../components/FacebookPixel'
import Script from 'next/script'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
  theme?: string
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

start()

function MyApp({
  Component,
  //@ts-ignore
  pageProps: { session, initialRecoilState, ...pageProps },
}: AppPropsWithLayout) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
          },
        },
      }),
  )

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  // Keep a reference to all atoms by key for initializeRecoilState
  const allAtoms = {
    game: selectedGameState,
    product: selectedProductState,
  }

  // Where the magic happens: Used by RecoilRoot to update atoms by key given an
  // initial state object of the form {key: initial_value}
  const initializeRecoilState =
    (initialRecoilState: any) =>
    //@ts-ignore
    ({ set }) => {
      if (initialRecoilState !== undefined && initialRecoilState !== null)
        Object.keys(initialRecoilState).forEach((key) => {
          const value = initialRecoilState[key]
          if (value === undefined || value === null) return
          //@ts-ignore
          const atom = allAtoms[key]
          set(atom, value)
        })
    }

  const persister = createSyncStoragePersister({
    storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    throttleTime: 500,
  })

  // const userID = Cookies.get(UID_COOKIE)

  return (
    <ThemeProvider
      defaultTheme={'dark'}
      attribute={'class'}
      enableSystem={false}
      enableColorScheme={false}
      forcedTheme={Component.theme || undefined}
    >
      <RecoilRoot initializeState={initializeRecoilState(initialRecoilState)}>
        <SessionProvider
          //@ts-ignore
          session={session}
          // refetchInterval={1800} // 30 minutes
          // refetchOnWindowFocus={true}
        >
          <PersistQueryClientProvider
            client={queryClient}
            // @ts-ignore
            persistOptions={{ persister }}
          >
            <Hydrate state={pageProps.dehydratedState}>
              <style jsx global>{`
                :root {
                  --font-oswald: ${oswald.style.fontFamily};
                  --font-manrope: ${manrope.style.fontFamily};
                }
              `}</style>
              <GoogleAnalytics gaId='G-X30BS5B3K3' />
              <FacebookPixel />
              <Script
                id='tiktok-pixel'
                strategy='afterInteractive'
                dangerouslySetInnerHTML={{
                  __html: `
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            ttq.load('CGIGVSJC77U55TSEMBH0');
            ttq.page();
          }(window, document, 'ttq');
        `,
                }}
              />
              {/*<Script*/}
              {/*  id='water-init'*/}
              {/*  dangerouslySetInnerHTML={{*/}
              {/*    __html: `*/}
              {/*(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://load.water.tapin.gg/qspgdugs.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NSPGC2N');`,*/}
              {/*  }}*/}
              {/*/>*/}

              {getLayout(<Component {...pageProps} />)}
            </Hydrate>
            <ReactQueryDevtools initialIsOpen={false} />
          </PersistQueryClientProvider>
        </SessionProvider>
      </RecoilRoot>
    </ThemeProvider>
  )
}

export default appWithTranslation(MyApp)
