import { homeUrl } from '../../common/urls/homeUrl'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'
import { activeRequestState } from '../../common/atoms/checkout'
import {
  activeHomeRequests,
  activeRequests,
} from '../../common/react-query/react-query-keys'
import { useQueryClient } from '@tanstack/react-query'

export function useRequestStates() {
  const router = useRouter()
  const clientQuery = useQueryClient()
  const [, setActiveRequest] = useRecoilState(activeRequestState)

  function redirectToHome() {
    try {
      setActiveRequest(null)
      clientQuery.removeQueries([activeHomeRequests])
      clientQuery.removeQueries([activeRequests])
    } catch (e) {}
    router.push(homeUrl)
  }

  return {
    redirectToHome,
  }
}
