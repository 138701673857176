import React from 'react'
import RankPercentItem from './RankPercentItem'
import useFriendListCard from '../useFriendListCard'

export default function RankInfoSection() {
  const { tierRanksArray: ranks } = useFriendListCard()
  return (
    <div className={'relative w-[264px] rounded-lg overflow-hidden'}>
      <div className={'flex flex-col items-start pt-3 px-3 pb-2'}>
        <h3 className={'text-white font-oswald text-body-semibold uppercase'}>
          Skill Rank
        </h3>
        {/*<p className={'text-fizz-0 text-secondary-normal'}>*/}
        {/*  Libero sollicitudin nec scelerisq.*/}
        {/*</p>*/}
      </div>

      {ranks.map((item, index) => {
        if (index > 5) return null
        return (
          <div
            key={index}
            className={`py-2 px-3 ${index % 2 === 0 ? 'bg-fizz-700' : 'bg-fizz-600'}`}
          >
            <RankPercentItem
              title={item.title}
              type={item.type}
              percent={item.percent}
            />
          </div>
        )
      })}
    </div>
  )
}
