import React from 'react'
import SmallFriendCardTag from './SmallFriendCardTag'

type Props = {
  title: string
  type?: string
  percent?: string
}
export default function RankPercentItem({ title, type = 's', percent }: Props) {
  return (
    <div className={'flex flex-row items-center justify-between'}>
      <div className={'flex flex-row items-center'}>
        <SmallFriendCardTag title={title} type={type} />
        <p className={'text-white text-secondary-bold ml-3'}>Tier {title}:</p>
      </div>

      <p className={'text-fizz-100 text-secondary-normal'}>
        Top {percent ?? 0}%
      </p>
    </div>
  )
}
