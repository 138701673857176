import React from 'react'
import Discord from '../../public/static/join-discord-v3.svg'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import Logo from '../../public/static/logo-white.svg'
import ApplePayIcon from '../../public/static/payment-v2/ApplePay.svg'
import GooglePayIcon from '../../public/static/payment-v2/GooglePay.svg'
import VisaIcon from '../../public/static/payment-v2/Visa.svg'
import AmexIcon from '../../public/static/payment-v2/Amex.svg'
import DinersClubIcon from '../../public/static/payment-v2/DinersClub.svg'
import DiscoverIcon from '../../public/static/payment-v2/Discover.svg'
import PayPalIcon from '../../public/static/payment-v2/PayPal.svg'
import MastercardIcon from '../../public/static/payment-v2/Mastercard.svg'
import VenmoIcon from '../../public/static/payment-v2/Venmo.svg'
import TikTokIcon from '../../public/static/join-tiktok-v3.svg'
import Youtube from '../../public/static/join-youtube.svg'
import { howItWorksUrl } from '../../common/urls/homeUrl'
import GoogleIcon from '../../public/static/download/google.png'
import AppleIcon from '../../public/static/download/apple.png'

export default function Footer() {
  const { t } = useTranslation('common')

  return (
    <div className='container z-10 mx-auto mt-20 flex flex-col justify-between pb-12 dark:border-gray2-dark'>
      <div
        className={
          'flex flex-col items-center justify-between gap-2 md:flex-row md:gap-4'
        }
      >
        <div className='flex w-full flex-col items-center gap-2 md:flex-row md:gap-3'>
          <div className={'flex flex-row'}>
            <div className={'relative h-8 w-18'}>
              <Image
                src={Logo}
                fill={true}
                className={'object-contain'}
                alt={'Logo icon'}
              />
            </div>
          </div>

          <h3 className='hidden text-fizz-100 md:flex mx-2'>•</h3>

          <Link
            className='hover:text-white cursor-pointer'
            href={howItWorksUrl}
          >
            <p
              className={
                'text-secondary-normal cursor-pointer text-center text-fizz-100 hover:text-white'
              }
            >
              {t('howItWorks')}
            </p>
          </Link>

          <Link
            className='hover:text-white cursor-pointer'
            href='/blogs/pages/1'
          >
            <p
              className={
                'text-secondary-normal cursor-pointer text-center text-fizz-100 hover:text-white'
              }
            >
              {t('blogs')}
            </p>
          </Link>

          <Link className='hover:text-white cursor-pointer' href='/contact'>
            <p
              className={
                'text-secondary-normal cursor-pointer text-center text-fizz-100 hover:text-white'
              }
            >
              {t('contact')}
            </p>
          </Link>

          <Link
            className='hover:text-white cursor-pointer'
            href='/legal/privacy'
          >
            <p
              className={
                'text-secondary-normal cursor-pointer text-center text-fizz-100 hover:text-white'
              }
            >
              {t('privacy')}
            </p>
          </Link>

          <Link className='hover:text-white cursor-pointer' href='/legal/terms'>
            <p
              className={
                'text-secondary-normal cursor-pointer text-center text-fizz-100 hover:text-white'
              }
            >
              {t('terms')}
            </p>
          </Link>

          {/*{(!user || (user && !isCreator(user))) && (*/}
          <Link
            className='hover:text-white cursor-pointer'
            href='/applications/intro'
          >
            <p
              className={
                'text-secondary-normal cursor-pointer text-center text-fizz-100 hover:text-white'
              }
            >
              {t('teammates')}
            </p>
          </Link>

          <Link
            className='hover:text-white cursor-pointer'
            href='https://pwxlb67wize.typeform.com/to/xbIlI7Sx'
          >
            <p
              className={
                'text-secondary-normal cursor-pointer text-center text-fizz-100 hover:text-white'
              }
            >
              {t('youTube')}
            </p>
          </Link>
        </div>
      </div>

      {/*row-2*/}
      <div
        className={
          '-ml-1 mt-2 flex flex-col items-center justify-between gap-2 md:flex-row md:gap-8'
        }
      >
        {/*<a*/}
        {/*  href='https://www.trustpilot.com/review/tapin.gg'*/}
        {/*  target='_blank'*/}
        {/*  rel='noopener noreferrer'*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className={*/}
        {/*      'pointer-events-none relative h-7 w-72 px-8 md:h-8 md:w-88 md:px-0 lg:h-9 lg:w-108'*/}
        {/*    }*/}
        {/*  >*/}
        {/*    <Image*/}
        {/*      src={TrustpilotIcon}*/}
        {/*      fill={true}*/}
        {/*      className={'object-contain'}*/}
        {/*      alt={'Trust pilot icon'}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</a>*/}

        <div className='flex flex-col items-center justify-evenly gap-1 md:flex-row'>
          <div className={'flex flex-row gap-1.5'}>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={ApplePayIcon}
                fill={true}
                className={'object-cover'}
                alt={'Visa icon'}
              />
            </div>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={GooglePayIcon}
                fill={true}
                className={'object-cover'}
                alt={'Visa icon'}
              />
            </div>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={VisaIcon}
                fill={true}
                className={'object-cover'}
                alt={'Visa icon'}
              />
            </div>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={AmexIcon}
                fill={true}
                className={'object-cover'}
                alt={'Amex icon'}
              />
            </div>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={DinersClubIcon}
                fill={true}
                className={'object-cover'}
                alt={'Diners club icon'}
              />
            </div>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={VenmoIcon}
                fill={true}
                className={'object-cover'}
                alt={'Venmo icon'}
              />
            </div>
          </div>

          <div className={'flex flex-row gap-1.5'}>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={DiscoverIcon}
                fill={true}
                className={'object-cover'}
                alt={'Discover icon'}
              />
            </div>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={MastercardIcon}
                fill={true}
                className={'object-cover'}
                alt={'Master card icon'}
              />
            </div>
            <div className={'relative mx-auto h-7 w-10 lg:h-9 lg:w-12'}>
              <Image
                src={PayPalIcon}
                fill={true}
                className={'object-cover'}
                alt={'Paypal icon'}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Right */}
      <div
        className={
          'mt-4 flex flex-col items-center justify-between gap-2 md:flex-row'
        }
      >
        <h3 className='text-xs-normal pt-2 text-fizz-100 md:flex md:pt-0'>
          {t('rights')}
        </h3>

        <div className={'flex flex-col items-center gap-5 md:flex-row'}>
          <div className={'flex flex-row items-center gap-5'}>
            <a
              target={'_blank'}
              className='link-hover link flex cursor-pointer justify-self-end transition duration-150 hover:-translate-y-0.5 hover:bg-opacity-30'
              href='https://www.tiktok.com/@tapinlol'
              rel='noreferrer'
            >
              <Image
                src={TikTokIcon}
                alt={'TikTok'}
                width={21}
                height={24}
                className={'object-contain'}
              />
            </a>
            <a
              target={'_blank'}
              className='link-hover link flex cursor-pointer justify-self-end transition duration-150 hover:-translate-y-0.5 hover:bg-opacity-30'
              href='https://www.youtube.com/@TapinLeague'
              rel='noreferrer'
            >
              <Image
                src={Youtube}
                alt={'Youtube'}
                width={21}
                height={24}
                className={'object-contain'}
              />
            </a>

            <a
              target={'_blank'}
              className='link-hover link flex cursor-pointer justify-self-end transition duration-150 hover:-translate-y-0.5 hover:bg-opacity-30'
              href={'https://discord.gg/tapingg'}
              rel='noreferrer'
            >
              <Image
                src={Discord}
                width={117.25}
                height={41}
                className={'object-contain'}
                alt={'Discord icon'}
              />
            </a>
          </div>

          <div className={'flex flex-row items-center gap-5'}>
            <a
              target={'_blank'}
              className='md:flex link-hover link  cursor-pointer justify-self-end transition duration-150 hover:-translate-y-0.5 hover:bg-opacity-30'
              href='https://play.google.com/store/apps/details?id=com.tapin.user'
              rel='noreferrer'
            >
              <Image
                src={GoogleIcon}
                alt={'Google play icon'}
                width={117.25}
                height={41}
                className={'object-contain'}
              />
            </a>

            <a
              target={'_blank'}
              className='md:flex link-hover link  cursor-pointer justify-self-end transition duration-150 hover:-translate-y-0.5 hover:bg-opacity-30'
              href='https://apps.apple.com/us/app/id6458741754'
              rel='noreferrer'
            >
              <Image
                src={AppleIcon}
                alt={'Apple store icon'}
                width={117.25}
                height={41}
                className={'object-contain'}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
