import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { selectedAccountProductState } from '../../../../common/atoms/checkout'
import InfoSelector, { option } from './InfoSelector'
import ChampionsSection from './champions/ChampionsSection'
import SkinsSection from './skins/SkinsSection'
import AccountInfo from './AccountInfo'
import { useAccountMarket } from '../../../../hooks/accounts/useAccountChampion'

export default function AccountInfoSection() {
  const [selectedAccountProduct] = useRecoilState(selectedAccountProductState)
  const selectorState = useState(option[0])
  const [currentMode] = selectorState
  const accountMarket = useAccountMarket()

  return (
    <div className={'flex flex-col h-full w-full'}>
      {selectedAccountProduct && (
        <AccountInfo accountProduct={selectedAccountProduct} />
      )}

      <div className={'mt-6'}></div>
      <InfoSelector
        accountProduct={selectedAccountProduct}
        currentOptionState={selectorState}
      />

      <div className={'mt-4'} />
      {currentMode === option[0] && (
        <ChampionsSection accountMarket={accountMarket} />
      )}
      {currentMode === option[1] && (
        <SkinsSection accountMarket={accountMarket} />
      )}
    </div>
  )
}
