import { useSession } from 'next-auth/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { ChampionResponse } from '../../types/users/responses/champion-response'
import { getMyChampions, postMyChampions } from '../../api/users/users'
import { PostChampionRequest } from '../../types/users/requests/post-champion-request'
import { getCategoryChampions } from '../../api/categories/category-tag'
import { isNil } from '../../common/utils/common.utils'

export function useGetMyChampions() {
  const { data, status } = useSession()

  return useQuery<ChampionResponse[]>(
    ['myChampions'],
    //@ts-ignore
    async () => getMyChampions(data?.accessToken),
    {
      enabled:
        status === SessionStatusEnum.AUTHENTICATED ||
        status === SessionStatusEnum.UNAUTHENTICATED,
    },
  )
}

export function usePostMyChampion() {
  const { data } = useSession()

  return useMutation(async (request: PostChampionRequest) =>
    //@ts-ignore
    postMyChampions(data?.accessToken, request),
  )
}

export function useGetChampions(key?: string) {
  const { data } = useSession()
  return useQuery<ChampionResponse[]>(
    ['champions', key],
    //@ts-ignore
    async () => getCategoryChampions(data?.accessToken, key),
    {
      enabled: !isNil(key),
    },
  )
}

export default function useChampion(key?: string) {
  const { data: champions } = useGetChampions(key)

  const { data: myChampions } = useGetMyChampions()

  return {
    champions,
    myChampions,
  }
}
