import React from 'react'

import Divider from '../../divider/Divider'

type Props = {
  title: string
}

export function TitlePart({ title }: Props) {
  return (
    <div className={'flex flex-col'}>
      <h3 className={'text-2xl-normal text-white'}>{title}</h3>
      <div className={'mt-2'}>
        <Divider backgroundColor={'border-fizz-500'}></Divider>
      </div>
    </div>
  )
}
