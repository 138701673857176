import React from 'react'

import Image from 'next/image'
import { LolAccountResponse } from '../../../../types/account/lol-account-response'

import IconRP from '../../../../public/static/product/account/icon-riot-point.svg'
import IconBE from '../../../../public/static/product/account/icon-blue-essence.svg'
import IconLV from '../../../../public/static/product/account/icon-level.svg'
import InfoItem from './infoItem'

type Props = { accountProduct: LolAccountResponse }

export default function AccountInfo({ accountProduct }: Props) {
  return (
    <div className={'w-full'}>
      <div className={'flex flex-row items-center gap-3'}>
        <div className={'relative h-[50px] w-[43px] shrink-0'}>
          <Image
            src={
              accountProduct.imageUrl ??
              process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
            }
            alt={accountProduct.title ?? ''}
            fill={true}
            className={'object-contain'}
          />
        </div>
        <h3 className={'text-white text-xl font-bold'}>
          {accountProduct.title}
        </h3>

        {/*{isOnSale && (*/}
        {/*  <div className={'relative  h-[24px] w-[53px] shrink-0'}>*/}
        {/*    <Image src={IconSale} fill={true} alt={'Cloud Icon'} />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      <div className={'flex flex-row items-center justify-between mt-3'}>
        {accountProduct.rp && (
          <InfoItem
            image={IconRP}
            title={'Riot Points'}
            value={accountProduct.rp}
          />
        )}

        {accountProduct.blueEssence && (
          <InfoItem
            image={IconBE}
            title={'Blue Essence'}
            value={accountProduct.blueEssence}
          />
        )}

        {accountProduct.level && (
          <InfoItem
            image={IconLV}
            title={'Level'}
            value={accountProduct.level}
          />
        )}
      </div>
    </div>
  )
}
