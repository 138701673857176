import { useEffect } from 'react'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useRecoilState } from 'recoil'
import {
  isDefaultMethodLoadedState,
  isOpenStripePaymentState,
  isOpenWalletState,
} from '../../common/atoms/checkout'
import { useUser } from '../users/useUser'
import { useSession } from 'next-auth/react'
import { useGetPayments } from '../stripe/useGetPayments'
import { selectedPaymentState } from '../../common/atoms/atoms'

export function useWallet() {
  const { refetch: refetchUser } = useUser()
  const { status } = useSession()
  const [isOpenWallet] = useRecoilState(isOpenWalletState)
  const [isOpenStripeAddPayment] = useRecoilState(isOpenStripePaymentState)
  const [, setIsDefaultMethodLoaded] = useRecoilState(
    isDefaultMethodLoadedState,
  )
  const [, setSelectedPayment] = useRecoilState(selectedPaymentState)
  const { data: payments, refetch: refetchPayments } = useGetPayments()

  useEffect(() => {
    if (!isOpenStripeAddPayment && status === SessionStatusEnum.AUTHENTICATED) {
      refetchPayments()
    }
  }, [isOpenStripeAddPayment])

  useEffect(() => {
    if (payments) {
      const payment = payments?.find((payment) => payment.isDefault)

      if (payment) {
        setSelectedPayment(payment)
      } else {
        setSelectedPayment(null)
      }

      setIsDefaultMethodLoaded(true)
    }
  }, [payments])

  useEffect(() => {
    if (
      (isOpenWallet || !isOpenStripeAddPayment) &&
      status === SessionStatusEnum.AUTHENTICATED
    ) {
      refetchUser()
    }
  }, [isOpenWallet, isOpenStripeAddPayment])
}
