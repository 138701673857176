import { useMutation } from '@tanstack/react-query'
import { getPromoCode } from '../../api/stripe/getPromoCode'
import { useSession } from 'next-auth/react'

export function useGetPromoCode() {
  const { data } = useSession()

  return useMutation(
    async (request: {
      promoCode: string
      quantity?: number
      productId?: number
      shouldThrowError?: boolean
    }) =>
      // @ts-ignore
      getPromoCode(data?.accessToken, request),
    {
      retry: false,
    },
  )
}
