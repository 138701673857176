import React from 'react'

import { ToastContainer } from 'react-toastify'

export default function CustomizedToastContainer() {
  return (
    <ToastContainer
      bodyClassName={'text-secondary-base text-black'}
      autoClose={2500}
      position={'top-center'}
      theme={'light'}
      closeButton={false}
      stacked={true}
      closeOnClick={true}
    />
  )
}
