import { authFetch } from '../../common/networking/auth-fetch'
import { PromoCodeResponse } from '../../types/stripe/responses/promo-code-response'
import { CampaginsResponse } from '../../types/stripe/responses/campagins-response'
import { isNil } from '../../common/utils/common.utils'

export async function getPromoCode(
  accessToken: any,
  request: {
    promoCode: string
    quantity?: number
    productId?: number
    shouldThrowError?: boolean
  },
): Promise<PromoCodeResponse> {
  let url = new URL(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/promo-codes/${request.promoCode}`,
  )
  if (!isNil(request.quantity)) {
    url.searchParams.append('quantity', request.quantity?.toString() ?? '1')
  }
  if (!isNil(request.productId)) {
    url.searchParams.append('productId', request.productId?.toString() ?? '1')
  }

  if (!isNil(request.shouldThrowError)) {
    url.searchParams.append(
      'shouldThrowError',
      request.shouldThrowError?.toString() ?? 'false',
    )
  }

  const response = await authFetch(accessToken).get(url.toString())
  return await response.json()
}

export async function deletePromoCode(
  accessToken: any,
  promoCodeId: number,
): Promise<Response> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/promo-codes/${promoCodeId}`
  return await authFetch(accessToken).delete(url)
}

export async function getPromoCodesByBatch(
  accessToken: any,
  batch: string,
): Promise<PromoCodeResponse> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/promo-codes/campaigns/batches/${batch}`
  const response = await authFetch(accessToken).get(url)
  return await response.json()
}

export async function getPromoCodesByBatchAndCode(
  accessToken: any,
  batch: string,
  code: string,
): Promise<PromoCodeResponse> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/promo-codes/campaigns/batches/${batch}/promo-code/${code}`
  const response = await authFetch(accessToken).get(url)
  return await response.json()
}

export async function getPromoCodesCampaigns(
  accessToken: any,
): Promise<CampaginsResponse[]> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/promo-codes/campaigns`
  const response = await authFetch(accessToken).get(url)
  return await response.json()
}

export async function putPromoCodeClaimedById(
  accessToken: any,
  id: string,
): Promise<PromoCodeResponse> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/promo-codes/campaigns/${id}/claimed`
  const response = await authFetch(accessToken).put(url)
  return await response.json()
}
