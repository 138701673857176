import { useRecoilState } from 'recoil'
import {
  formatAmountWithCurrency,
  getCurrentCreditAmountWithMultipleCurrencyResponse,
} from '../../common/utils/currency.utils'
import { useUser } from '../users/useUser'
import { defaultCurrencyState } from '../../common/atoms/atoms'

export function useMoneySavedAmount() {
  const { user } = useUser()
  const [currency] = useRecoilState(defaultCurrencyState)
  const SavedMoneyAmountWithMultipleCurrencies =
    getCurrentCreditAmountWithMultipleCurrencyResponse(
      user?.rewardProgram?.savedMoneyAmountWithMultipleCurrencies ?? [],
      currency?.id ?? 1,
    )

  return SavedMoneyAmountWithMultipleCurrencies
    ? formatAmountWithCurrency(
        SavedMoneyAmountWithMultipleCurrencies.amount,
        SavedMoneyAmountWithMultipleCurrencies.currency,
        2,
      )
    : '0.00'
}

export function useCreditAmount() {
  const { user } = useUser()
  const [currency] = useRecoilState(defaultCurrencyState)
  const creditAmountWithMultipleCurrencies =
    getCurrentCreditAmountWithMultipleCurrencyResponse(
      user?.creditAccount?.creditAmountWithMultipleCurrencies ?? [],
      currency?.id ?? 1,
    )

  return {
    yourCreditString: creditAmountWithMultipleCurrencies
      ? formatAmountWithCurrency(
          creditAmountWithMultipleCurrencies.amount,
          creditAmountWithMultipleCurrencies.currency,
          2,
        )
      : '0.00',
    yourCredit: creditAmountWithMultipleCurrencies?.amount,
  }
}
