import { useTranslation } from 'next-i18next'
import { useRecoilState } from 'recoil'
import Image from 'next/image'
import React from 'react'
import IconNewTag from '../../public/static/select-mode/lobby/friendlist/close/icon-new-tag.svg'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { toast } from 'react-toastify'
import { lobbyUrl } from '../../common/urls/homeUrl'
import {
  ErrorResponse,
  readableError,
} from '../../types/errors/responses/error-response'
import {
  isOpenInGameAccountModalState,
  isOpenSignUpModalState,
  quantityState,
  selectedChampionsState,
  selectedProductAddOnsState,
  selectedProductState,
} from '../../common/atoms/checkout'
import { usePostCreateTeam } from '../../hooks/teams/usePostCreateTeam'
import { useGetActiveTeam } from '../../hooks/teams/useGetActiveTeam'
import { useInGameAccounts } from '../../hooks/accounts/useInGameAccounts'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { updateStoredControl } from '../../hooks/cache/user-control-storage'
import { UserLocalstorage } from '../../types/users/user-local-storage'
import { selectedGameState } from '../../common/atoms/atoms'

type Props = {
  title?: string
  showAnimatedEffect?: boolean
}

export default function CustomizeButton({
  title,
  showAnimatedEffect = false,
}: Props) {
  const router = useRouter()
  const { t: booking } = useTranslation('booking')
  const { status } = useSession()
  const [, setIsOpenSignUp] = useRecoilState(isOpenSignUpModalState)
  const { mutate: postCreateTeam, isLoading: isPostCreateTeamLoading } =
    usePostCreateTeam()
  const [selectedAddOns] = useRecoilState(selectedProductAddOnsState)
  const { refetch: refetchActiveTeam } = useGetActiveTeam('CUSTOM')
  const [, setInGameAccountModalOpen] = useRecoilState(
    isOpenInGameAccountModalState,
  )
  const { hasDefaultInGameAccount } = useInGameAccounts(true)
  const [selectedProduct] = useRecoilState(selectedProductState)
  const [game] = useRecoilState(selectedGameState)
  const [quantity] = useRecoilState(quantityState)
  const [champions] = useRecoilState(selectedChampionsState)

  function getButtonClassName() {
    return 'relative w-full no-animation btn-v2-new font-normal text-lg hover:opacity-80 transition duration-300'
  }

  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        if (isPostCreateTeamLoading) return
        if (status === SessionStatusEnum.UNAUTHENTICATED) {
          setIsOpenSignUp(true)
          return
        }

        if (!hasDefaultInGameAccount()) {
          setInGameAccountModalOpen(true)
          return
        }

        if (!selectedProduct) {
          toast.error('Please select a product to continue.')
          return
        }

        // Save user last selected service.
        try {
          updateStoredControl({
            selectedGame: game,
            selectedProduct: selectedProduct,
          } as UserLocalstorage)
        } catch (e) {}

        postCreateTeam(
          {
            productId: selectedProduct?.id,
            addonIds: selectedAddOns?.map((addon) => addon.id),
            quantity: quantity ?? 1,
            champions:
              selectedProduct?.id === 27 || selectedProduct?.id === 122
                ? champions
                : undefined,
          },
          {
            onSuccess: () => {
              router.push(lobbyUrl)
            },
            onError: (error) => {
              refetchActiveTeam()
              toast.error(readableError(error as ErrorResponse))
            },
          },
        )
      }}
      className={getButtonClassName()}
    >
      {isPostCreateTeamLoading && <span className='loading loading-spinner' />}

      {!isPostCreateTeamLoading && (
        <span className={'z-20'}>{title ?? booking('confirmButtonTitle')}</span>
      )}

      <div className={'absolute top-0 left-0 w-[35px] h-[35px] shrink-0'}>
        <Image
          className='object-contain'
          src={IconNewTag}
          alt={'new'}
          fill={true}
        />
      </div>
    </button>
  )
}
