import { CheckoutPayloadResponse } from '../../types/checkout/responses/checkout-payload-response'
import { sendGAEvent } from '@next/third-parties/google'

export function useLogGAPurchaseEvent() {
  function logGAPurchaseEvent(
    checkoutResponse?: CheckoutPayloadResponse,
    total?: number,
    email?: string,
    isNewUser: boolean = false,
    userId?: number,
  ) {
    if (process.env.NODE_ENV !== 'production') return

    const product_quantity = checkoutResponse?.request?.orderItem?.quantity ?? 1

    if (isNewUser) {
      const transaction_id = checkoutResponse?.transaction?.id?.toString()
      try {
        window.fbq('track', 'Purchase', {
          new_customer: isNewUser,
          currency: 'USD',
          value: total ?? 0,
          external_id: userId?.toString(),
          externalId: userId?.toString(),
          email: email,
          quantity: product_quantity,
        })

        window.fbq('track', 'Unique Purchase', {
          new_customer: isNewUser,
          currency: 'USD',
          value: total ?? 0,
          external_id: userId?.toString(),
          externalId: userId?.toString(),
          email: email,
          quantity: product_quantity,
        })
      } catch (e) {}

      try {
        sendGAEvent('event', 'purchase', {
          currency: 'USD',
          transaction_id: transaction_id,
          value: total ?? 0,
          external_id: userId?.toString(),
          externalId: userId?.toString(),
          email: email,
          quantity: product_quantity,
          new_customer: isNewUser,
        })

        sendGAEvent('event', 'conversion', {
          currency: 'USD',
          transaction_id: transaction_id,
          value: total ?? 0,
          send_to: 'AW-11004250452/FKAxCN_NpOYDENSSnv8o',
          external_id: userId?.toString(),
          externalId: userId?.toString(),
          email: email,
          quantity: product_quantity,
          new_customer: isNewUser,
        })

        sendGAEvent('event', 'Unique Purchase', {
          currency: 'USD',
          transaction_id: transaction_id,
          value: total ?? 0,
          send_to: 'AW-11004250452/FKAxCN_NpOYDENSSnv8o',
          external_id: userId?.toString(),
          externalId: userId?.toString(),
          email: email,
          quantity: product_quantity,
          new_customer: isNewUser,
        })
      } catch (e) {}

      // TikTok Pixel tracking
      try {
        window.ttq.track('CompletePayment', {
          contents: [
            {
              content_type: 'product',
              content_id: transaction_id,
              quantity: product_quantity,
              price: total ?? 0,
            },
          ],
          value: total ?? 0,
          currency: 'USD',
          email: email,
          external_id: userId?.toString(),
          externalId: userId?.toString(),
          new_customer: isNewUser,
        })

        window.ttq.track('Unique Purchase', {
          contents: [
            {
              content_type: 'product',
              content_id: transaction_id,
              quantity: product_quantity,
              price: total ?? 0,
            },
          ],
          value: total ?? 0,
          currency: 'USD',
          email: email,
          external_id: userId?.toString(),
          externalId: userId?.toString(),
          new_customer: isNewUser,
        })
      } catch (e) {}
    }
  }

  return { logGAPurchaseEvent }
}
