import { useMutation } from '@tanstack/react-query'
import { deletePromoCode } from '../../api/stripe/getPromoCode'
import { useSession } from 'next-auth/react'

export function useDeletePromoCode() {
  const { data } = useSession()

  return useMutation(async (promoCodeId: number) =>
    //@ts-ignore
    deletePromoCode(data?.accessToken, promoCodeId),
  )
}
