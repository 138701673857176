import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import dynamic from 'next/dynamic'
import { useRecoilState } from 'recoil'
import {
  isOpenGuestPaymentState,
  isOpenPromoCodeManagementState,
  isOpenPromoCodeState,
  isOpenStripePaymentState,
  isOpenWalletState,
} from '../../common/atoms/checkout'
import { useWallet } from '../../hooks/wallet/useWallet'
import CustomizedToastContainer from '../common/toast/CustomizedToastContainer'

const StripePaymentModal = dynamic(
  () => import('../checkout/modals/StripePaymentModal'),
  {
    ssr: true,
  },
)
const GuestCheckoutModal = dynamic(
  () => import('../checkout/modals/GuestCheckoutModal'),
  {
    ssr: true,
  },
)
const Wallet = dynamic(() => import('../checkout/modals/WalletModal'), {
  ssr: true,
})
const PromoCodeModal = dynamic(
  () => import('../checkout/modals/PromoCodeModal'),
  {
    ssr: true,
  },
)
const PromoCodesModal = dynamic(
  () => import('../checkout/promo/PromoCodesModal'),
  {
    ssr: true,
  },
)

export default function LayoutModals() {
  const [isOpenStripePayment] = useRecoilState(isOpenStripePaymentState)
  const [isOpenGuestPayment] = useRecoilState(isOpenGuestPaymentState)
  const [isOpenWallet] = useRecoilState(isOpenWalletState)
  const [isOpenPromoCode] = useRecoilState(isOpenPromoCodeState)
  const [isOpenPromoCodeManagement] = useRecoilState(
    isOpenPromoCodeManagementState,
  )
  useWallet()

  return (
    <>
      {isOpenWallet && <Wallet />}

      {isOpenStripePayment && <StripePaymentModal />}

      {isOpenGuestPayment && <GuestCheckoutModal />}

      {isOpenPromoCode && <PromoCodeModal />}

      {isOpenPromoCodeManagement && <PromoCodesModal />}

      <CustomizedToastContainer />
    </>
  )
}
