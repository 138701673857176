import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { postConnectSSOProvider } from '../../api/sso/sso'
import { SsoRequest } from '../../types/sso/sso-request'

export function usePostConnectSSOProvider() {
  const { data } = useSession()

  return useMutation(async (request: SsoRequest) =>
    //@ts-ignore
    postConnectSSOProvider(data?.accessToken, request),
  )
}
