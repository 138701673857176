import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { TeamResponse } from '../../types/teams/responses/team-response'
import { getActiveTeam } from '../../api/teams/teams'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { activeTeam } from '../../common/react-query/react-query-keys'

export function useGetActiveTeam(type: 'CUSTOM' | 'ALL' = 'ALL') {
  const { data, status } = useSession()

  return useQuery<TeamResponse>(
    [activeTeam, type],
    // @ts-ignore
    async () => getActiveTeam(data?.accessToken, type),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
      cacheTime: 0,
      retry: 1,
    },
  )
}
