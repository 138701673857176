export const activeRequests = 'activeRequests'
export const activeHomeRequests = 'activeHomeRequests'
export const createApplication = 'createApplication'
export const categories = 'categories'
export const categoryTags = 'category-tags'
export const countries = 'countries'
export const languages = 'languages'
export const activeRequest = ['request', 'active']
export const activeRequestsV2 = ['requestsv2', 'active']
export const creator = 'creator'
export const favorites = 'favorites'
export const skin = 'skin'
export const blocked = 'blocked'
export const teammates = 'teammates'
export const report = 'report'
export const checkStatus = 'check-status'
export const shortList = 'shortListD'
export const categoryProfiles = 'category-profiles'
export const inGameRank = 'in-game-rank'
export const preferences = 'preferences'
export const teammateStatus = 'teammate status'
export const referrals = 'referrals'
export const credit_products = 'credit_products'
export const onlineUsers = ['onlineUsers', 'count', 'active']
export const activeTeam = ['activeTeam']
