import { useRecoilState } from 'recoil'
import { defaultCurrencyState } from '../../common/atoms/atoms'
import React from 'react'
import { CurrencySymbolPositionEnum } from '../../common/enums/currency-symbol-position.enum'

type Props = {
  price: number
  symbolClassName: string
  isHoliday?: boolean
}

export default function PricePart({
  price,
  symbolClassName,
  isHoliday,
}: Props) {
  const [currency] = useRecoilState(defaultCurrencyState)

  if (+price >= 10000) {
    return null
  } else if (currency?.symbolPosition === CurrencySymbolPositionEnum.LEADING) {
    return (
      <div>
        <span className={symbolClassName}>{currency?.currencySymbol}</span>
        {price.toFixed(2)}
      </div>
    )
  } else if (currency?.symbolPosition === CurrencySymbolPositionEnum.TRAILING) {
    return (
      <div>
        {price.toFixed(2)}
        <span className={symbolClassName}>{currency?.currencySymbol}</span>
      </div>
    )
  }

  return <div>{price.toFixed(2)}</div>
}
