import React from 'react'
import Image, { StaticImageData } from 'next/image'

type Props = {
  title: string
  description: string
  image?: StaticImageData
}

export function FeaturePart({ title, description, image }: Props) {
  return (
    <div className={'flex flex-col gap-1.5'}>
      <Image
        src={image ?? process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!}
        alt={'Check icon'}
        width={24}
        height={24}
      />

      <div className={'text-body-light text-white'}>{title}</div>

      <div className={'text-body-light text-darul-100'}>{description}</div>
    </div>
  )
}
