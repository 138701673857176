import React from 'react'
import CurrentBalanceSection from './CurrentBalanceSection'
import BalanceAndPayment from './BalanceAndPayment'
import DefaultCreditCard from '../DefaultCreditCard'
import NoCreditCard from '../NoCreditCard'
import { useRecoilState } from 'recoil'
import { selectedPaymentState } from '../../../common/atoms/atoms'
import { useCreateTotalAmount } from '../../../hooks/checkout/useCreateTotalAmount'
import { useCreditAmount } from '../../../hooks/currencies/useCurrencies'

type Props = {
  theme?: 'light' | 'dark'
}

export default function PaymentInfo({ theme = 'dark' }: Props) {
  const [selectedPayment] = useRecoilState(selectedPaymentState)
  const { data: total } = useCreateTotalAmount()
  const { yourCredit } = useCreditAmount()

  if ((total?.total ?? 0) === 0 && (yourCredit ?? 0) > 0) {
    return <CurrentBalanceSection theme={theme} />
  } else if (
    (total?.total ?? 0) > 0 &&
    selectedPayment &&
    (yourCredit ?? 0) > 0
  ) {
    return <BalanceAndPayment payment={selectedPayment} theme={theme} />
  } else if (selectedPayment) {
    return <DefaultCreditCard payment={selectedPayment} theme={theme} />
  } else {
    return <NoCreditCard theme={theme} />
  }
}
