import React, { useState } from 'react'
import { ChampionResponse } from '../../../../../types/users/responses/champion-response'

import ChampionsItem from '../../../../home/select/champions/ChampionsItem'
import Image from 'next/image'
import IconSearch from '../../../../../public/static/dashboard/icon_search.svg'

type Props = {
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  champions: ChampionResponse[]
  selectedState: [
    ChampionResponse | undefined,
    React.Dispatch<React.SetStateAction<ChampionResponse | undefined>>,
  ]
}

export default function ChampionMenu({
  openState,
  selectedState,
  champions,
}: Props) {
  const [inputValue, setInputValue] = useState<string>('')
  const [, setSelectedChampion] = selectedState
  const [open, setOpen] = openState
  const filteredOptions = champions.filter((option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase()),
  )

  if (!open) {
    return
  }

  return (
    <div className={'relative w-full z-40'}>
      <div className={'absolute w-full'}>
        <ul
          key={'selector'}
          id={'scrollableDiv'}
          className={`mt-2 overflow-y-auto no-scrollbar bg-fizz-800 border border-fizz-500 rounded-xl ${
            open ? 'max-h-96' : 'max-h-96'
          } `}
        >
          <li className='flex items-center px-4 py-2 sticky top-0 z-10 bg-fizz-800'>
            <div className='relative w-full '>
              <input
                type='text'
                value={inputValue}
                onChange={(e) => {
                  const searchTerm = e.target.value
                  setInputValue(searchTerm)
                }}
                placeholder='Search by name...'
                className='input-basic-dark pr-8 '
              />

              <Image
                src={IconSearch}
                className={
                  'object-cover absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none '
                }
                width={16}
                height={16}
                alt={'Search avatar'}
              />
            </div>
          </li>

          {filteredOptions?.map((champion) => (
            <li
              key={champion.id}
              className={
                'hover:bg-darul-800 mx-4 py-1 text-sm mt-1 rounded-lg  '
              }
              onClick={() => {
                setInputValue('')
                setOpen(false)
                setSelectedChampion(champion)
              }}
            >
              <ChampionsItem
                name={champion.name}
                imageUrl={champion.imageUrl}
              />
            </li>
          ))}

          {filteredOptions.length < 1 && (
            <div
              className={
                'bg-fizz-800 text-center p-5 text-sm   text-white  select-none cursor-not-allowed'
              }
            >
              Couldn't find any champion that match your search.
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}
