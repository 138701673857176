import { clearStoredControl } from '../../hooks/cache/user-control-storage'
import { signOut } from 'next-auth/react'
import { firebaseSignOut } from '../../hooks/firebase/useFirebaseMessaging'
import { clearStoredPromoCodeV2 } from '../../hooks/cache/promo-code-storage-v2'

export async function logout() {
  clearStoredControl()
  clearStoredPromoCodeV2()
  await signOut()
  await firebaseSignOut()
}
