import { useRecoilState } from 'recoil'
import {
  quantityState,
  selectedProductState,
} from '../../common/atoms/checkout'
import { ChangeEvent, useEffect } from 'react'

type Props = {
  max: number
}

export function useQuantity({ max }: Props) {
  const [quantity, setQuantity] = useRecoilState(quantityState)

  const onChangeN = (event: ChangeEvent<HTMLInputElement>) => {
    if (+event.currentTarget.value <= max) {
      setQuantity(+event.currentTarget.value)
    }
  }

  return { quantity, onChangeN, setQuantity }
}
