import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { getPromoCodesByBatchAndCode } from '../../api/stripe/getPromoCode'

export function useGetPromoCodeByBatchAndCode() {
  const { data } = useSession()

  return useMutation(
    async (request: { batch: string; code: string }) =>
      getPromoCodesByBatchAndCode(
        // @ts-ignore
        data?.accessToken,
        request.batch,
        request.code,
      ),
    {
      retry: false,
    },
  )
}
