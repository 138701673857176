import React from 'react'

export type Props = {
  text: string
  boldWord: string
}

export default function TitleStringBuilder({ text, boldWord }: Props) {
  const parts = text.split(new RegExp(`(${boldWord})`, 'g'))

  return (
    <h1 className='text-center font-oswald text-4xl font-medium md:text-4xl text-white uppercase italic'>
      {parts?.map((part, index) =>
        part === boldWord ? (
          <span key={index} className='gradient-textStyle'>
            {part?.toUpperCase()}
          </span>
        ) : (
          part?.toUpperCase()
        ),
      )}
    </h1>
  )
}
