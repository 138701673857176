import { authFetch } from '../../common/networking/auth-fetch'
import { LolAccountResponse } from '../../types/account/lol-account-response'
import { LolAccountCheckoutRequest } from '../../types/account/lol-account-checkout-request'
import { TotalAmountResponse } from '../../types/checkout/responses/total-amount.response'
import { LolAccountChampion } from '../../types/account/lol-account-champion'
import { LolAccountSkin } from '../../types/account/lol-account-skin'

export async function getPurchasedMarketplaceAccounts(
  accessToken: any,
): Promise<LolAccountResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/league-of-legends-accounts`,
  )

  return response.json()
}

export async function postMarketplaceAccountCheckout(
  accessToken: any,
  request: LolAccountCheckoutRequest,
): Promise<LolAccountResponse> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/league-of-legends-accounts/${request.id}/checkout`,
    request,
  )

  return await response?.json()
}

export async function postMarketplaceAccountTotal(
  accessToken: any,
  request: LolAccountCheckoutRequest,
): Promise<TotalAmountResponse> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/league-of-legends-accounts/${request.id}/total`,
    request,
  )

  return await response?.json()
}

export async function getMyMarketplacePurchasedAccount(
  accessToken: any,
): Promise<LolAccountResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/league-of-legends-accounts/me`,
  )

  return response.json()
}

export async function getMarketplaceAccountsChampion(
  id: number,
  accessToken: any,
): Promise<LolAccountChampion[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/league-of-legends-accounts/${id}/champions`,
  )

  return response.json()
}

export async function getMarketplaceAccountsSkins(
  id: number,
  accessToken: any,
): Promise<LolAccountSkin[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/league-of-legends-accounts/${id}/skins`,
  )

  return response.json()
}
