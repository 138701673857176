import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { getCustomerInvites } from '../../api/dashboard/dashboard'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'

export function useGetCustomerInvites() {
  const { data, status } = useSession()

  return useQuery(
    ['teams', 'teammate', 'invites'],
    // @ts-ignore
    async () => getCustomerInvites(data?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
      refetchInterval: 10000, // 20 seconds in milliseconds
    },
  )
}
