import { StripePaymentResponse } from '../../types/stripe/responses/stripe-payment-response'

export function creditCardImage(
  paymentMethod: StripePaymentResponse | undefined,
): string {
  if (paymentMethod?.cashapp) {
    return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/cash-app.svg'
  }
  switch (paymentMethod?.card?.brand) {
    case 'amex':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/amex.svg'
    case 'discover':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/discover.svg'
    case 'jc':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/jcb.svg'
    case 'jcb':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/jcb.svg'
    case 'mastercard':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/mastercard.svg'
    case 'visa':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/visa.svg'
    case 'unionpay':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/unionpay.svg'
    case 'paypal':
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/PayPal.svg'
    default:
      return 'https://d1qrjcmi5hciyv.cloudfront.net/image-assets/visa.svg'
  }
}

export function creditCardColor(type: string | undefined): string {
  switch (type?.toLowerCase()) {
    case 'amex':
      return 'bg-creditCard-amex'
    case 'discover':
      return 'bg-creditCard-discover'
    case 'jcc':
      return 'bg-creditCard-jcb'
    case 'jcb':
      return 'bg-creditCard-jcb'
    case 'mastercard':
      return 'bg-creditCard-mastercard'
    case 'visa':
      return 'bg-creditCard-visa'
    case 'unionpay':
      return 'bg-creditCard-unionpay'
    case 'paypal':
      return 'bg-creditCard-paypal'
    default:
      return 'bg-creditCard-visa'
  }
}
