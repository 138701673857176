import { PromoCodeResponse } from '../../types/stripe/responses/promo-code-response'

const PROMO_CODE_LOCALSTORAGE_KEY_V2 = 'local_promo_code_v2'

export function getStorePromoCodeV2(): PromoCodeResponse | null {
  try {
    if (typeof window === 'undefined') {
      return null
    }
    try {
      const storedPromoCode = localStorage.getItem(
        PROMO_CODE_LOCALSTORAGE_KEY_V2,
      )
      return storedPromoCode ? JSON.parse(storedPromoCode) : null
    } catch (e) {
      return null
    }
  } catch (e) {
    return null
  }
}

export function setStoredPromoCodeV2(promoCode: PromoCodeResponse): void {
  try {
    if (typeof window === 'undefined') {
      return
    }
    try {
      localStorage.setItem(
        PROMO_CODE_LOCALSTORAGE_KEY_V2,
        JSON.stringify(promoCode),
      )
    } catch (e) {}
  } catch (e) {}
}

export function clearStoredPromoCodeV2(): void {
  if (typeof window === 'undefined') {
    return
  }
  try {
    localStorage.removeItem(PROMO_CODE_LOCALSTORAGE_KEY_V2)
  } catch (e) {}
}
