import React from 'react'

interface Props {
  id: number
  checked: boolean
  onChange: (id: number) => void

  option?: string
  price?: number
  hidePrice?: boolean
}

export default function FreeCheckBoxItemV2({
  id,
  checked,
  onChange,
  option,
}: Props) {
  const handleCheckBoxChange = () => {
    onChange(id)
  }

  return (
    <div>
      <input
        className='peer hidden'
        type='checkbox'
        id={id + ''}
        checked={checked}
        onChange={handleCheckBoxChange}
      />

      <label
        className='rounded-lg btn-primary-dark-sm-v2 w-fit select-none font-manrope font-semibold hover:bg-fizz-600 peer-checked:bg-white peer-checked:text-darul-900'
        onClick={(e) => {
          e.preventDefault()
          handleCheckBoxChange()
        }}
      >
        {option}
      </label>
    </div>
  )
}
