import { ProductResponse } from '../../types/products/product-response'
import { sendGTMEvent } from '../../lib/customGTM'
import { sendGAEvent } from '@next/third-parties/google'

export function captureAddedPaymentMethodEvent(
  email?: string,
  userId?: number,
  isNewUser?: boolean,
) {
  if (!isNewUser) return
  try {
    // if (process.env.NODE_ENV !== 'production') return
    try {
      window.fbq('track', 'AddPaymentInfo', {
        email: email,
        external_id: userId?.toString(),
        externalId: userId?.toString(),
      })
    } catch (e) {}

    try {
      window.ttq.track('AddPaymentInfo', {
        email: email,
        external_id: userId?.toString(),
        externalId: userId?.toString(),
      })
    } catch (e) {}

    try {
      sendGAEvent('event', 'add_payment_info', {
        email: email,
        external_id: userId?.toString(),
        externalId: userId?.toString(),
      })
    } catch (e) {}
  } catch (e) {}
}

export function captureSignupEvent() {
  try {
    // if (process.env.NODE_ENV !== 'production') return
    try {
      window.fbq('track', 'CompleteRegistration', {})
    } catch (e) {}

    try {
      window.ttq.track('CompleteRegistration', {})
    } catch (e) {}

    try {
      sendGAEvent('event', 'sign_up', {})
    } catch (e) {}
  } catch (e) {}
}

export function captureInitiateCheckout(
  email?: string,
  userId?: number,
  product?: ProductResponse | null,
  quantity?: number,
  isNewUser?: boolean,
) {
  if (!isNewUser) return

  try {
    try {
      window.fbq('track', 'InitiateCheckout', {
        new_customer: isNewUser,
        content_ids: [product?.id],
        content_type: 'product',
        value: product?.currencyUnitAmount,
        currency: 'USD',
        external_id: userId?.toString(),
        externalId: userId?.toString(),
        email: email,
        quantity: quantity,
      })
    } catch (e) {}

    try {
      window.ttq.track('InitiateCheckout', {
        new_customer: isNewUser,
        content_ids: [product?.id],
        content_type: 'product',
        value: product?.currencyUnitAmount,
        currency: 'USD',
        description: product?.about,
        external_id: userId?.toString(),
        externalId: userId?.toString(),
        email: email,
        quantity: quantity,
      })
    } catch (e) {}

    try {
      sendGAEvent('event', 'begin_checkout', {
        currency: 'USD',
        value: product?.currencyUnitAmount,
        external_id: userId?.toString(),
        externalId: userId?.toString(),
        email: email,
        quantity: quantity,
        new_customer: isNewUser,
        items: [
          {
            item_id: product?.id,
            item_name: product?.title,
            price: product?.currencyUnitAmount,
            quantity: quantity,
          },
        ],
      })
    } catch (e) {}
  } catch (e) {}
}

export function captureAddToCart(
  email?: string,
  userId?: number,
  product?: ProductResponse | null,
  quantity?: number,
  isNewUser?: boolean,
) {
  if (!isNewUser) return

  // if (process.env.NODE_ENV !== 'production') return

  try {
    window.fbq('track', 'AddToCart', {
      new_customer: isNewUser,
      content_ids: [product?.id],
      content_type: 'product',
      value: product?.currencyUnitAmount,
      currency: 'USD',
      external_id: userId?.toString(),
      externalId: userId?.toString(),
      email: email,
      quantity: quantity,
    })
  } catch (e) {}

  try {
    window.ttq.track('AddToCart', {
      new_customer: isNewUser,
      content_ids: [product?.id],
      content_type: 'product',
      value: product?.currencyUnitAmount,
      currency: 'USD',
      description: product?.about,
      external_id: userId?.toString(),
      externalId: userId?.toString(),
      email: email,
      quantity: quantity,
    })
  } catch (e) {}

  try {
    sendGAEvent('event', 'add_to_cart', {
      currency: 'USD',
      value: product?.currencyUnitAmount,
      external_id: userId?.toString(),
      externalId: userId?.toString(),
      email: email,
      quantity: quantity,
      new_customer: isNewUser,
      items: [
        {
          item_id: product?.id,
          item_name: product?.title,
          price: product?.currencyUnitAmount,
          quantity: quantity,
        },
      ],
    })
  } catch (e) {}
}

export function captureViewProductFull(
  product: ProductResponse,
  email?: string,
  userId?: number,
) {
  try {
    if (process.env.NODE_ENV !== 'production') return

    sendGTMEvent({
      event: 'ViewContent',
      content_id: product.id,
      description: product.about,
      content_type: 'product',
      email: email ?? '',
      externalId: userId?.toString() ?? '',
    })
  } catch (e) {}
}
