import { useSession } from 'next-auth/react'
import { useInGameAccounts } from '../accounts/useInGameAccounts'
import { useRecoilState } from 'recoil'
import {
  defaultCurrencyState,
  selectedGameState,
} from '../../common/atoms/atoms'
import { useQuery } from '@tanstack/react-query'
import { getLocalizedPrices } from '../../api/checkout/checkout'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { LocalizedPrice } from '../../types/prices/LocalizedPrice'
import { useUser } from '../users/useUser'
import { isNil } from '../../common/utils/common.utils'
import { ign, server } from '../../common/utils/profile.utils'

export function useGetProductLocalizedPrices() {
  const { data, status } = useSession()
  const { profiles } = useInGameAccounts()
  const [game] = useRecoilState(selectedGameState)
  const [currency] = useRecoilState(defaultCurrencyState)
  const { user } = useUser()

  return useQuery<LocalizedPrice[]>(
    [
      'localized-prices',
      game.key,
      status,
      server(profiles),
      ign(profiles),
      currency?.id ?? 1,
      user?.defaultCurrencyId,
    ],
    async () => {
      return getLocalizedPrices(
        //@ts-ignore
        data?.accessToken,
        game.key,
        currency?.id,
      )
    },
    {
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: true,
      enabled:
        !isNil(currency?.id) &&
        (status === SessionStatusEnum.UNAUTHENTICATED ||
          status === SessionStatusEnum.AUTHENTICATED),
    },
  )
}
