import React from 'react'

type Props = { search: string; setSearch: (value: string) => void }

export default function SearchInput({ search, setSearch }: Props) {
  return (
    <div className='relative rounded-md shadow-sm self-end w-full'>
      <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            d='M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z'
            stroke='#5E7198'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M17.5 17.5L13.875 13.875'
            stroke='#5E7198'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <input
        type='text'
        className='input-basic-transparent pl-10  text-start px-3 h-[53px]  '
        value={search}
        placeholder={'Search...'}
        onInput={(e) => setSearch(e.currentTarget.value)}
      />
    </div>
  )
}
