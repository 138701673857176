import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { getPromoCodesCampaigns } from '../../api/stripe/getPromoCode'
import { CampaginsResponse } from '../../types/stripe/responses/campagins-response'

export function useGetPromoCodesCampaigns() {
  const { data, status } = useSession()

  return useQuery<CampaginsResponse[] | null>(
    ['getCampagins'],
    // @ts-ignore
    async () => getPromoCodesCampaigns(data?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}
