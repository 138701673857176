import React from 'react'
import Image from 'next/image'
import CheckIcon from '../../../public/static/check-dark-green-rounded.svg'
import remarkBreaks from 'remark-breaks'
import ReactMarkdown from 'react-markdown'

type Props = {
  title: string
  // description: string
  // image?: StaticImageData
}

export function BenefitPart({ title }: Props) {
  return (
    <div className={'article flex w-full flex-row items-center gap-3'}>
      <Image
        src={CheckIcon}
        className={'object-cover'}
        alt={'Check icon'}
        width={20}
        height={20}
      />
      <ReactMarkdown
        remarkPlugins={[remarkBreaks]}
        className={'markdown text-body-light text-white'}
        linkTarget={'_blank'}
      >
        {title}
      </ReactMarkdown>
    </div>
  )
}
