import { authFetch } from '../../common/networking/auth-fetch'

export async function postSendTKSMessage(
  accessToken: any,
  request: { tippingId?: number; requestId?: number; message: string },
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/gifts/messages`,
    request,
  )
}

export async function getGiftingMessages(
  accessToken: any,
): Promise<{ message: string; teammateId: number }[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/gifts/messages`,
  )

  return response.json()
}

export async function readGiftingMessages(accessToken: any): Promise<Response> {
  return await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/gifts/messages/read`,
  )
}
