export enum GameCategories {
  leagueOfLegends = 'league-of-legends',
  warzone = 'call-of-duty-warzone',
  valorant = 'valorant',
  tft = 'teamfight-tactics',
  diablo4 = 'diablo-4',
  csgo = 'counter-strike-global-offensive',
  fortnite = 'fortnite',
  fifa = 'fifa',
  marvelRivals = 'marvel-rivals',
  egirls = 'egirls',
}
