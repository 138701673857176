import React, { useEffect } from 'react'
import FriendListEntry from './FriendListEntry'
import FriendListCloseButton from './FriendListCloseButton'
import CollapseParent from './CollapseParent'
import { useGetTeamFriendList } from '../../../../hooks/teams/useGetTeamFriendList'
import { atom, useRecoilState } from 'recoil'
import { TeamResponse } from '../../../../types/teams/responses/team-response'

type Props = {
  team?: TeamResponse
  defaultOpen?: boolean
}

export const isOpenFriendListSection = atom<boolean>({
  key: 'isOpenFriendListSectionState',
  default: false,
})

export const isBlinkingState = atom<boolean>({
  key: 'isBlinkingState',
  default: false,
})

export default function FriendListSection({ team, defaultOpen = true }: Props) {
  const [isOpen, setIsOpen] = useRecoilState(isOpenFriendListSection)
  const { data: friendList } = useGetTeamFriendList(team?.id)
  const [isBlinking, setIsBlinking] = useRecoilState(isBlinkingState)

  useEffect(() => {
    if (defaultOpen) {
      setTimeout(() => {
        setIsOpen(defaultOpen)
      }, 500)
    } else {
      setIsOpen(defaultOpen)
    }
  }, [])

  useEffect(() => {
    if (isBlinking) {
      const timer = setTimeout(() => {
        setIsBlinking(false)
      }, 700)

      return () => clearTimeout(timer)
    }
  }, [isBlinking])

  const total =
    (friendList?.favorites?.length || 0) +
    (friendList?.non_favorites?.length || 0)

  return (
    <div className={'z-50'}>
      <FriendListEntry
        onClick={() => {
          setIsOpen(true)
        }}
        num_of_friends={total}
      />

      <div
        className={`fixed top-0 right-0 h-screen w-full md:w-fit transition-transform ease-in-out bg-fizz-800 ${isBlinking ? 'blinking' : ''} z-50 shadow-lg ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition duration-300`}
      >
        <div className='px-4 py-4 h-[67px]'>
          <div className='flex justify-between items-start w-full md:w-[400px]'>
            <p className='text-xl font-medium text-bung-400 font-oswald'>
              FRIENDLIST • {total}
            </p>
            <div className='h-7 items-center'>
              <FriendListCloseButton
                onClick={() => {
                  setIsOpen(false)
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ height: 'calc(100% - 67px)' }}>
          <CollapseParent
            teamFriendlist={friendList}
            team={team}
            smallScreen={!defaultOpen}
          />
        </div>
      </div>
    </div>
  )
}
