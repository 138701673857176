import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { postCreateEvent } from '../../api/events/events.api'
import { CreateEventRequest } from '../../types/events/requests/create-event-request'
import { fetchFingerprint } from '../../lib/fingerprint'

export function usePostCreateEvent() {
  const { data } = useSession()

  return useMutation(async (body: CreateEventRequest) => {
    let visitorId
    let fingerprintRequestId

    try {
      const fingerprint = await fetchFingerprint()
      visitorId = fingerprint?.visitorId
      fingerprintRequestId = fingerprint?.requestId
    } catch (e) {}

    //@ts-ignore
    return postCreateEvent(data?.accessToken, {
      ...body,
      visitorId: visitorId,
      fingerprintRequestId: fingerprintRequestId,
    })
  })
}
