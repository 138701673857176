import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { getTeamFriendList } from '../../api/teams/teams'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { TeamFriendlistResponse } from '../../types/teams/responses/team-friendlist-response'

export function useGetTeamFriendList(teamId?: string) {
  const { data, status } = useSession()

  return useQuery<TeamFriendlistResponse>(
    ['team', 'friendlist', teamId],
    // @ts-ignore
    async () => getTeamFriendList(data?.accessToken, teamId),
    {
      enabled:
        status === SessionStatusEnum.AUTHENTICATED &&
        teamId !== null &&
        teamId !== undefined &&
        teamId !== '',
      cacheTime: 60 * 1000,
      refetchInterval: 30 * 1000,
      refetchIntervalInBackground: true,
    },
  )
}
