import { useMutation } from '@tanstack/react-query'
import { postCheckout } from '../../api/checkout/checkout'
import { useSession } from 'next-auth/react'
import { CheckoutRequest } from '../../types/checkout/requests/checkout-request'

export function usePostCheckout() {
  const { data } = useSession()

  return useMutation(async (request: CheckoutRequest) =>
    //@ts-ignore
    postCheckout(data?.accessToken, request),
  )
}
