import React, { useEffect } from 'react'
import { useCreateTotalAmount } from '../../hooks/checkout/useCreateTotalAmount'
import { useUser } from '../../hooks/users/useUser'
import { useRecoilState } from 'recoil'
import {
  isOpenSignUpModalState,
  isPlacingOrderState,
  quantityState,
  selectedProductState,
} from '../../common/atoms/checkout'
import { useCheckout } from '../../hooks/checkout/useCheckout'
import PaymentButton from './PaymentButton'
import { PaymentGatewayEnum } from '../../common/enums/payment-gateway.enum'
import { PaymentCustomerTypeEnum } from '../../common/enums/payment-customer-type.enum'
import { selectedPaymentState } from '../../common/atoms/atoms'
import {
  captureAddToCart,
  captureInitiateCheckout,
} from '../../common/utils/analytics.utils'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useSession } from 'next-auth/react'
import { AddonResponse } from '../../types/addons/responses/AddonResponse'

type Props = {
  isRematch: boolean
  title?: string
  theme?: string
  checkoutSuccessAction?: () => void
  showAnimatedEffect?: boolean
  addons?: AddonResponse[]
  hexagonalButton?: boolean
  waitingForInvite?: boolean
}

export default function OrderButton({
  isRematch,
  title,
  theme = 'light',
  checkoutSuccessAction,
  showAnimatedEffect = false,
  addons = undefined,
  hexagonalButton,
  waitingForInvite,
}: Props) {
  const {
    data: total,
    // refetch,
    isLoading: isGetTotalAmountLoading,
  } = useCreateTotalAmount(undefined, isRematch)
  // const [loaded, setLoaded] = useState(false)
  const { user } = useUser()
  const { submit, isLoading, isSuccess, isError } = useCheckout()
  const [isPlacingOrder, setIsPlacingOrder] =
    useRecoilState(isPlacingOrderState)
  const [selectedPayment] = useRecoilState(selectedPaymentState)
  const { status } = useSession()
  const [, setIsOpenSignUp] = useRecoilState(isOpenSignUpModalState)
  const [selectedProduct] = useRecoilState(selectedProductState)
  const [quantity] = useRecoilState(quantityState)

  useEffect(() => {
    if (isLoading) setIsPlacingOrder(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (isSuccess || isError) setIsPlacingOrder(false)

    if (isSuccess) {
      checkoutSuccessAction && checkoutSuccessAction()
    }
  }, [isSuccess, isError])

  // useEffect(() => {
  //   // if (user?.creditAccount?.creditUnitAmount) {
  //   //   if (loaded) refetch()
  //   // }
  //
  //   if (user) setLoaded(true)
  // }, [user])

  return (
    <div className={'w-full'}>
      <PaymentButton
        onSubmit={() => {
          if (status === SessionStatusEnum.UNAUTHENTICATED) {
            setIsOpenSignUp(true)
            // setInGameAccountFrom('modal')
            // setInGameAccountModalOpen(true)
            return
          }

          setIsPlacingOrder(true)
          captureInitiateCheckout(
            user?.email,
            user?.id,
            selectedProduct,
            quantity,
            user?.isNewUser,
          )
          captureAddToCart(
            user?.email,
            user?.id,
            selectedProduct,
            quantity,
            user?.isNewUser,
          )
          // postFingerprint()
          submit(
            selectedPayment?.type === PaymentCustomerTypeEnum.BRAINTREE
              ? PaymentGatewayEnum.BRAINTREE
              : PaymentGatewayEnum.STRIPE_PAYMENT_INTENT,
            isRematch,
          )
        }}
        isLoading={isPlacingOrder || isGetTotalAmountLoading}
        title={title}
        theme={theme}
        total={total?.localizedTotal}
        showAnimatedEffect={showAnimatedEffect}
        addons={addons}
        hexagonalButton={hexagonalButton}
        waitingForInvite={waitingForInvite}
      />
    </div>
  )
}
