import { authFetch } from '../../common/networking/auth-fetch'
import { CheckoutRequest } from '../../types/checkout/requests/checkout-request'
import { CheckoutPayloadResponse } from '../../types/checkout/responses/checkout-payload-response'
import { CheckoutGiftingRequest } from '../../types/checkout/requests/checkout-gifting-request'
import { CreateTotalAmountRequest } from '../../types/checkout/requests/create-total-amount.request'
import { TotalAmountResponse } from '../../types/checkout/responses/total-amount.response'
import { ReloadBalanceRequest } from '../../types/checkout/requests/reload-balance.request'
import { PaypalOrderResponse } from '../../types/paypal/paypal-order.response'
import { LocalizedPrice } from '../../types/prices/LocalizedPrice'
import { GiftsResponse } from '../../types/checkout/responses/gifts-response'

export async function postCheckout(
  accessToken: any,
  request: CheckoutRequest,
): Promise<CheckoutPayloadResponse> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/checkout/transactions`,
    request,
  )

  return await response?.json()
}

export async function postReloadBalances(
  accessToken: any,
  request: ReloadBalanceRequest,
): Promise<CheckoutPayloadResponse> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/checkout/balances`,
    request,
  )
  return await response?.json()
}

export async function postGifting(
  accessToken: any,
  request: CheckoutGiftingRequest,
): Promise<Response | void> {
  return authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/checkout/gifting`,
    request,
  )
}

export async function getGifting(accessToken: any): Promise<GiftsResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/gifts`,
  )

  return response.json()
}

export async function getCreateTotalAmount(
  accessToken: any,
  request: CreateTotalAmountRequest,
): Promise<TotalAmountResponse> {
  let url = new URL(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/checkout/total-amount`,
  )
  const response = await authFetch(accessToken).post(url.toString(), {
    productId: request.productId,
    quantity: request.quantity,
    promoCode: request.promoCode,
    ign: request.ign,
    server: request.server,
    addonIds: request.addonIds,
    streamer: request.streamer,
    currencyId: request.currencyId,
    skinId: request.skinId,
    progress: request.progress,
    teamId: request.teamId,
  })
  return await response?.json()
}

export async function getLocalizedPrices(
  accessToken: any,
  gamekey: string,
  currencyId?: number,
): Promise<LocalizedPrice[]> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/products/localized-prices`,
    {
      gameKey: gamekey,
      currencyId: currencyId,
    },
  )
  return await response?.json()
}

export async function postCreatePaypalOrder(
  accessToken: any,
  request: CheckoutRequest,
): Promise<PaypalOrderResponse> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/checkout/paypal/transactions`,
    request,
  )
  return await response.json()
}

export async function getKlarnaCheckoutLink(
  accessToken: any,
  productId: number,
): Promise<any> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/checkout/balances/klarna`,
    {
      productId,
    },
  )
  return await response?.json()
}
