import React from 'react'
import Footer from './navigation/Footer'
import 'react-toastify/dist/ReactToastify.css'
import LayoutModals from './layout/LayoutModals'
import { IntercomProvider } from 'react-use-intercom'
import HeaderV2 from './navigation/HeaderV2'

type LayoutProps = {
  children: React.ReactNode
  addFooter?: boolean
}

export default function Layout({ children, addFooter = true }: LayoutProps) {
  return (
    <IntercomProvider appId={'u9r1twrv'} autoBoot={true} initializeDelay={3000}>
      <div className={'bg flex min-h-screen flex-col'}>
        <HeaderV2 />

        <main className={'grow'}>{children}</main>

        {addFooter && <Footer />}

        <LayoutModals />
      </div>
    </IntercomProvider>
  )
}
