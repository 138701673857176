export enum ProductModeEnum {
  COMPANION = 'companion',
  FREEMIUM = 'freemium',
  COMPETITIVE = 'competitive',
  COACHING = 'coaching',
  STREAMER = 'streamer',
  MARKETPLACE = 'marketplace',
  Tournament = 'tournament',
  QUEST = 'quest',
  RANKUP = 'rankup',
}
