import { authFetch } from '../../common/networking/auth-fetch'
import { SsoRequest } from '../../types/sso/sso-request'
import { DiscordInviteLink } from '../../types/discord/DiscordInviteLink'

export async function postConnectSSOProvider(
  accessToken: any,
  request: SsoRequest,
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/sso/connections`,
    request,
  )
}

export async function postConnectInSessionDiscord(
  accessToken: any,
  request: SsoRequest,
): Promise<DiscordInviteLink> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/sso/in-session/discord`,
    request,
  )

  return await response.json()
}
