import { authFetch } from '../../common/networking/auth-fetch'
import { CategorySpecializedTag } from '../../types/users/requests/category-specialized-tag-response'
import { CategoryProfileResponse } from '../../types/users/category-profile-response'
import { GeneralCategoryRequest } from '../../types/users/requests/general-category-request'
import { ChampionResponse } from '../../types/users/responses/champion-response'

export async function getCategoryTag(
  key: string,
): Promise<CategorySpecializedTag[]> {
  const response = await authFetch().get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/category-tags?categoryKey=${key}`,
  )
  return await response.json()
}

export async function getMeCategoryProfileByTag(
  accessToken: any,
  key: string,
): Promise<CategoryProfileResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/categories/${key}/profiles`,
  )
  return ((await response.json()) as CategoryProfileResponse[]).filter(
    (item) => {
      return item.username !== null
    },
  )
}

export async function getMeInGameRank(
  accessToken: any,
  key: string,
): Promise<{
  solo?: { tier?: string; division?: number; lp?: number }
  flex?: { tier?: string; division?: number; lp?: number }
}> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/categories/${key}/in-game-rank`,
  )
  return await response.json()
}

export async function postMeCategoryProfileByTag(
  accessToken: any,
  key: string,
  request: GeneralCategoryRequest,
): Promise<CategoryProfileResponse[]> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/categories/${key}/profiles`,
    request,
  )
  return await response.json()
}

export async function putMeCategoryProfileById(
  accessToken: any,
  id: number,
  request: GeneralCategoryRequest,
): Promise<Response> {
  return await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/categories/profiles/${id}`,
    request,
  )
}

export async function deleteMeCategoryProfile(
  accessToken: any,
  id: number,
): Promise<Response> {
  return await authFetch(accessToken).delete(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/categories/profiles/${id}`,
  )
}

export async function getCategoryChampions(
  accessToken: any,
  key: string,
): Promise<ChampionResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/categories/${key}/champions`,
  )
  return await response.json()
}
