import { useQuery } from '@tanstack/react-query'
import { getPayments } from '../../api/stripe/payments'
import { PaymentResponse } from '../../types/stripe/responses/stripe-payment-response'
import { useSession } from 'next-auth/react'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'

export function useGetPayments() {
  const { data, status } = useSession()

  return useQuery<PaymentResponse[] | null>(
    ['getPayments'],
    // @ts-ignore
    async () => getPayments(data?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}
