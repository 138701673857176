import React from 'react'
import { ProductResponse } from '../../../types/products/product-response'
import { TitlePart } from './TitlePart'
import { FeaturePart } from './FeaturePart'
import Feature1Icon from '../../../public/static/product-detail/feature-1.png'
import Feature2Icon from '../../../public/static/product-detail/feature-2.png'
import Feature3Icon from '../../../public/static/product-detail/feature-3.png'
import Feature4Icon from '../../../public/static/product-detail/feature-4.png'
import Feature5Icon from '../../../public/static/product-detail/feature-5.png'
import Feature6Icon from '../../../public/static/product-detail/feature-6.png'

type Props = { product: ProductResponse }

export function FeatureSection({ product }: Props) {
  return (
    <div>
      <TitlePart title={'WHY US'} />

      <div
        className={
          'mt-5 grid w-full grid-cols-2 gap-x-4 gap-y-7 sm:grid-cols-3'
        }
      >
        <FeaturePart
          title={'The best PRO teammates'}
          description={
            'Top 0.1% teammates with an average rating of 4.9 stars.'
          }
          image={Feature1Icon}
        />

        <FeaturePart
          title={'No wait, start now'}
          description={'No waiting, average wait time under 2 minutes to play.'}
          image={Feature6Icon}
        />

        <FeaturePart
          title={'100% safe and secure'}
          description={
            'Play with a teammate without any cheats, or hacks involved.'
          }
          image={Feature2Icon}
        />

        <FeaturePart
          title={'Money back guarantee'}
          description={'Risk-free satisfaction: Money-back guarantee assured.'}
          image={Feature3Icon}
        />

        <FeaturePart
          title={'Community first'}
          description={'Inclusive and supportive community of gamers.'}
          image={Feature4Icon}
        />

        <FeaturePart
          title={'24/7 support services'}
          description={'We are always ready to help.'}
          image={Feature5Icon}
        />
      </div>
    </div>
  )
}
