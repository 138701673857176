import { useEffect, useState } from 'react'
import { useGetAccountChampion } from './useGetAccountChampion'
import { useDebounce } from 'use-debounce'
import { useRecoilState } from 'recoil'
import { selectedAccountProductState } from '../../common/atoms/checkout'
import { LolAccountChampion } from '../../types/account/lol-account-champion'
import { useGetAccountSkins } from './useGetAccountSkins'
import { LolAccountSkin } from '../../types/account/lol-account-skin'

export type AccountMarket = ReturnType<typeof useAccountMarket>

export function useAccountMarket() {
  const [selectedAccountProduct] = useRecoilState(selectedAccountProductState)
  const { data: championData, isLoading: isLoadingChampion } =
    useGetAccountChampion(selectedAccountProduct?.id ?? '')
  const { data: skinData, isLoading: isLoadingSkins } = useGetAccountSkins(
    selectedAccountProduct?.id ?? '',
  )
  const [champions, setChampions] = useState<LolAccountChampion[]>([])
  const [search, setSearch] = useState<string>('')
  const [debounceSearch, setDebounceSearch] = useDebounce(search, 1000)
  const [skins, setSkins] = useState<LolAccountSkin[]>([])
  useEffect(() => {
    if (!championData) return

    const filteredChampions = championData.filter((champion) =>
      champion.champion.name
        .toLowerCase()
        .includes(debounceSearch.toLowerCase()),
    )

    setChampions(filteredChampions)
  }, [championData, debounceSearch])

  useEffect(() => {
    if (!skinData) return

    const filteredSkins = skinData.filter(
      (skin) =>
        skin.skin.name.toLowerCase().includes(debounceSearch.toLowerCase()) ||
        skin.skin.champion.name
          .toLowerCase()
          .includes(debounceSearch.toLowerCase()),
    )

    setSkins(filteredSkins)
  }, [skinData, debounceSearch])

  return {
    isLoadingChampion,
    isLoadingSkins,
    champions,
    skins,
    search,
    setSearch,
  }
}
