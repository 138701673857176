import { authFetch } from '../../common/networking/auth-fetch'
import { CreateUserRequest } from '../../types/auth/requests/create-user-request'
import { TokensResponse } from '../../types/auth/tokens-response'
import { ResetPasswordRequest } from '../../types/auth/requests/reset-password-request'
import { ForgotPasswordRequest } from '../../types/auth/requests/forgot-password-request'
import { ChangePasswordRequest } from '../../types/auth/requests/change-password-request'
import { LoginRequest } from '../../types/auth/requests/login-request'

export async function postRegisterUser(
  accessToken: any,
  request: CreateUserRequest,
): Promise<TokensResponse> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/register`,
    request,
  )

  return await response.json()
}

export async function postRegisterGuestToUser(
  accessToken: any,
  request: LoginRequest,
): Promise<TokensResponse> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/accounts/guest`,
    request,
  )

  return await response.json()
}

export async function putResetPassword(
  accessToken: any,
  request: ResetPasswordRequest,
): Promise<Response> {
  return authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/reset-password`,
    request,
  )
}

export async function postForgotPassword(
  accessToken: any,
  request: ForgotPasswordRequest,
): Promise<Response> {
  return authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/forgot-password`,
    request,
  )
}

export async function putChangePassword(
  accessToken: any,
  request: ChangePasswordRequest,
  refreshToken: any,
): Promise<Response> {
  request.refreshToken = refreshToken
  const response = await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/password`,
    request,
  )

  return await response.json()
}

export async function postLogout(accessToken: any): Promise<Response> {
  return authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/auth/logout`,
  )
}
