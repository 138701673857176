import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { getSupplierStatus } from '../../api/dashboard/dashboard'
import { teammateStatus } from '../../common/react-query/react-query-keys'
import { SupplierStatus } from '../../types/dashboard/supplier-status.response'

export function useGetTeammateStatus(isEnable: boolean = true) {
  const { data, status } = useSession()

  return useQuery<SupplierStatus>(
    [teammateStatus, !isEnable ? 0 : 1],
    //@ts-ignore
    async () => getSupplierStatus(data?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
      //&& (isEnable || isToggleOnline),
    },
  )
}
