import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { getRequests } from '../../api/fans/fans'
import { RequestPaginationResponse } from '../../types/requests/responses/request-pagination-response'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import {
  activeHomeRequests,
  activeRequests,
} from '../../common/react-query/react-query-keys'

export function useGetRequests() {
  const { data, status } = useSession()

  return useQuery<RequestPaginationResponse>(
    [activeRequests],
    //@ts-ignore
    async () => getRequests(data?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}

export function useGetHomeRequests() {
  const { data, status } = useSession()

  return useQuery<RequestPaginationResponse>(
    [activeHomeRequests],
    //@ts-ignore
    async () => getRequests(data?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}
