import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { blockPromoCode } from '../../api/promos/promo-codes'

export function useBlockPromoCode() {
  const { data } = useSession()
  return useMutation(async (promoCodeId: number) =>
    // @ts-ignore
    blockPromoCode(data?.accessToken, promoCodeId),
  )
}
