import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { getPromoCodesByBatch } from '../../api/stripe/getPromoCode'

export function useGetPromoCodeByBatch() {
  const { data } = useSession()

  return useMutation(
    async (batch: string) =>
      // @ts-ignore
      getPromoCodesByBatch(data?.accessToken, batch),
    {
      retry: false,
    },
  )
}
