import { authFetch } from '../../common/networking/auth-fetch'
import { UpdateCommunicationRequest } from '../../types/users/requests/update-communication-request'
import { RequestResponse } from '../../types/requests/responses/request-response'
import {
  TeammatesPaginationResponse,
  TeammatesWithOnlineUserCountResponse,
} from '../../types/creator/response/teammates-pagination-response'
import { UserResponse } from '../../types/users/user-response'
import { isNil } from '../../common/utils/common.utils'

export async function putUpdateDiscordUsername(
  accessToken: any,
  request: UpdateCommunicationRequest,
): Promise<Response> {
  return await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/communication`,
    request,
  )
}

export async function getActiveRequest(
  accessToken: any,
): Promise<RequestResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/requests/active`,
  )
  return await response.json()
}

export async function getFavoriteCreators(
  accessToken: any,
  pageParam: number,
): Promise<TeammatesPaginationResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/favorites?page=${pageParam}`,
  )
  return await response.json()
}

export async function getBlockedCreators(
  accessToken: any,
  pageParam: number,
): Promise<TeammatesPaginationResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/blocks?page=${pageParam}`,
  )
  return await response.json()
}

export async function getTeammatesV2(
  accessToken: any,
  categoryKey: string,
  mode?: string,
  gender?: string,
): Promise<TeammatesWithOnlineUserCountResponse> {
  let url = new URL(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/teammates?categoryKey=${categoryKey}`,
  )

  if (!isNil(mode)) {
    url.searchParams.append('mode', mode?.toString() ?? '')
  }

  if (!isNil(gender)) {
    url.searchParams.append('gender', gender?.toString() ?? '')
  }

  const response = await authFetch(accessToken).get(url.toString())
  return await response.json()
}

export async function putCreatorLike(
  accessToken: any,
  request: {
    creatorId: number
    like: boolean
  },
): Promise<Response> {
  return await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/${request.creatorId}/favorites`,
    request,
  )
}

export async function getCreatorById(
  accessToken: any,
  request: {
    creatorId: number
    favorite?: boolean
    blocked?: boolean
    cache?: boolean
    requestId?: number
  },
): Promise<UserResponse> {
  let url = new URL(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/${request.creatorId}`,
  )

  if (!isNil(request.favorite)) {
    url.searchParams.append(
      'favorite',
      request?.favorite?.toString() ?? 'false',
    )
  }

  if (!isNil(request.blocked)) {
    url.searchParams.append('blocked', request?.blocked?.toString() ?? 'false')
  }

  if (!isNil(request.cache)) {
    url.searchParams.append('cache', request.cache?.toString() ?? 'true')
  }

  if (!isNil(request.requestId)) {
    url.searchParams.append('requestId', request.requestId?.toString() ?? '-1')
  }

  const response = await authFetch(accessToken).get(url.toString())
  return await response.json()
}

export async function putBlockTeammate(
  accessToken: any,
  request: {
    creatorId: number
    block: boolean
  },
): Promise<Response> {
  return await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/creators/${request.creatorId}/blocks`,
    request,
  )
}
