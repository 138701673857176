import { authFetch } from '../../common/networking/auth-fetch'
import { PaymentResponse } from '../../types/stripe/responses/stripe-payment-response'

export async function getPayments(
  accessToken: any,
): Promise<PaymentResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/payments/customers/payments`,
  )
  return await response.json()
}
