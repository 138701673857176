import React from 'react'
import Image from 'next/image'
import TrustpilotIcon from '../../public/static/trust-pilot-micro.svg'
import TitleStringBuilder from '../common/text/TitleStringBuilder'
import SubtitleStringBuilder from '../common/text/SubtitleStringBuilder'

type Props = {
  title?: string
  description?: string
}

export default function HomePageHeader({ title, description }: Props) {
  return (
    <div className={'flex mt-4 md:mt-4 flex-col items-center'}>
      <div className={'z-20 mx-4 mt-0 md:mt-0'}>
        <TitleStringBuilder
          text={title ?? 'Find no-stress teammates, anytime.'}
          boldWord={'anytime.'}
        />
      </div>

      <div className={'z-20 mx-4 mb-0 mt-1 md:mb-1'}>
        <SubtitleStringBuilder
          text={description ?? 'Average wait time under 2 minutes to play.'}
          boldWord={'2 minutes'}
          color={'text-kassadin-200'}
        />
      </div>

      <a
        href='https://www.trustpilot.com/review/tapin.gg'
        target='_blank'
        className={'hidden md:flex'}
        rel='noopener noreferrer'
      >
        <Image
          src={TrustpilotIcon}
          width={231}
          height={35}
          className={'object-contain'}
          alt={'Trust pilot icon'}
        />
      </a>

      {/*<div*/}
      {/*  className={'flex cursor-pointer flex-row items-center gap-1.5'}*/}
      {/*  onClick={(e) => {*/}
      {/*    e.preventDefault()*/}
      {/*    router.push(howItWorksUrl)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <h3 className={'text-body-medium text-bult-300'}>How it works</h3>*/}

      {/*  <div className={'relative h-4 w-4 shrink-0'}>*/}
      {/*    <Image*/}
      {/*      className={'object-contain'}*/}
      {/*      fill={true}*/}
      {/*      src={ArrowIcon}*/}
      {/*      alt={'Arrow icon'}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}
