import { useQuery } from '@tanstack/react-query'
import { ProductResponse } from '../../types/products/product-response'
import { getProductStreamer } from '../../api/products/products'

export const useGetProductSteamer = (gamekey: string) => {
  return useQuery<ProductResponse[]>(
    ['Steamer', gamekey],
    //@ts-ignore
    async () => getProductStreamer(gamekey),
    {
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: true,
    },
  )
}
