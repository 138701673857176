import { authFetch } from '../../common/networking/auth-fetch'

export function postCreatePushNotificationToken(
  accessToken: any,
  token: string,
): Promise<Response> {
  return authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/firebase/push-notification-tokens`,
    {
      token: token,
    },
  )
}
