import { authFetch } from '../../common/networking/auth-fetch'
import { CreateEventRequest } from '../../types/events/requests/create-event-request'

export async function postCreateEvent(
  accessToken: any,
  body: CreateEventRequest,
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/events`,
    body,
  )
}
