import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { CreateTeamRequest } from '../../types/teams/requests/create-team-request'
import { postCreateTeam } from '../../api/teams/teams'

export function usePostCreateTeam() {
  const { data } = useSession()

  return useMutation(async (request: CreateTeamRequest) =>
    // @ts-ignore
    postCreateTeam(data?.accessToken, request),
  )
}
