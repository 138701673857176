import React, { useEffect } from 'react'

import { toast } from 'react-toastify'
import CommentToast from '../../components/common/toast/CommentToast'
import { useRecoilState } from 'recoil'
import { tipsFeedbackFromTeammateState } from '../../common/atoms/atoms'

export default function useTippingMessageToast() {
  const [tippingMessage, setTippingMessage] = useRecoilState(
    tipsFeedbackFromTeammateState,
  )
  useEffect(() => {
    if (tippingMessage) {
      toast(
        (content) => {
          return (
            <CommentToast
              key={tippingMessage.id + ' : notification'}
              id={tippingMessage.id}
              closeToast={content.closeToast}
              toastProps={content.toastProps}
              comment={tippingMessage.message ?? ''}
            />
          )
        },
        {
          style: { padding: '0', zIndex: '999' },
          type: 'default',
          draggable: false,
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
          autoClose: false,
          position: 'top-left',
          bodyClassName: 'custom-toast',
        },
      )
      setTippingMessage(null)
    }
  }, [tippingMessage])

  return null
}
