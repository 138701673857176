import { useGetMeCategoryProfileByTag } from '../users/useGetMeCategoryProfileByTag'
import { useRecoilState } from 'recoil'
import { selectedGameState } from '../../common/atoms/atoms'

export function useInGameAccounts(excludeTeammate = false) {
  const [game] = useRecoilState(selectedGameState)
  const {
    data: profiles,
    refetch: refetchProfiles,
    isSuccess: isFetchProfileSuccess,
    isLoading: isFetchProfileLoading,
  } = useGetMeCategoryProfileByTag(game?.key ?? 'league-of-legends')

  function hasDefaultInGameAccount(): boolean {
    return !!profiles?.find((item) => {
      return item.isSelected && item.username !== null
    })
  }

  return {
    profiles: excludeTeammate
      ? profiles?.filter((item) => {
          return !item.isTeammate
        })
      : profiles,
    refetchProfiles: refetchProfiles,
    hasDefaultInGameAccount,
    isFetchProfileSuccess,
    isFetchProfileLoading,
    defaultProfile: profiles?.find((item) => {
      return item.isSelected
    }),
  }
}
