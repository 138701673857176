import { atom } from 'recoil'
import IconPlay from '../../../public/static/product/mode/lol/icon_play_mode.png'
import IconAccounts from '../../../public/static/product/mode/lol/icon_accounts_mode.png'
import { StaticImageData } from 'next/image'

const modes: Mode[] = [
  {
    id: 1,
    image: IconPlay,
    name: 'Play',
  },
  {
    id: 2,
    image: IconAccounts,
    name: 'Accounts',
  },
]

export const selectedProductModeState = atom<Mode>({
  key: 'selectedProductModeState',
  default: modes[0],
})

type Mode = {
  id: number
  image: StaticImageData
  name: string
}

export default function useProductMode() {
  return { modes }
}
