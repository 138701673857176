import { ChargeResponse } from '../../types/currencies/responses/charge-response'

export function getPriceByCurrencyId(
  currencyId: number | null,
  charges: ChargeResponse[] | undefined,
  defaultPrice: number = 0,
): number {
  const matchingCharge = charges?.find(
    (charge) => charge.currency?.id === currencyId,
  )

  return matchingCharge ? matchingCharge.price ?? defaultPrice : defaultPrice
}
