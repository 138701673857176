import React from 'react'
import { ProductResponse } from '../../../types/products/product-response'
import { TitlePart } from './TitlePart'
import { HowItWorkPart } from './HowItWorkPart'
import HowItWorks1Icon from '../../../public/static/product-detail/how-it-works-1.png'
import HowItWorks2Icon from '../../../public/static/product-detail/how-it-works-2.png'
import HowItWorks3Icon from '../../../public/static/product-detail/how-it-works-3.png'

type Props = { product: ProductResponse }

export function HowItWorkSection({ product }: Props) {
  return (
    <div className={'relative'}>
      <TitlePart title={'HOW IT WORKS'} />

      <div className={'will-wrapper mt-5 flex w-full flex-col gap-12'}>
        <HowItWorkPart
          title={'Setup your account.'}
          description={
            'Login with Discord or email and add your in game name for the games you play.'
          }
          image={HowItWorks1Icon}
        />

        <HowItWorkPart
          title={'Choose a game and game mode.'}
          description={
            'The game mode determines what type of teammate we match with you. Modes vary by price, depending on teammate availability and other factors.'
          }
          image={HowItWorks2Icon}
        />

        <HowItWorkPart
          title={'Start!'}
          description={
            'After about 2 minutes* you will be matched and your new teammate will add you in-game (and optionally voice chat platform).'
          }
          image={HowItWorks3Icon}
        />
      </div>
    </div>
  )
}
