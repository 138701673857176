import { useEffect } from 'react'
import { getStoredControl } from './user-control-storage'
import { ProductResponse } from '../../types/products/product-response'
import { useRecoilState } from 'recoil'
import { categoriesState, selectedGameState } from '../../common/atoms/atoms'
import { CategoryPaginationResponse } from '../../types/categories/responses/category-pagination-response'
import {
  activeRequestState,
  selectedProductAddOnsState,
  selectedProductState,
} from '../../common/atoms/checkout'
import { CategoryResponse } from '../../types/categories/responses/category-response'
import { ProductModeEnum } from '../../common/enums/product-mode.enum'
import { isNil } from '../../common/utils/common.utils'

export function useSavedGame(
  categories: CategoryPaginationResponse,
  seoCategoryKey?: string,
  seoProductMode?: ProductModeEnum,
  readSavedGame = true,
) {
  const [, setGame] = useRecoilState(selectedGameState)
  const [selectedProduct, setSelectedProduct] =
    useRecoilState(selectedProductState)
  const [, setCategories] = useRecoilState(categoriesState)
  const [, setSelectedAddOns] = useRecoilState(selectedProductAddOnsState)
  const [activeRequest] = useRecoilState(activeRequestState)

  useEffect(() => {
    if (isNil(categories)) return

    setCategories(categories?.data)
    // if (!readSavedGame) {
    //   return
    // }
    try {
      const control = getStoredControl()

      let savedProduct: ProductResponse | undefined
      let game: CategoryResponse | undefined

      if (
        activeRequest?.orderItem?.product !== undefined &&
        activeRequest?.orderItem?.product !== null &&
        activeRequest?.orderItem?.product?.category !== undefined &&
        activeRequest?.orderItem?.product?.category !== null
      ) {
        if (activeRequest.transactionSnapshot?.streamer) {
          const product = { ...activeRequest.orderItem.product }
          product.streamer = activeRequest?.transactionSnapshot?.streamer
          setSelectedProduct(product)
          setGame(activeRequest.orderItem?.product?.category)
        } else {
          let game = categories?.data?.find((game) => {
            return activeRequest.orderItem?.product?.categoryKey === game.key
          })
          let savedProduct = game?.products?.find((product) => {
            return product.id === activeRequest?.orderItem?.product?.id
          })
          setSelectedProduct(savedProduct ?? activeRequest?.orderItem?.product)
          setGame(game ?? activeRequest.orderItem.product.category)
        }
        return
      }

      game = categories?.data?.find((game) => {
        return game.key === control?.selectedGame?.key
      })

      savedProduct = game?.products?.find((product) => {
        if (seoProductMode) return product.mode === seoProductMode
        return product.id === control?.selectedProduct?.id
      })

      if (!readSavedGame && seoCategoryKey !== game?.key) {
        return
      }

      if (game && savedProduct) {
        if (!savedProduct.uniqueId) {
          savedProduct.uniqueId = `${savedProduct.id}`
        }
        setSelectedProduct(savedProduct)
        setGame(game)
        return
      }
    } catch (e) {}

    if (readSavedGame) {
      const game = categories?.data[0]
      setGame(game)
      if (game.products && game.products.length > 0)
        setSelectedProduct(game.products[0])
    }
  }, [activeRequest])

  useEffect(() => {
    setSelectedAddOns((prev) =>
      prev?.filter((addon) =>
        selectedProduct?.productAddons?.some((productAddon) =>
          productAddon?.addons?.map((item) => item.id).includes(addon.id),
        ),
      ),
    )
  }, [selectedProduct])
}
