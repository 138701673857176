import React from 'react'

type Props = {
  status: 'Inviting' | 'Invited' | 'Invite'
  onClickInvite?: () => void
  onClickRemove?: () => void
  buttonText?: string
  isDisabled?: boolean
  isLoading?: boolean
}
export default function FriendCardControl({
  status,
  onClickInvite,
  onClickRemove,
  buttonText,
  isDisabled,
  isLoading,
}: Props) {
  return (
    <button
      className={`btn-v2-fizz-600 min-h-10 h-10 hover:bg-fizz-400 hover:border-fizz-300 rounded-lg text-sm normal-case 
      ${(isDisabled || status === 'Inviting' || status === 'Invited') && 'opacity-30'}`}
      onClick={(e) => {
        e.stopPropagation()
        if (isDisabled || isLoading) return

        if (status === 'Inviting') {
          return
        }

        if (status === 'Invite') {
          if (onClickInvite) onClickInvite()
        }

        if (status === 'Invited') {
          if (onClickRemove) onClickRemove()
        }
      }}
    >
      {buttonText ?? 'Invite'}
    </button>
  )
}
