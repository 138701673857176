import { useQuery } from '@tanstack/react-query'
import { getCategoryTag } from '../../api/categories/category-tag'
import { CategorySpecializedTag } from '../../types/users/requests/category-specialized-tag-response'
import { categoryTags } from '../../common/react-query/react-query-keys'

export const useGetCategoriesTag = (key: string) => {
  const { data, isLoading, isFetched, error, refetch } = useQuery<
    CategorySpecializedTag[]
  >([categoryTags, key], async () => getCategoryTag(key))

  return {
    data,
    isLoading,
    isFetched,
    error,
    refetch,
  }
}
