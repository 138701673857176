import React from 'react'
import Image from 'next/image'
import YellowShineIcon from '../../public/static/yellow-shine.png'
import Link from 'next/link'
import { plansUrl } from '../../common/urls/homeUrl'

export default function GetProButton() {
  return (
    <Link href={plansUrl}>
      <div className='relative hover:opacity-80 transition-opacity duration-300 hidden sm:flex'>
        <button
          className={`
        relative
        bg-[#F9B700]
        text-black
        font-fjalla
        text-body-medium
        w-28
        cursor-pointer
        overflow-hidden
        transition
        transform
        h-12
        sm:mr-4
        mr-2
        rounded-md
      `}
        >
          <span className='relative z-20'></span>
        </button>

        <Image
          src={YellowShineIcon}
          alt='Shine'
          className='absolute top-0 left-9 w-1/3 h-12 opacity-90 pointer-events-none z-10'
        />

        <span className='absolute text-body-medium font-fjalla top-3 text-black left-8 z-20 pointer-events-none'>
          GET PRO
        </span>
      </div>
    </Link>
  )
}
