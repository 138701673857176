import { authFetch } from '../../common/networking/auth-fetch'

export async function blockPromoCode(
  accessToken: any,
  codeId: number,
): Promise<Response> {
  return await authFetch(accessToken).delete(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/promo-codes/${codeId}/block`,
  )
}
