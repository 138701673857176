import { authFetch } from '../../common/networking/auth-fetch'
import { CreateTeamRequest } from '../../types/teams/requests/create-team-request'
import { TeamResponse } from '../../types/teams/responses/team-response'
import { TeamFriendlistResponse } from '../../types/teams/responses/team-friendlist-response'

export async function postCreateTeam(
  accessToken: any,
  request: CreateTeamRequest,
): Promise<Response> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams`
  return authFetch(accessToken).post(url, request)
}

export async function getTeam(
  accessToken: any,
  teamId: string,
): Promise<TeamResponse> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/${teamId}`
  const response = await authFetch(accessToken).get(url)
  return response.json()
}

export async function getTeamFriendList(
  accessToken: any,
  teamId: string,
): Promise<TeamFriendlistResponse> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/${teamId}/friendlist`
  const response = await authFetch(accessToken).get(url)
  return response.json()
}

export async function getActiveTeam(
  accessToken: any,
  filter?: string,
): Promise<TeamResponse> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/active?filter=${filter ?? 'ALL'}`
  const response = await authFetch(accessToken).get(url)
  return response.json()
}

export async function closeTeam(
  accessToken: any,
  teamId: string,
): Promise<Response> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/${teamId}/close`
  return await authFetch(accessToken).post(url)
}

export async function inviteTeammateToTeam(
  accessToken: any,
  teamId: string,
  teammateId: string,
): Promise<Response> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/${teamId}/teammates/${teammateId}`
  return await authFetch(accessToken).post(url)
}

export async function removeTeammateFromTeam(
  accessToken: any,
  teamId: string,
  teammateId: string,
): Promise<Response> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/${teamId}/teammates/${teammateId}`
  return await authFetch(accessToken).delete(url)
}
