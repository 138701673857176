import React from 'react'
import { ProductResponse } from '../../../types/products/product-response'
import { TitlePart } from './TitlePart'
import { TrustPilotReviewPart } from './TrustPilotReviewPart'
import Review1Icon from '../../../public/static/product-detail/review-1.png'
import Review2Icon from '../../../public/static/product-detail/review-2.png'
import Review3Icon from '../../../public/static/product-detail/review-3.png'
import Review4Icon from '../../../public/static/product-detail/review-4.png'
import Review5Icon from '../../../public/static/product-detail/review-5.png'

import { Swiper, SwiperSlide } from 'swiper/react'

type Props = { product: ProductResponse }

export function ReviewSection({ product }: Props) {
  return null
  return (
    <div className={'flex w-full flex-col'}>
      <TitlePart title={'RECENT REVIEWS'} />

      <div className={'mt-3.5'}>
        <Swiper
          className={'flex flex-row'}
          slidesPerView={'auto'}
          centeredSlides={false}
          updateOnWindowResize={true}
          allowTouchMove={true}
          threshold={35}
          loop={false}
          direction={'horizontal'}
        >
          <SwiperSlide key={'Fast and Easy'}>
            <TrustPilotReviewPart
              title={'Fast and Easy'}
              author={'Dylan Miller'}
              date={'June 4, 2023'}
              description={
                'Gave it a shot after seeing multiple videos from Rav and Dante. It was really easy to use and the best platform to find teammates, got a match in 30 seconds that was communicative and fun to play with. 10/10 definitely recommend.'
              }
              image={Review1Icon}
            />
          </SwiperSlide>

          <SwiperSlide key={'Easy to use2'}>
            <TrustPilotReviewPart
              title={'Easy to use'}
              author={'yams'}
              date={'June 4, 2023'}
              description={
                'Gave it a shot after seeing Rav AND Doaenel do multiple youtube videos. It was just as easy as they showed it and the player I was paired with was fun and easy going.'
              }
              image={Review4Icon}
            />
          </SwiperSlide>

          <SwiperSlide key={'Perfection, and Kindness'}>
            <TrustPilotReviewPart
              title={'Perfection, and Kindness'}
              author={'ATrashBandit'}
              date={'May 2, 2023'}
              description={
                'Where to start? The teammates are VERY kind and friendly, Infinity is one of the kindness humans ever! Also its just free wins! You guaranteed to have a wonderful experience. Im one happy person and would recommend them to ANYONE.'
              }
              image={Review2Icon}
            />
          </SwiperSlide>

          <SwiperSlide key={'Just Try it'}>
            <TrustPilotReviewPart
              title={'Just Try it'}
              author={'Zilla'}
              date={'May 9, 2023'}
              description={
                'My personal experience with tapingg has definitely been such a fun experience! I learn so much from the players, and getting a ton of Ws in the process. I look forward to continuing using this great service . Also to add, Jude & Kostas are amazing at what they do to ensure customers are 100% happy. For personally being a professional on the customer service in the field of tech, I can say they provide A+ service!'
              }
              image={Review3Icon}
            />
          </SwiperSlide>

          <SwiperSlide key={'Copilot coaching'}>
            <TrustPilotReviewPart
              title={'Copilot coaching'}
              author={'Balazs'}
              date={'June 23, 2023'}
              description={
                'I ordered coaching, got 2 sessions with Xveezy,I can recommend it to everyone for sure.The website is great, good customer support and decent service overall.The experience worth the money in my opinion because the quality you get.'
              }
              image={Review5Icon}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}
