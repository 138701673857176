import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { postCreateReferralCode } from '../../api/users/users'
import { CreateReferralCodeRequest } from '../../types/users/requests/create-referral-code-request'

export function usePostCreateReferralCode() {
  const { data } = useSession()

  return useMutation(
    async (request: CreateReferralCodeRequest) =>
      // @ts-ignore
      postCreateReferralCode(data?.accessToken, request),
    {
      retry: false,
    },
  )
}
