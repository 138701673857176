import { useRecoilState } from 'recoil'
import { selectedAccountProductState } from '../../../../common/atoms/checkout'
import React from 'react'
import Image, { StaticImageData } from 'next/image'

type Props = {
  checked?: boolean
  onClick?: () => void
  value?: string
  number?: string
  image: StaticImageData
}

export default function InfoSelectorItem({
  value,
  image,
  number,
  checked,
  onClick,
}: Props) {
  const [selectedAccountProduct, setSelectAccountProduct] = useRecoilState(
    selectedAccountProductState,
  )

  const background = checked ? 'bg-bult-1000' : 'bg-fizz-700'
  const numberBackground = checked ? 'bg-kassadin-600' : 'bg-fizz-500'

  const border = checked ? 'border-kassadin-300' : 'border-fizz-700'

  return (
    <div
      className={`flex flex-row items-center justify-between flex-1 pl-5 pr-2 py-1 h-13 cursor-pointer rounded-lg border ${background} ${border}`}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div className={'flex flex-row gap-2 items-center'}>
        <div className={'relative w-4 h-4 shrink-0'}>
          <Image
            src={image}
            fill={true}
            className={'object-contain'}
            alt={`${value} icon`}
          />
        </div>
        <p className={'text-white text-body-bold hidden sm:flex'}>{value}</p>
      </div>

      <div
        className={`py-1 px-2 flex flex-row items-center justify-center rounded-lg text-white ${numberBackground}`}
      >
        {number}
      </div>
    </div>
  )
}
