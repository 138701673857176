import React, { useEffect, useState } from 'react'
import { AddonResponse } from '../../../types/addons/responses/AddonResponse'
import { useRecoilState } from 'recoil'
import { selectedProductAddOnsState } from '../../../common/atoms/checkout'
import Select, { components, OptionProps, SingleValueProps } from 'react-select'
import { defaultCurrencyState } from '../../../common/atoms/atoms'
import { getPriceByCurrencyId } from '../../../common/utils/addon.utils'
import { formatAmountWithCurrency } from '../../../common/utils/currency.utils'
import { useLocalizedPrice } from '../../../hooks/currencies/useLocalizedPrice'

type Props = {
  addon: AddonResponse
  addons: AddonResponse[]
  myAddons?: AddonResponse[]
  showPrice?: boolean
}
export default function SelectSection({
  addon,
  addons,
  myAddons,
  showPrice,
}: Props) {
  const [, setSelectedAddOns] = useRecoilState(selectedProductAddOnsState)
  const [currency] = useRecoilState(defaultCurrencyState)
  const { getLocalizedPrice } = useLocalizedPrice()

  const [choice, setChoice] = useState<{
    value: number
    label: string
    priceString?: string
    amount: number | undefined
    type?: string
  } | null>(null)

  useEffect(() => {
    const options = addons
    if (options && options.length > 0) {
      const myOption = addons?.find((option) =>
        myAddons?.some((itemA) => itemA?.id === option?.id),
      )

      if (myOption) {
        const matchingCharge = getLocalizedPrice(myOption.charge ?? [])
        const priceString = formatAmountWithCurrency(
          matchingCharge?.price ?? 0,
          matchingCharge?.currency,
        )
        const price = matchingCharge ? matchingCharge?.price ?? 0 : 0

        setChoice({
          value: myOption.id,
          label: myOption.title,
          priceString: priceString,
          amount: price,
          type: myOption.type,
        })
      } else {
        setChoice({
          value: -1,
          label: '',
          amount: 0,
          type: '',
        })
      }
    }
  }, [currency])

  useEffect(() => {
    setSelectedAddOns((currentAddOns) => {
      const selectItem = addons.find((option) => option.id === choice?.value)
      let newAddOns = currentAddOns.filter(
        (addOn) => addOn.parentId !== selectItem?.parentId,
      )

      if (selectItem) newAddOns = [...newAddOns, selectItem]
      return newAddOns.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i,
      )
    })
  }, [choice])

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<{
    value: number
    label: string
    priceString?: string
    amount: number | undefined
    type?: string
  }>) => (
    <components.SingleValue {...props}>
      <div
        className={
          'text-secondary-normal flex flex-row items-center justify-between text-white'
        }
      >
        {/* @ts-ignore */}
        <p className={'ml-0'}>{props.selectProps.value?.label}</p>

        {showPrice && (
          <p className={'mr-0'}>
            {/*  @ts-ignore */}
            {(props.selectProps.value?.amount ?? 0) > 0
              ? `${
                  //   @ts-ignore
                  props.selectProps.value?.type === 'add' ? '+' : ''
                }${
                  //   @ts-ignore
                  props.selectProps.value?.priceString
                }`
              : ''}
          </p>
        )}
      </div>
    </components.SingleValue>
  )

  const Option = (
    props: OptionProps<{
      value: number
      label: string
      priceString?: string
      amount: number | undefined
      type?: string
    }>,
  ) => {
    return (
      <components.Option {...props}>
        <div
          className={
            'text-secondary-normal flex flex-row items-center justify-between text-white'
          }
        >
          <p className={'ml-0'}>{props?.data?.label}</p>
          {showPrice && (
            <p className={'mr-3'}>
              {(props?.data?.amount ?? 0) > 0
                ? `${props?.data?.type === 'add' ? '+' : ''}${
                    props?.data?.priceString
                  }`
                : ''}
            </p>
          )}
        </div>
      </components.Option>
    )
  }

  return (
    <div className={'w-full '}>
      <div className={'flex flex-col gap-2'}>
        <div className={'flex flex-row items-center gap-2'}>
          <h6 className={'text-body-medium self-start text-[#F87B20]'}>
            {addon.title}
          </h6>
        </div>
        {choice &&
          (choice.value === -1 ? (
            <Select
              id={currency?.id + 'Option'}
              components={{ SingleValue, Option }}
              isMulti={false}
              menuPosition={'fixed'}
              menuPlacement={'top'}
              onChange={(e) => {
                if (e) setChoice(e)
              }}
              options={
                addons?.map((item) => {
                  const currentCharge = getLocalizedPrice(item.charge ?? [])
                  return {
                    value: item.id,
                    label: item.title,
                    priceString: formatAmountWithCurrency(
                      currentCharge?.price,
                      currentCharge?.currency,
                    ),
                    amount: +getPriceByCurrencyId(
                      currency?.id ?? 1,
                      item.charge,
                    ).toFixed(2),

                    type: item.type,
                  }
                }) ?? []
              }
              placeholder={`Please Select Your ${addon.title}`}
              className='react-select-container-v2 mt-1.5'
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: '#A2ADC5', // Placeholder color
                },
              })}
              styles={{
                // @ts-ignore
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: 'transparent',
                  border: ' none',
                  boxShadow: 'none',
                }),

                // @ts-ignore
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isFocused ? '#212900' : 'transparent',
                }),
              }}
            />
          ) : (
            <Select
              id={currency?.id + 'Option'}
              components={{ SingleValue, Option }}
              isMulti={false}
              menuPosition={'fixed'}
              menuPlacement={'top'}
              onChange={(e) => {
                if (e) setChoice(e)
              }}
              value={choice}
              options={
                addons?.map((item) => {
                  const currentCharge = getLocalizedPrice(item.charge ?? [])
                  return {
                    value: item.id,
                    label: item.title,
                    priceString: formatAmountWithCurrency(
                      currentCharge?.price,
                      currentCharge?.currency,
                    ),
                    amount: +getPriceByCurrencyId(
                      currency?.id ?? 1,
                      item.charge,
                    ).toFixed(2),

                    type: item.type,
                  }
                }) ?? []
              }
              className='react-select-container-v2'
              classNamePrefix='react-select'
              styles={{
                // @ts-ignore
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }),

                // @ts-ignore
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isFocused ? '#212900' : 'transparent',
                }),
              }}
            />
          ))}
      </div>
    </div>
  )
}
