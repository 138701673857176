import React, { useEffect } from 'react'
import Image from 'next/image'
import CloseIcon from '../../../public/static/close_gray.svg'
import Divider from '../../divider/Divider'
import { ToastProps } from 'react-toastify/dist/types'
import { useGetCreatorById } from '../../../hooks/creators/useGetCreatorById'
import { usePutReadGiftingMessages } from '../../../hooks/tips/usePutReadGiftingMessages'

type Props = {
  id: number
  closeToast: (() => void) | undefined
  toastProps: ToastProps
  comment: string
}
export default function CommentToast({
  id,
  closeToast,
  toastProps,
  comment,
}: Props) {
  const { data: teammate } = useGetCreatorById(id)
  const { mutate } = usePutReadGiftingMessages()

  useEffect(() => {
    mutate()
  }, [])

  return (
    <div className='relative h-full w-full bg-fizz-900 pt-5'>
      <div className='absolute right-0 top-0 pr-5 pt-5'>
        <button
          type='button'
          className='rounded-full bg-fizz-900 text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={closeToast}
        >
          <div className='focus:outline-none focus:ring-0'>
            <Image src={CloseIcon} width={14} height={14} alt={'Close icon'} />
          </div>
        </button>
      </div>

      <header className={'mx-5 flex flex-row items-center gap-3'}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M3.54797 5.03553C5.80922 3.28053 8.81109 2.31396 12 2.31396C12.2906 2.31396 12.5798 2.32193 12.8672 2.33787C15.7303 2.49678 18.397 3.44037 20.452 5.03553C22.74 6.81115 24 9.18021 24 11.7063C24 13.9849 22.9552 16.1669 21.052 17.876C21.6122 19.1796 22.3294 20.2568 23.1858 21.0804C23.288 21.1788 23.3213 21.3288 23.2702 21.461C23.2186 21.5932 23.093 21.6818 22.9514 21.6855C22.9416 21.6855 22.9134 21.686 22.868 21.686C22.3716 21.686 19.8291 21.6152 17.0147 20.2427C15.7003 20.7166 14.3095 20.9951 12.8677 21.0747C12.5803 21.0907 12.2911 21.0987 12 21.0987C8.81109 21.0987 5.80922 20.1316 3.54797 18.3766C1.26 16.601 0 14.2319 0 11.7063C0 9.18021 1.26 6.81115 3.54797 5.03553Z'
            fill='#9348C9'
          />
          <path
            d='M5.32875 12C5.32875 12.9694 6.1162 13.7578 7.08436 13.7578C7.28943 13.7578 7.4865 13.7227 7.66954 13.6575C8.35073 13.4156 8.83997 12.7641 8.83997 12C8.83997 11.2359 8.35073 10.5844 7.66954 10.3425C7.4865 10.2773 7.28939 10.2422 7.08436 10.2422C6.1162 10.2422 5.32875 11.0306 5.32875 12Z'
            fill='white'
          />
          <path
            d='M5.32876 12C5.32876 12.9694 6.11621 13.7578 7.08437 13.7578C7.28945 13.7578 7.48651 13.7227 7.66956 13.6575C6.98837 13.4156 6.49913 12.7641 6.49913 12C6.49913 11.2359 6.98837 10.5844 7.66956 10.3425C7.48651 10.2773 7.2894 10.2422 7.08437 10.2422C6.11621 10.2422 5.32876 11.0306 5.32876 12Z'
            fill='#DCECF4'
          />
          <path
            d='M15.16 11.9998C15.16 12.9691 15.9475 13.7576 16.9157 13.7576C17.1207 13.7576 17.3178 13.7224 17.5008 13.6573C18.182 13.4154 18.6713 12.7638 18.6713 11.9998C18.6713 11.2357 18.182 10.5841 17.5008 10.3423C17.3178 10.2771 17.1207 10.2419 16.9157 10.2419C15.9475 10.2419 15.16 11.0304 15.16 11.9998Z'
            fill='white'
          />
          <path
            d='M15.1601 11.9998C15.1601 12.9691 15.9475 13.7576 16.9157 13.7576C17.1207 13.7576 17.3178 13.7224 17.5009 13.6573C16.8197 13.4154 16.3304 12.7638 16.3304 11.9998C16.3304 11.2357 16.8197 10.5841 17.5009 10.3423C17.3178 10.2771 17.1207 10.2419 16.9157 10.2419C15.9475 10.2419 15.1601 11.0304 15.1601 11.9998Z'
            fill='#DCECF4'
          />
          <path
            d='M10.2443 11.9998C10.2443 12.9691 11.0318 13.7576 11.9999 13.7576C12.205 13.7576 12.4021 13.7224 12.5851 13.6573C13.2663 13.4154 13.7556 12.7638 13.7556 11.9998C13.7556 11.2357 13.2663 10.5841 12.5851 10.3423C12.4021 10.2771 12.205 10.2419 11.9999 10.2419C11.0318 10.2419 10.2443 11.0304 10.2443 11.9998Z'
            fill='white'
          />
          <path
            d='M10.2443 11.9998C10.2443 12.9691 11.0318 13.7576 12 13.7576C12.205 13.7576 12.4021 13.7224 12.5851 13.6573C11.904 13.4154 11.4147 12.7638 11.4147 11.9998C11.4147 11.2357 11.904 10.5841 12.5851 10.3423C12.4021 10.2771 12.205 10.2419 12 10.2419C11.0318 10.2419 10.2443 11.0304 10.2443 11.9998Z'
            fill='#DCECF4'
          />
          <path
            d='M1.90735e-05 11.7063C1.90735e-05 14.2319 1.26002 16.601 3.54799 18.3766C5.80924 20.1316 8.81111 21.0987 12 21.0987C12.2911 21.0987 12.5803 21.0907 12.8677 21.0747C10.0046 20.9158 7.33736 19.9718 5.28236 18.3766C2.9944 16.601 1.7344 14.2319 1.7344 11.7063C1.7344 9.18021 2.9944 6.81115 5.28236 5.03553C7.33736 3.44037 10.0041 2.49678 12.8672 2.33787C12.5799 2.32193 12.2906 2.31396 12 2.31396C8.81111 2.31396 5.80924 3.28053 3.54799 5.03553C1.26002 6.81115 1.90735e-05 9.18021 1.90735e-05 11.7063Z'
            fill='#4E2D65'
          />
        </svg>
        <h6 className={'text-body-normal text-color-primary'}>New comment</h6>
      </header>
      <div className={'mt-5'}>
        <Divider></Divider>
      </div>

      <div className={'mx-5 mb-5 mt-2.5 flex flex-col gap-3'}>
        <div className={'flex flex-row items-center gap-2.5'}>
          <div className={'relative h-8 w-8 rounded-full'}>
            <Image
              src={
                teammate?.avatar?.imageUrl ??
                process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
              }
              className={'rounded-full object-cover'}
              fill={true}
              alt={'User avatar'}
            />
          </div>

          <div className={'text-white'}>
            {teammate?.username ?? 'The teammate'} sent you a comment
          </div>
        </div>

        <div
          className={'flex w-full items-center justify-center bg-fizz-600 p-4'}
        >
          <div className={'text-white'}>{comment}</div>
        </div>
      </div>
    </div>
  )
}
