import { useQueryClient } from '@tanstack/react-query'
import { useIntercom } from 'react-use-intercom'
import { useLogoutUser } from './useLogoutUser'
import { useRequestStates } from '../requests/useRequestStates'
import { logout } from '../../common/utils/logout'
import { useSession } from 'next-auth/react'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'

export function useLogout() {
  const queryClient = useQueryClient()
  const { shutdown } = useIntercom()
  const { mutate: logoutUser } = useLogoutUser()
  const { redirectToHome } = useRequestStates()
  const { status } = useSession()

  function logoutCurrentSession() {
    if (status !== SessionStatusEnum.AUTHENTICATED) return

    queryClient.clear()
    try {
      shutdown()
    } catch (e) {}
    setTimeout(() => {
      logoutUser()
      logout()
      redirectToHome()
    }, 1000)
  }

  return {
    logoutCurrentSession,
  }
}
