import { useEffect, useRef, useState } from 'react'
import { UserResponse } from '../../types/users/user-response'
import { GameCategories } from '../../common/enums/game-categories'
import IconGirl from '../../public/static/product/icon_girl.svg'
import IconAram from '../../public/static/product/icon_aram.svg'
import IconPopcorn from '../../public/static/product/icon_popcorn.svg'
import { GameTeammatesV2 } from '../../api/creators/getGamingTeammates'

export default function useProduct(
  eGirlTeammates: GameTeammatesV2,
  gameKey: string,
) {
  const [teammate, setTeammate] = useState<UserResponse | null>()
  const [secondTeammate, setSecondTeammate] = useState<UserResponse | null>()
  const [thirdTeammate, setThirdTeammate] = useState<UserResponse | null>()
  const [fourthTeammate, setFourthTeammate] = useState<UserResponse | null>()
  const interval = useRef<any | null>(null)

  function splitTeammates() {
    switch (gameKey) {
      case GameCategories.leagueOfLegends:
        return eGirlTeammates?.leagueSocialTeammates
      case GameCategories.fortnite:
        return eGirlTeammates?.leagueSocialTeammates
      case GameCategories.valorant:
        return eGirlTeammates?.valorantSocialTeammates
      case GameCategories.tft:
        return eGirlTeammates?.tftSocialTeammates
      default:
        return eGirlTeammates?.leagueSocialTeammates
    }
  }

  function filterDecoration(mode?: string, slug?: string) {
    if (mode === 'companion' && slug?.includes('girl')) {
      return IconGirl
    }
    if (slug?.includes('aram')) {
      return IconAram
    }
    if (slug?.includes('swarm')) {
      return IconPopcorn
    }
    return null
  }

  function filterTeammates(
    slug?: string,
    categoryKey?: string,
    gameKey?: string,
  ) {
    if (gameKey === GameCategories.egirls) {
      if (categoryKey === GameCategories.leagueOfLegends) {
        return teammate
      } else if (categoryKey === GameCategories.fortnite) {
        return secondTeammate
      } else if (categoryKey === GameCategories.valorant) {
        return thirdTeammate
      } else if (categoryKey === GameCategories.marvelRivals) {
        return fourthTeammate
      }
      return teammate
    }
    if (slug?.includes('girl')) {
      return teammate
    }

    if (slug?.includes('aram')) {
      return secondTeammate
    }

    if (slug?.includes('swarm')) {
      return thirdTeammate
    }

    return null
  }

  function randomTeammates() {
    const teammates = splitTeammates()?.teammates
    if (teammates?.length) {
      let firstIndex, secondIndex, thirdIndex, fourthIndex
      // do {
      //   firstIndex = Math.floor(Math.random() * teammates.length)
      //   secondIndex = Math.floor(Math.random() * teammates.length)
      // } while (firstIndex === secondIndex) // to make sure we have two different random indices

      firstIndex = Math.floor(Math.random() * teammates.length)
      secondIndex = Math.floor(Math.random() * teammates.length)
      thirdIndex = Math.floor(Math.random() * teammates.length)
      fourthIndex = Math.floor(Math.random() * teammates.length)

      setTeammate(teammates[firstIndex])
      setSecondTeammate(teammates[secondIndex])
      setThirdTeammate(teammates[thirdIndex])
      setFourthTeammate(teammates[fourthIndex])
    } else {
      setTeammate(null)
      setSecondTeammate(null)
      setThirdTeammate(null)
      setFourthTeammate(null)
    }
  }

  useEffect(() => {
    randomTeammates()

    interval.current = setInterval(() => {
      randomTeammates()
    }, 4000)

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [])

  return { filterTeammates, filterDecoration }
}
