import React, { useEffect, useState } from 'react'
import { AddonResponse } from '../../../types/addons/responses/AddonResponse'
import { useRecoilState } from 'recoil'
import { selectedProductAddOnsState } from '../../../common/atoms/checkout'
import CheckBoxItemV2 from './checkbox/CheckBoxItemV2'
import FreeCheckBoxItemV2 from './checkbox/FreeCheckBoxItemV2'
import { getPriceByCurrencyId } from '../../../common/utils/addon.utils'
import { defaultCurrencyState } from '../../../common/atoms/atoms'
import { useLocalizedPrice } from '../../../hooks/currencies/useLocalizedPrice'
import { formatAmountWithCurrency } from '../../../common/utils/currency.utils'

type Props = {
  addon: AddonResponse
  addons: AddonResponse[]
  myAddons?: AddonResponse[]
  twoRows?: boolean
}
export default function CheckBoxSection({
  addon,
  addons,
  myAddons,
  twoRows,
}: Props) {
  const [, setSelectedAddOns] = useRecoilState(selectedProductAddOnsState)
  const [checkBoxes, setCheckBoxes] = useState<AddonResponse[]>([])
  const [currency] = useRecoilState(defaultCurrencyState)
  const { getLocalizedPrice } = useLocalizedPrice()
  useEffect(() => {
    setCheckBoxes(addons)
    if (addons && addons.length > 0) {
      const myOption = addons?.filter((option) =>
        myAddons?.some((itemA) => itemA?.id === option?.id),
      )
      if (myOption && myOption.length > 0) {
        myOption.forEach((item) => {
          handleCheckBoxChange(item.id)
        })
      } else {
        //handleCheckBoxChange(addons[0].id)
      }
    }
  }, [])

  const handleCheckBoxChange = (id: number) => {
    setCheckBoxes((prevCheckBoxes) =>
      prevCheckBoxes?.map((checkBox) =>
        checkBox.id === id
          ? { ...checkBox, checked: !checkBox.checked }
          : checkBox,
      ),
    )
  }

  useEffect(() => {
    const checkedIds = checkBoxes.filter((checkBox) => checkBox.checked)

    setSelectedAddOns((currentAddOns) => {
      let newAddOns = currentAddOns.filter(
        (addOn) => !addons.some((option) => option.id === addOn.id),
      )

      newAddOns = [...newAddOns, ...checkedIds]

      return newAddOns.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i,
      )
    })
  }, [checkBoxes])

  return (
    <div className={'w-full'}>
      <div className={'flex flex-col gap-2'}>
        <div className={'flex flex-row items-center gap-2'}>
          <h6 className={'text-body-medium self-start text-[#F87B20]'}>
            {addon.title === 'Preferred teammate roles'
              ? 'I want my teammate to play'
              : addon.title}
          </h6>
        </div>

        <div className={'mt-1.5'}>
          <div
            className={`${
              twoRows ? 'flex flex-row flex-wrap' : 'flex flex-col'
            } gap-1.5`}
          >
            {checkBoxes?.map((checkBox) => {
              const currentCharge = getLocalizedPrice(checkBox.charge)
              return twoRows ? (
                <FreeCheckBoxItemV2
                  key={checkBox.id}
                  id={checkBox.id}
                  checked={checkBox.checked ?? false}
                  onChange={handleCheckBoxChange}
                  option={checkBox.title}
                  price={
                    +getPriceByCurrencyId(
                      currency?.id ?? 1,
                      checkBox.charge,
                    ).toFixed(2)
                  }
                  hidePrice={twoRows}
                />
              ) : (
                // eslint-disable-next-line react/jsx-no-undef
                <CheckBoxItemV2
                  key={checkBox.id}
                  id={checkBox.id}
                  checked={checkBox.checked ?? false}
                  onChange={handleCheckBoxChange}
                  option={checkBox.title}
                  price={
                    +getPriceByCurrencyId(
                      currency?.id ?? 1,
                      checkBox.charge,
                    ).toFixed(2)
                  }
                  priceString={formatAmountWithCurrency(
                    currentCharge?.price,
                    currentCharge?.currency,
                  )}
                  hidePrice={false}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
