import React, { useState } from 'react'
import Image from 'next/image'

import IconNewTag from '../../../../public/static/select-mode/lobby/friendlist/close/icon-new-tag.svg'

type Props = {
  onClick?: () => void
  buttonText?: string
  num_of_friends?: number
}
export default function FriendListEntry({
  onClick,
  buttonText,
  num_of_friends = 0,
}: Props) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      className={
        'relative bg-fizz-400 border border-fizz-200 hover:border-fizz-100 font-oswald text-white py-3 rounded-lg px-4 transition duration-300'
      }
      onClick={(e) => {
        e.preventDefault()
        onClick && onClick()
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={
          'absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-fizz-1000 bg-bung-400 h-5 w-5'
        }
      >
        <div className={'text-fizz-1000 text-xs  font-oswald font-semibold'}>
          {num_of_friends}
        </div>
      </div>
      <div className={'flex flex-row items-center w-full gap-1.5'}>
        <div className={'absolute top-0 left-0 w-[35px] h-[35px] shrink-0'}>
          <Image
            className=''
            src={IconNewTag}
            alt={'Discord icon'}
            fill={true}
          />
        </div>

        <div className={'shrink-0 ml-1'}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M14 15H7C5.93913 15 4.92172 15.4214 4.17157 16.1716C3.42143 16.9217 3 17.9391 3 19V21'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10.5 11C12.7091 11 14.5 9.20914 14.5 7C14.5 4.79086 12.7091 3 10.5 3C8.29086 3 6.5 4.79086 6.5 7C6.5 9.20914 8.29086 11 10.5 11Z'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M18 16V22'
              stroke={isHovered ? '#C3FF00' : 'white'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M21 19H15'
              stroke={isHovered ? '#C3FF00' : 'white'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <p>FRIENDSLIST</p>
      </div>
    </button>
  )
}
