import React, { ReactElement } from 'react'
import { useRecoilState } from 'recoil'
import { selectedProductState } from '../../../common/atoms/checkout'
import { ProductAddOnsUITypeEnum } from '../../../common/enums/product-add-ons-enum'
import SelectSection from './SelectSection'
import { AddonResponse } from '../../../types/addons/responses/AddonResponse'
import CheckBoxSection from './CheckBoxSection'
import RadioSection from './RadioSection'

type Props = {
  myAddons?: AddonResponse[]
}
export default function AddonsSection({ myAddons }: Props) {
  const [selectedProduct] = useRecoilState(selectedProductState)

  function AddonInputsSection({
    parentAddon,
    addons,
  }: {
    parentAddon: AddonResponse
    addons: AddonResponse[]
  }): ReactElement | null {
    switch (parentAddon?.uiType) {
      case ProductAddOnsUITypeEnum.SELECT:
        return (
          <SelectSection
            key={`SelectSection ${parentAddon.id}-${selectedProduct?.title}`}
            addon={parentAddon}
            addons={addons}
            myAddons={myAddons}
            showPrice={true}
          />
        )
      case ProductAddOnsUITypeEnum.CHECKBOX:
        return (
          <CheckBoxSection
            key={`CheckBoxSection ${parentAddon.id}-${selectedProduct?.title}`}
            addon={parentAddon}
            addons={addons}
            myAddons={myAddons}
            twoRows={!addons.some((addon) => addon?.amount !== 0)}
          />
        )
      case ProductAddOnsUITypeEnum.RADIO:
        return (
          <RadioSection
            key={`RadioSection ${parentAddon.id}-${selectedProduct?.title}`}
            addon={parentAddon}
            addons={addons}
            myAddons={myAddons}
          />
        )
      default:
        return <div className={'text-white'}>{parentAddon.title}</div>
    }
  }

  return (
    <div className={'flex flex-col gap-5 mb-2'}>
      {selectedProduct?.productAddons
        ?.filter((addon) => addon.parentId === null)
        ?.map((addon) => {
          return AddonInputsSection({
            parentAddon: addon,
            addons: [...(addon?.addons ?? [])].sort((a, b) => a.id - b.id),
          })
        })}
    </div>
  )
}
