import React, { useState } from 'react'
import Image from 'next/image'
import IconClose from '../../../../public/static/select-mode/lobby/close/icon-close.svg'
import IconCloseHoverd from '../../../../public/static/select-mode/lobby/close/icon-close-hoverd.svg'

type Props = {
  onClick?: () => void
}
export default function FriendListCloseButton({ onClick }: Props) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      className={'w-fit'}
      onClick={(e) => {
        e.preventDefault()
        onClick && onClick()
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        className=''
        src={isHovered ? IconCloseHoverd : IconClose}
        alt={'Discord icon'}
        width={24}
        height={24}
      />
    </button>
  )
}
