import React from 'react'
import Image, { StaticImageData } from 'next/image'

type Props = { image: StaticImageData; title?: string; value: string }

export default function InfoItem({ image, title, value }: Props) {
  return (
    <div className={'flex flex-col items-center flex-1'}>
      <div className={'flex flex-row gap-2 items-center'}>
        <div className={'relative w-5 h-5'}>
          <Image src={image} alt={`${title} Icon`} fill={true} />
        </div>
        <p className={'text-white text-xl  font-bold'}>{value ?? 0}</p>
      </div>

      <p className={'text-fizz-100 text-sm mt-0.5'}>{title}</p>
    </div>
  )
}
