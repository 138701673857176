import Image from 'next/image'
import React, { useState } from 'react'
import ConfirmButtonIcon from '../../../../public/static/select-mode/icon-waiting-btn.png'
import ConfirmButtonShineIcon from '../../../../public/static/select-mode/icon-waiting-btn-shine.svg'
import { useRecoilState } from 'recoil'
import {
  isBlinkingState,
  isOpenFriendListSection,
} from '../friendlist/FriendListSection'

type Props = {
  onClick: () => void
  isLoading?: boolean
  buttonText?: string
  showAnimatedEffect?: boolean
}
export default function WaitingForTeammateButton({
  onClick,
  isLoading,
}: Props) {
  const [isOpenFriendList, setIsOpenFriendList] = useRecoilState(
    isOpenFriendListSection,
  )
  const [, setIsBlink] = useRecoilState(isBlinkingState)

  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      className='relative h-[86px] w-[300px] sm:w-[340px] shrink-0 cursor-pointer'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e) => {
        e.preventDefault()

        if (isLoading) return
        if (!isOpenFriendList) {
          setIsOpenFriendList(true)
        }
        if (isOpenFriendList) {
          setIsBlink(true)
        }
        if (onClick) onClick()
      }}
    >
      <Image
        src={ConfirmButtonIcon}
        className={`object-contain ${isHovered ? 'opacity-100' : 'opacity-95'} transition duration-300`}
        alt={'confirm button'}
        fill={true}
        priority={true}
      />

      <p
        className={
          'text-center top-1/2 w-full absolute -translate-y-1/2 text-fizz-100 font-oswald font-medium text-xl uppercase z-10'
        }
      >
        {isLoading && <span className='loading loading-spinner mr-2' />}
        <span className={'z-20'}>INVITE TEAMMATE TO START</span>
      </p>

      <div
        className={`h-[70px] w-[71px] sm:w-[81px] ${isHovered ? 'confirm-button-shine-hovered' : 'confirm-button-shine'}`}
      >
        <Image
          src={ConfirmButtonShineIcon}
          fill={true}
          className={'object-contain'}
          alt={'Social mode'}
        />
      </div>
    </div>
  )
}
