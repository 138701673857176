import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { getCreatorById } from '../../api/creators/creators'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { UserResponse } from '../../types/users/user-response'
import { creator } from '../../common/react-query/react-query-keys'

export function useGetCreatorById(
  id: number | undefined | null,
  favorite?: boolean,
  blocked?: boolean,
  cache?: boolean,
  requestId?: number,
) {
  const { data, status } = useSession()

  return useQuery<UserResponse>(
    [creator, id, favorite, blocked, cache, requestId],
    async () =>
      //@ts-ignore
      getCreatorById(data?.accessToken, {
        creatorId: id ?? -1,
        favorite,
        blocked,
        cache,
        requestId,
      }),
    {
      enabled:
        id !== null &&
        id !== undefined &&
        id > 0 &&
        status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}
