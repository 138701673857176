import React, { useEffect } from 'react'
import Image from 'next/image'
import Discord from '../../../public/static/discord_white.svg'
import Apple from '../../../public/static/apple.svg'
import Facebook from '../../../public/static/facebook.svg'
import FacebookLogin from '@greatsumini/react-facebook-login'
import { useRouter } from 'next/router'
// @ts-ignore
import AppleSignIn from 'react-apple-signin-auth'
import { usePostConnectSSOProvider } from '../../../hooks/sso/usePostConnectSSOProvider'
import { SsoRequest } from '../../../types/sso/sso-request'
import { useUser } from '../../../hooks/users/useUser'
import { toast } from 'react-toastify'
import {
  ErrorResponse,
  readableError,
} from '../../../types/errors/responses/error-response'

type Props = {}

export function GuestOauthContainer({}: Props) {
  const router = useRouter()
  const { mutate, isSuccess, error } = usePostConnectSSOProvider()
  const { refetch } = useUser()

  useEffect(() => {
    if (isSuccess) {
      refetch()
    }
  }, [isSuccess])

  useEffect(() => {
    if (error) {
      toast.error(readableError(error as ErrorResponse))
    }
  }, [error])

  return (
    <div className={'flex w-full flex-col gap-3'}>
      {/* Discord button*/}
      <button
        onClick={(e) => {
          e.preventDefault()
          router.push(process.env.NEXT_PUBLIC_DISCORD_APPLICATION_URL!)
        }}
        className='btn flex h-13 flex-auto items-center justify-center rounded-none border border-gray2 bg-[#5865F2] font-fjalla text-base uppercase text-white transition duration-150 hover:bg-[#5865F2] hover:bg-opacity-70 dark:border-none'
      >
        <Image
          className='mr-2'
          src={Discord}
          alt={'Discord icon'}
          width={24}
          height={24}
        />
        Sign up with Discord
      </button>

      <div className='items-evenly flex w-full flex-row gap-2.5'>
        {/* Facebook button */}
        <FacebookLogin
          appId={process.env.NEXT_PUBLIC_DEFAULT_FACEBOOK_ID!}
          initParams={{
            version: 'v13.0',
          }}
          autoLoad={false}
          scope={'email,public_profile'}
          fields='name,email,picture'
          className='btn flex h-13 flex-auto basis-1/2 items-center justify-center rounded-none border border-fizz-1000 bg-white font-fjalla text-base uppercase text-black transition duration-150 hover:bg-white hover:bg-opacity-70 '
          onSuccess={(response) => {
            if (!response) {
              return
            }
            mutate({
              ssoAccessToken: response.accessToken,
              provider: 'facebook',
            } as SsoRequest)
          }}
        >
          <Image
            src={Facebook}
            className={'mr-1.5'}
            width={24}
            height={24}
            alt={'Facebook icon'}
          />
          Facebook
        </FacebookLogin>

        {/* Apple button */}
        <AppleSignIn
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: process.env.NEXT_PUBLIC_DEFAULT_APPLE_ID!,
            /** Requested scopes, seperated by spaces - eg: 'email name' */
            scope: 'email name',
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: process.env.NEXT_PUBLIC_DEFAULT_APPLE_URL!,
            /** State string that is returned with the apple response */
            state: 'state',
            /** Nonce */
            nonce: 'nonce',
            /** Uses popup auth instead of redirection */
            usePopup: true,
          }} // REQUIRED
          /** General props */
          uiType='dark'
          /** className */
          className=''
          /** Removes default style tag */
          noDefaultStyle={true}
          /** Extra controlling props */
          /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
          // @ts-ignore
          onSuccess={(response) => {
            const idToken = response?.authorization?.id_token
            const firstName = response?.user?.name?.firstName
            const lastName = response?.user?.name?.lastName

            if (!idToken) {
              return
            }

            const data = {
              redirect: false,
              grantType: 'sso',
              ssoAccessToken: idToken,
              provider: 'apple',
            }

            if (firstName && lastName) {
              // @ts-ignore
              data['fullName'] = `${firstName} ${lastName}`
            }

            mutate({
              ssoAccessToken: idToken,
              provider: 'apple',
            } as SsoRequest)
          }}
          /** Called upon signin error */
          // @ts-ignore
          onError={(error) => console.error(error)} // default = undefined
          /** Skips loading the apple script if true */
          skipScript={false} // default = undefined
          /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
          //@ts-ignore
          render={(props) => (
            <button
              {...props}
              className='btn flex h-13 flex-auto basis-1/2 items-center justify-center rounded-none border border-fizz-1000 bg-white font-fjalla text-base uppercase text-black transition duration-150 hover:bg-white hover:bg-opacity-70 '
            >
              <Image
                className={'mr-1.5'}
                src={Apple}
                width={24}
                height={24}
                alt={'Apple icon'}
              />
              Apple
            </button>
          )}
        />
      </div>
    </div>
  )
}
