import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { postLogout } from '../../api/auth/auth'

export function useLogoutUser() {
  const { data } = useSession()

  return useMutation(async () =>
    // @ts-ignore
    postLogout(data?.accessToken),
  )
}
