import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { postPing } from '../../api/users/users'

export function usePostPing() {
  const { data } = useSession()

  return useMutation(async () =>
    // @ts-ignore
    postPing(data?.accessToken),
  )
}
