import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { getMarketingOffers } from '../../api/marketing/marketing'
import { MarketingOffersResponse } from '../../types/marketing/response/MarketingOffersResponse'
import { useRecoilState } from 'recoil'
import {
  activeRequestState,
  isOpenLimitedTimeOfferModalState,
} from '../../common/atoms/checkout'

export const useGetMarketingOffers = () => {
  const { data, status } = useSession()
  const [, setIsOpen] = useRecoilState(isOpenLimitedTimeOfferModalState)
  const [activeRequest] = useRecoilState(activeRequestState)

  return useQuery<MarketingOffersResponse[]>(
    ['marketing offers'],
    //@ts-ignore
    async () => getMarketingOffers(data?.accessToken),

    {
      enabled:
        status === SessionStatusEnum.AUTHENTICATED && activeRequest === null,
      staleTime: 3600 * 24,
      cacheTime: 3600 * 24 + 300,
      onSuccess: (received: null | MarketingOffersResponse[]) => {
        if (received && received.length > 0) {
          setIsOpen(true)
        }
      },
    },
  )
}
