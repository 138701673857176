import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'
import { inviteTeammateToTeam } from '../../api/teams/teams'

export function useInviteTeammateToTeam() {
  const { data } = useSession()

  return useMutation(async (request: { teamId: string; teammateId: string }) =>
    // @ts-ignore
    inviteTeammateToTeam(data?.accessToken, request.teamId, request.teammateId),
  )
}
