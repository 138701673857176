import React, { useState } from 'react'
import CollapseChild from './CollapseChild'
import { TeamFriendlistResponse } from '../../../../types/teams/responses/team-friendlist-response'
import { TeamResponse } from '../../../../types/teams/responses/team-response'

type Props = {
  team?: TeamResponse
  teamFriendlist?: TeamFriendlistResponse
  smallScreen?: boolean
}

export default function CollapseParent({
  team,
  teamFriendlist,
  smallScreen,
}: Props) {
  const [isOpen1, setIsOpen1] = useState(true)
  const [isOpen2, setIsOpen2] = useState(true)

  const handleToggle1 = () => {
    setIsOpen1(!isOpen1)
  }

  const handleToggle2 = () => {
    setIsOpen2(!isOpen2)
  }

  const headerHeight = '45px'
  const maxHeight1 = isOpen1 ? '40%' : headerHeight
  const maxHeight2 =
    isOpen1 && (teamFriendlist?.favorites?.length ?? 0) > 0
      ? '60%'
      : 'calc(100% - 45px)'

  return (
    <div className={'flex flex-col h-full'}>
      <CollapseChild
        team={team}
        title='Favorite Teammates'
        mitrensRecommendSupplier={teamFriendlist?.favorites}
        smallScreen={smallScreen}
        isOpen={isOpen1}
        toggleOpen={handleToggle1}
        maxHeight={maxHeight1}
      />

      <CollapseChild
        team={team}
        title='AI Recommended'
        mitrensRecommendSupplier={teamFriendlist?.non_favorites}
        smallScreen={smallScreen}
        isOpen={isOpen2}
        toggleOpen={handleToggle2}
        maxHeight={maxHeight2}
      />
    </div>
  )
}
