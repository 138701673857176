import React from 'react'
import Image from 'next/image'
import { ChampionResponse } from '../../../../../types/users/responses/champion-response'

type Props = {
  champion: ChampionResponse
}

export default function ChampionItem({ champion }: Props) {
  return (
    <div
      className={
        'flex flex-col gap-1 justify-center items-center w-full h-full'
      }
    >
      <div className={'w-full h-0 pb-[100%] relative rounded-lg'}>
        <Image
          src={champion.imageUrl ?? process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!}
          className={
            'object-cover rounded-lg absolute top-0 left-0 w-full h-full'
          }
          layout='fill'
          alt={`${champion.name} skin`}
        />
      </div>
      <p
        className={
          'line-clamp-1 text-fizz-0 text-xs-normal text-ellipsis w-full text-center'
        }
      >
        {champion.name}
      </p>
    </div>
  )
}
