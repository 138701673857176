import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import duration from 'dayjs/plugin/duration'
import { ProductResponse } from '../../types/products/product-response'

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(duration)

export function isBetweenHappyHour(
  open: string,
  closed: string,
  now_time?: string,
): boolean {
  let now: dayjs.Dayjs
  if (now_time) {
    now = dayjs.utc(now_time)
  } else {
    now = dayjs.utc()
  }

  let isProductAvailable = false

  let openedHour = dayjs
    .utc(open)
    .set('year', now.year())
    .set('month', now.month())
    .set('date', now.date())

  let closedHour = dayjs
    .utc(closed)
    .set('year', now.year())
    .set('month', now.month())
    .set('date', now.date())

  if (openedHour.isAfter(closedHour)) {
    const endOfDay = dayjs
      .utc()
      .set('year', now.year())
      .set('month', now.month())
      .set('date', now.date())
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)

    if (
      now.isBetween(openedHour, endOfDay.add(1, 'day'), null, '[]') ||
      now.isBetween(endOfDay, closedHour, null, '[]')
    ) {
      isProductAvailable = true
    }
  } else {
    if (now.isBetween(openedHour, closedHour, null, '[]')) {
      isProductAvailable = true
    }
  }

  return isProductAvailable
}

export function waitingTime(
  product?: ProductResponse,
  withRandomFactor = true,
  isBase = false,
) {
  let count: number

  if (!product) {
    return 1
  }

  if (product.mode === 'marketplace') {
    return 10
  } else if (
    product.streamer?.stream?.current_viewer_count !== undefined &&
    product.streamer?.stream?.current_viewer_count !== null
  ) {
    if (product.streamer?.stream?.current_viewer_count < 100) {
      count = 1
    } else if (
      product.streamer?.stream?.current_viewer_count >= 100 &&
      product.streamer?.stream?.current_viewer_count < 500
    ) {
      count = 2
    } else if (
      product.streamer?.stream?.current_viewer_count >= 500 &&
      product.streamer?.stream?.current_viewer_count < 1000
    ) {
      count = 3
    } else if (
      product.streamer?.stream?.current_viewer_count >= 1000 &&
      product.streamer?.stream?.current_viewer_count < 2000
    ) {
      count = 4
    } else {
      count = 5
    }
    return count
  } else if (product.slug === 'pro-teammate-diablo-4') {
    count = 2
  } else if (product.slug === 'main-story-diablo-4') {
    count = 4
  } else if (product.slug === 'dungeon-run-diablo-4') {
    count = 2
  } else if (product.slug === 'world-tiers-diablo-4') {
    count = 3
  } else if (
    product.slug === 'egirl-league-of-legends' ||
    product.slug === 'eguy-league-of-legends'
  ) {
    count = 7
  } else if (product.maxSlots && product.maxSlots > 2) {
    count = 7
  } else if (product.categoryKey === 'call-of-duty-warzone') {
    count = 4
  } else if (product.categoryKey === 'teamfight-tactics') {
    count = 8
  } else if (product.title === 'Duo') {
    count = 1
  } else if (product.title === 'Duo Ultra') {
    count = 3
  } else if (product.slug.includes('coach') || product.slug.includes('1v1')) {
    count = 2
  } else {
    count = 3
  }

  if (isBase) {
    return count
  }

  if (withRandomFactor) {
    return (new Date().getMinutes() % 3) + count
  }

  return count
}
