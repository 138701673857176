import React from 'react'
import Image, { StaticImageData } from 'next/image'

type Props = {
  title: string
  description: string
  image?: StaticImageData
}

export function HowItWorkPart({ title, description, image }: Props) {
  return (
    <div className={'flex-rol z-10 flex w-full items-start gap-4'}>
      <Image
        src={image ?? process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!}
        alt={'Benefit icon'}
        className={'mt-1 shrink-0 object-contain'}
        width={60}
        height={60}
      />

      <div className={'flex flex-col gap-1'}>
        <div className={'text-lg-normal text-white'}>{title}</div>

        <div className={'text-body-light text-darul-100'}>{description}</div>
      </div>
    </div>
  )
}
