import React from 'react'
import { useTranslation } from 'next-i18next'
import { useRecoilState } from 'recoil'
import OrderButtonEffect from '../../public/static/order_button_effect.svg'
import Image from 'next/image'
import { AddonResponse } from '../../types/addons/responses/AddonResponse'
import { defaultCurrencyState } from '../../common/atoms/atoms'
import { formatAmountWithCurrency } from '../../common/utils/currency.utils'
import ConfirmButton from '../select-game/game-lobby/confirmbtn/ConfirmButton'
import WaitingForTeammateButton from '../select-game/game-lobby/confirmbtn/WaitingForTeammateButton'
import ConfirmButtonShineIcon from '../../public/static/select-mode/icon-waiting-btn-shine.svg'

type Props = {
  onSubmit: () => void
  isLoading: boolean
  total?: number
  title?: string
  theme?: string
  showAnimatedEffect?: boolean
  addons?: AddonResponse[]
  hexagonalButton?: boolean
  waitingForInvite?: boolean
}

export default function StripePaymentButton({
  onSubmit,
  isLoading,
  total,
  title,
  theme = 'light',
  showAnimatedEffect = false,
  hexagonalButton,
  waitingForInvite,
}: Props) {
  const { t: booking } = useTranslation('booking')
  const [currency] = useRecoilState(defaultCurrencyState)

  const buttonClassName = `absolute top-0 left-0 w-full no-animation ${
    theme === 'light'
      ? `${waitingForInvite ? 'btn-v2-bg-fizz-800' : 'btn-v2-primary'} font-fjalla text-lg `
      : theme === 'dark'
        ? 'btn-v2-fizz-600 font-fjalla text-lg'
        : 'btn h-15 border-none bg-transparent font-fjalla text-lg text-black hover:bg-transparent'
  } ${
    total === undefined || total === null
      ? 'pointer-events-none opacity-60'
      : ''
  } ${total && total > 10000 ? 'pointer-events-none opacity-60' : ''}`

  const buttonText = waitingForInvite
    ? 'INVITE TEAMMATE TO START'
    : total && total > 10000
      ? 'Unavailable'
      : total === undefined || total === null
        ? `${title ?? booking('confirmButtonTitle')} •`
        : `${title ?? booking('confirmButtonTitle')} • 
      ${formatAmountWithCurrency(+total, currency, 2)}
     `

  if (hexagonalButton && waitingForInvite) {
    return (
      <WaitingForTeammateButton
        onClick={() => {
          if (isLoading) return
          onSubmit()
        }}
        buttonText={buttonText}
        isLoading={isLoading}
      />
    )
  }

  if (hexagonalButton) {
    return (
      <ConfirmButton
        onClick={() => {
          if (isLoading) return
          onSubmit()
        }}
        buttonText={buttonText}
        isLoading={isLoading}
      />
    )
  }

  return (
    <div className='relative h-15 w-full hover:opacity-80 transition duration-300'>
      <button
        onClick={(e) => {
          e.preventDefault()
          if (isLoading) return
          onSubmit()
        }}
        className={buttonClassName}
      >
        {isLoading && <span className='loading loading-spinner' />}
        <span className={'z-20'}>{buttonText}</span>
      </button>

      {showAnimatedEffect && (
        <div className={'checkout-button h-15 w-16 pointer-events-none'}>
          <Image
            src={waitingForInvite ? ConfirmButtonShineIcon : OrderButtonEffect}
            className={'object-cover'}
            fill={true}
            alt={'right arrow icon'}
          />
        </div>
      )}
    </div>
  )
}
