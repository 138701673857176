import { UserLocalstorage } from '../../types/users/user-local-storage'

const USER_LOCALSTORAGE_KEY = 'user_control'

// helper to get user from localstorage
export function getStoredControl(): UserLocalstorage | null {
  try {
    if (typeof window === 'undefined') {
      return null
    }
    const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY)
    return storedUser ? JSON.parse(storedUser) : null
  } catch (e) {
    return null
  }
}

export function setStoredControl(user: UserLocalstorage): void {
  try {
    if (typeof window === 'undefined') {
      return
    }
    localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user))
  } catch (e) {}
}

export function updateStoredControl(user: UserLocalstorage): void {
  try {
    if (typeof window === 'undefined') {
      return
    }

    let storedUser = getStoredControl()
    if (storedUser) {
      storedUser.selectedTab = user.selectedTab
        ? user.selectedTab
        : storedUser.selectedTab
      storedUser.selectedGame = user.selectedGame
        ? user.selectedGame
        : storedUser.selectedGame
      storedUser.selectedProduct = user.selectedProduct
        ? user.selectedProduct
        : storedUser.selectedProduct
      localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(storedUser))
    } else {
      setStoredControl(user)
    }
  } catch (e) {}
}

export function clearStoredControl(): void {
  if (typeof window === 'undefined') {
    return
  }
  try {
    localStorage.removeItem(USER_LOCALSTORAGE_KEY)
  } catch (e) {}
}
