import { useRecoilState } from 'recoil'
import { activeRequestState, promoState } from '../../common/atoms/checkout'
import { useSession } from 'next-auth/react'
import { useGetHomeRequests } from '../fans/useGetRequests'
import { useEffect, useRef } from 'react'
import { RequestStatusEnum } from '../../common/enums/request-status.enum'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useAudioPlayer } from 'react-use-audio-player'
import { useRouter } from 'next/router'
import { inSessionUrl } from '../../common/urls/homeUrl'
import { activeRequests } from '../../common/react-query/react-query-keys'
import { useQueryClient } from '@tanstack/react-query'

export function useHomeActiveRequest() {
  const { status } = useSession()
  const [activeRequest, setActiveRequest] = useRecoilState(activeRequestState)
  const { data: requests, refetch } = useGetHomeRequests()
  const interval = useRef<any | null>(null)
  const [promoCode, setPromoCode] = useRecoilState(promoState)
  const { load, play } = useAudioPlayer()
  const router = useRouter()
  const clientQuery = useQueryClient()

  function playAudio() {
    play()
  }

  useEffect(() => {
    try {
      clientQuery.removeQueries([activeRequests])
    } catch (e) {}

    load('/lobby-audio.mp3', {
      autoplay: false,
      html5: true,
    })

    interval.current = setInterval(() => {
      refetch()
    }, 20000)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  useEffect(() => {
    if (status !== SessionStatusEnum.AUTHENTICATED) {
      return
    }

    if (requests && (requests.data?.length ?? 0) === 0 && activeRequest) {
      setActiveRequest(null)
      return
    }

    if (requests && requests.data && requests.data.length > 0) {
      const request = requests.data[0]

      // if (activeRequest?.status !== request.status) {
      //   refetch()
      // }

      switch (request?.status) {
        case RequestStatusEnum.PROOFING:
        case RequestStatusEnum.COMPLETED:
          if (
            activeRequest &&
            activeRequest.status !== RequestStatusEnum.COMPLETED &&
            activeRequest.status !== RequestStatusEnum.PROOFING &&
            activeRequest.status !== request.status
          ) {
            setActiveRequest(request)
            if (promoCode?.type !== 'campaign' && promoCode?.type !== 'forever')
              setPromoCode(null)
          }
          break
        case RequestStatusEnum.ACTIVE:
          router.replace(inSessionUrl)

          if (activeRequest && activeRequest.status !== request.status) {
            playAudio()
          }
          if (
            !activeRequest ||
            activeRequest?.id === -1 ||
            activeRequest.status !== request.status ||
            activeRequest.askToCancelOrder !== request.askToCancelOrder
          ) {
            setActiveRequest(request)
          }

          break
        case RequestStatusEnum.PENDING:
          router.replace(inSessionUrl)

          if (
            !activeRequest ||
            activeRequest?.id === -1 ||
            activeRequest.status !== request.status
          ) {
            setActiveRequest(request)
          }
          break
        case RequestStatusEnum.CANCELED:
          break
        default:
          break
      }
    }
  }, [requests])
}
