import isString from 'lodash.isstring'

export interface ErrorResponse {
  statusCode?: number
  message?: string[]
  error?: string
}

export function readableError(error: ErrorResponse | null | undefined): string {
  if (!error) return 'Something went wrong.'
  if (isString(error.message)) return error.message
  return error.message?.join('. ') ?? 'Something went wrong.'
}
