import React from 'react'
import Image from 'next/image'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import AddIcon from '../../public/static/add-credit-card.svg'
import { useRecoilState } from 'recoil'
import { isOpenWalletState } from '../../common/atoms/checkout'
import { useSession } from 'next-auth/react'

interface Props {
  theme?: 'light' | 'dark'
}

export default function NoCreditCard({ theme }: Props) {
  const [, setIsOpenWallet] = useRecoilState(isOpenWalletState)
  const { status } = useSession()

  return (
    <div
      className={'flex w-full flex-row items-center gap-2'}
      onClick={(e) => {
        e.preventDefault()
        setIsOpenWallet(true)
      }}
    >
      <div
        className={`${
          status === SessionStatusEnum.LOADING ? 'invisible' : ''
        } relative h-6 w-6`}
      >
        <Image
          src={AddIcon}
          fill={true}
          className={'object-contain'}
          alt={'Add icon'}
        />
      </div>

      <p
        className={`${
          status === SessionStatusEnum.LOADING ? 'invisible' : ''
        } text-body-medium ml-2 ${
          theme === 'light' ? ' text-fizz-1000 ' : ' text-white '
        }`}
      >
        {status === SessionStatusEnum.UNAUTHENTICATED
          ? 'Add Promo Code'
          : 'Add Payment Method'}
      </p>
    </div>
  )
}
