import { authFetch } from '../../common/networking/auth-fetch'
import { PaginationRequest } from '../../types/common/requests/pagination.request'
import { RequestResponse } from '../../types/requests/responses/request-response'
import { DashboardDataResponse } from '../../types/dashboard/dashboard-data-response'
import { RecentRequestsResponse } from '../../types/requests/responses/recent-requests.response'
import { RequestPaginationResponse } from '../../types/requests/responses/request-pagination-response'
import { SupplierStatus } from '../../types/dashboard/supplier-status.response'
import { AvailableInvitesResponse } from '../../types/requests/responses/available-invites-response'
import { BanStatusResponse } from '../../types/dashboard/ban-status-response'
import { PayoutSummaryResponse } from '../../types/dashboard/payout-summary-response'
import { PayoutResponse } from '../../types/dashboard/payout-response'
import { InviteCustomerRequest } from '../../types/teams/requests/invite-customer-request'
import { CustomerInviteResponse } from '../../types/teams/responses/customer-invite-response'

export async function getSupplierStatus(
  accessToken: any,
): Promise<SupplierStatus> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/my/teammate-status`,
  )

  return await response.json()
}

export async function getDashboardData(
  accessToken: any,
): Promise<DashboardDataResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/data`,
  )

  return await response.json()
}

export async function getPayoutSummary(
  accessToken: any,
): Promise<PayoutSummaryResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/payouts/summary`,
  )

  return await response.json()
}

export async function getPayouts(
  accessToken: any,
  page: PaginationRequest,
): Promise<PayoutResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/payouts?page=${page.page}`,
  )

  return await response.json()
}

export async function putUpdateTeammateStatus(
  accessToken: any,
  status: string,
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/teammates/status`,
    {
      status,
    },
  )
}

export async function getOrders(
  accessToken: any,
  page: PaginationRequest,
): Promise<RecentRequestsResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/teammates/orders?page=${page.page}`,
  )

  return await response.json()
}

export async function getPayoutsByUserId(
  accessToken: any,
  id: string,
  page: PaginationRequest,
): Promise<PayoutResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/payouts/users/${id}?page=${page.page}`,
  )

  return await response.json()
}

export async function getHistoryRequest(
  accessToken: any,
  page: PaginationRequest,
): Promise<RequestPaginationResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/requests?pageSize=${page.pageSize}&page=${page.page}`,
  )

  return await response.json()
}

export async function getAvailableRequests(
  accessToken: any,
): Promise<RequestResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/teammates/requests`,
  )

  return await response.json()
}

export async function postPing(accessToken: any): Promise<Response> {
  const response = await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/pings`,
  )

  return await response.json()
}

export async function getAvailableInvites(
  accessToken: any,
): Promise<AvailableInvitesResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/teams/invites`,
  )

  return await response.json()
}

export async function postAcceptInvite(
  accessToken: any,
  teamId: string,
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/teams/${teamId}/invites/accept`,
  )
}

export async function deleteRejectInvite(
  accessToken: any,
  teamId: string,
): Promise<Response> {
  return await authFetch(accessToken).delete(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/teams/${teamId}/invites/reject`,
  )
}

export async function getBanStatus(
  accessToken: any,
): Promise<BanStatusResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/ban/status`,
  )

  return await response.json()
}

export async function getCustomers(accessToken: any): Promise<
  [
    {
      id: number
      username?: string
      avatar?: string
    },
  ]
> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/dashboard/customers`,
  )

  return await response.json()
}

export async function postInviteCustomer(
  accessToken: any,
  request: InviteCustomerRequest,
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/customers/invites`,
    request,
  )
}

export async function getTeammateInvites(
  accessToken: any,
): Promise<CustomerInviteResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/teammates/invites`,
  )

  return await response.json()
}

export async function getCustomerInvites(
  accessToken: any,
): Promise<CustomerInviteResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/customers/invites`,
  )

  return await response.json()
}

export async function postRejectCustomerInvite(
  accessToken: any,
  id: string,
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/teams/customers/invites/${id}/reject`,
  )
}

export async function getShowAgreement(accessToken: any): Promise<{
  status: string
}> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/agreements`,
  )

  return await response.json()
}

export async function postAcceptAgreement(accessToken: any): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/agreements`,
  )
}
