import { CurrencyResponse } from '../../types/currencies/responses/currency-response'
import { CreditAmountWithMultipleCurrencyResponse } from '../../types/users/CreditAmountWithMultipleCurrencyResponse'
import { CurrencySymbolPositionEnum } from '../enums/currency-symbol-position.enum'
import { isNil } from './common.utils'

export function getCurrentCreditAmountWithMultipleCurrencyResponse(
  currencies: CreditAmountWithMultipleCurrencyResponse[],
  defaultCurrencyId?: number | null,
): CreditAmountWithMultipleCurrencyResponse | undefined {
  return (
    currencies.find((currency) => {
      if (defaultCurrencyId) {
        return currency.currency.id === defaultCurrencyId
      }
      return currency.isDefault
    }) || currencies.find((currency) => currency.currency.id === 1)
  )
}

export function formatAmountWithCurrency(
  amount: number = 0,
  currency: CurrencyResponse | null | undefined,
  decimalPlaces?: number,
): string {
  const formattedAmount = amount

  if (currency?.symbolPosition === CurrencySymbolPositionEnum.LEADING) {
    if (!isNil(decimalPlaces)) {
      return `${currency?.currencySymbol ?? ''}${toDecimalPlaces(formattedAmount, decimalPlaces ?? 2)}`
    }
    return `${currency?.currencySymbol ?? ''}${formattedAmount}`
  } else if (currency?.symbolPosition === CurrencySymbolPositionEnum.TRAILING) {
    if (!isNil(decimalPlaces)) {
      return `${toDecimalPlaces(formattedAmount, decimalPlaces ?? 2)}${currency?.currencySymbol ?? ''}`
    }
    return `${formattedAmount}${currency?.currencySymbol ?? ''}`
  } else if (
    currency?.currencySymbolPosition === CurrencySymbolPositionEnum.TRAILING
  ) {
    if (!isNil(decimalPlaces)) {
      return `${toDecimalPlaces(formattedAmount, decimalPlaces ?? 2)}${currency?.currencySymbol ?? ''}`
    }
    return `${formattedAmount}${currency?.currencySymbol ?? ''}`
  } else if (
    currency?.currencySymbolPosition === CurrencySymbolPositionEnum.LEADING
  ) {
    if (!isNil(decimalPlaces)) {
      return `${currency?.currencySymbol ?? ''}${toDecimalPlaces(formattedAmount, decimalPlaces ?? 2)}`
    }
    return `${currency?.currencySymbol ?? ''}${formattedAmount}`
  }

  if (!isNil(decimalPlaces)) {
    return `${toDecimalPlaces(formattedAmount, decimalPlaces ?? 2)}`
  }
  // Default to just the amount if no symbolPosition provided.
  return `${formattedAmount}`
}

export function toDecimalPlaces(value: number, decimalPlaces: number): string {
  return value.toFixed(decimalPlaces)
}
