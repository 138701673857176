import React from 'react'

type Props = {
  title: string
  type?: string
}
export default function SmallFriendCardTag({ title, type = 's' }: Props) {
  function tagColor() {
    switch (type) {
      case 's':
        return 'fill-gnar-400'
      case 'a':
        return 'fill-ashe-400'
      case 'b':
        return 'fill-thresh-300'
      default:
        return 'fill-thresh-300'
    }
  }

  // function textFont() {
  //   switch (type) {
  //     case 'ultra':
  //       return 'text-xs-normal'
  //     case 'god':
  //       return 'text-xs-normal'
  //     case 'rank':
  //       return 'text-xs-normal'
  //   }
  // }

  return (
    <div className={'relative'}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='23'
        viewBox='0 0 20 23'
        fill='none'
      >
        <path
          className={`${tagColor()}`}
          d='M18 0H2C0.895431 0 0 0.895429 0 2V17.1716C0 17.9913 0.50013 18.728 1.26194 19.0305L9.26193 22.2069C9.73598 22.3952 10.264 22.3952 10.7381 22.2069L18.7381 19.0305C19.4999 18.728 20 17.9913 20 17.1716V2C20 0.895431 19.1046 0 18 0Z'
          fill='#F4A400'
        />
      </svg>

      <p
        className={
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-black font-semibold text-[12px] font-oswald'
        }
        style={{
          verticalAlign: 'middle',
          transform: 'translateY(-5%) translateY(-50%) translateX(-50%)',
        }}
      >
        {title}
      </p>
    </div>
  )
}
