import React from 'react'
import { ChampionResponse } from '../../../../../types/users/responses/champion-response'
import Image from 'next/image'

type Props = { champion?: ChampionResponse; onClick: () => void }
export default function ChampionOption({ champion, onClick }: Props) {
  return (
    <div
      className={
        'border-fizz-500 bg-fizz-800 border w-full flex flex-row items-center justify-between h-12 px-3 rounded-lg hover:border-fizz-500 hover:bg-fizz-600 cursor-pointer'
      }
      onClick={onClick}
    >
      {champion ? (
        <div className={'flex flex-row items-center grow gap-2'}>
          <div className={'relative h-8 w-8 rounded-full'}>
            <Image
              src={
                champion?.imageUrl ??
                process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
              }
              className={'rounded-full object-cover'}
              fill={true}
              alt={'Avatar image'}
            />
          </div>

          <div className={'text-white text-body-normal'}>{champion.name}</div>
        </div>
      ) : (
        <div className={'text-white text-body-normal'}>Select...</div>
      )}

      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M4 6L8 10L12 6'
          stroke='#585860'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </div>
  )
}
