import { useRecoilState } from 'recoil'
import { ChargeResponse } from '../../types/currencies/responses/charge-response'
import { defaultCurrencyState } from '../../common/atoms/atoms'

export function useLocalizedPrice() {
  const [currency] = useRecoilState(defaultCurrencyState)

  function getLocalizedPrice(
    charges?: ChargeResponse[],
  ): ChargeResponse | undefined {
    return charges?.find((charge) => {
      return charge.currency?.id === (currency?.id ?? 1)
    })
  }

  return {
    getLocalizedPrice,
  }
}
