import { useMutation } from '@tanstack/react-query'
import { postRegisterGuestToUser } from '../../api/auth/auth'
import { useSession } from 'next-auth/react'
import { LoginRequest } from '../../types/auth/requests/login-request'

export function useConvertGuestToUser() {
  const { data } = useSession()

  return useMutation(async (request: LoginRequest) =>
    // @ts-ignore
    postRegisterGuestToUser(data?.accessToken, request),
  )
}
