import React from 'react'

type Props = {
  id: number
  value: string
  selectedState: [
    { id: number; value: string },
    React.Dispatch<React.SetStateAction<{ id: number; value: string }>>,
  ]
  onSelected: (id: number) => void
}

export default function RadioButton({
  id,
  value,
  selectedState,
  onSelected,
}: Props) {
  const [selected, setSelected] = selectedState

  return (
    <div>
      <input
        className='peer hidden'
        type='radio'
        id={`radio ${value} id`}
        value={id}
        checked={selected.id === id}
        onChange={() => {}}
      />

      <label
        className='rounded-lg btn-primary-dark-sm-v2 w-fit select-none font-manrope font-semibold peer-checked:bg-white peer-checked:text-darul-900'
        onClick={(e) => {
          e.preventDefault()
          setSelected({ id: id, value: value })
          onSelected(id)
        }}
      >
        {value}
      </label>
    </div>
  )
}
