import React from 'react'
import Image from 'next/image'
import { LolAccountSkin } from '../../../../../types/account/lol-account-skin'
import useSkinCard from './useSkinCard'

type Props = {
  lolAccountSkin: LolAccountSkin
}

export default function SkinItem({ lolAccountSkin }: Props) {
  const { getMatchedRank, defaultRank } = useSkinCard()
  const rank =
    getMatchedRank(lolAccountSkin.skin.rarity ?? 'kNoRarity') ?? defaultRank
  const borderColor = rank?.color ?? 'border-skin-standard'
  const rarityImage = rank?.image

  return (
    <div
      className={
        'flex flex-col gap-1 justify-center items-center w-full h-full relative'
      }
    >
      {rarityImage && (
        <div className={'absolute top-2 left-2 w-5 h-5 shrink-0 z-10'}>
          <Image
            src={rarityImage}
            alt={''}
            fill={true}
            className={'object-cover'}
          />
        </div>
      )}

      <div
        className={`w-full h-0 pb-[182%] relative rounded-lg border ${borderColor} `}
      >
        <Image
          src={
            lolAccountSkin.skin.imageUrl ??
            process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
          }
          className={'object-cover rounded-lg'}
          fill={true}
          alt={`${lolAccountSkin.skin.name} skin`}
        />
      </div>
      <p
        className={
          'line-clamp-1 text-fizz-0 text-xs-normal text-ellipsis w-full'
        }
      >
        {lolAccountSkin.skin.champion.name}
      </p>

      <p
        className={
          'line-clamp-1 text-fizz-0 text-xs-normal text-ellipsis  w-full'
        }
      >
        {lolAccountSkin.skin.name}
      </p>
    </div>
  )
}
