import { CategoryResponse } from '../../types/categories/responses/category-response'
import { isBetweenHappyHour } from '../../common/utils/time.utils'

export function useOnlineTeammates() {
  function calculateTeammates(
    category: CategoryResponse,
    isBase = false,
  ): number {
    let count = 21
    let base = 1

    if (isBetweenHappyHour('2023-03-04 04:00:00', '2023-03-04 10:00:00')) {
      base = 0.9
    } else if (
      isBetweenHappyHour('2023-03-04 10:00:00', '2023-03-04 13:00:00')
    ) {
      base = 0.8
    }

    if (category.key === 'call-of-duty-warzone') {
      count = 16
    } else if (category.key === 'teamfight-tactics') {
      count = 28
    } else if (category.key === 'valorant') {
      count = 142
    } else if (category.key === 'diablo-4') {
      count = 23
    } else if (category.key === 'fortnite') {
      count = 87
    } else if (category.key === 'league-of-legends') {
      count = 331
    } else if (category.key === 'fifa') {
      count = 18
    } else if (category.key === 'counter-strike-global-offensive') {
      count = 27
    }

    if (isBase) return count

    return Math.trunc((new Date().getMinutes() % 6) + count * base)
  }

  return {
    calculateTeammates,
  }
}
