import React from 'react'
import SkinItem from './SkinItem'
import { AccountMarket } from '../../../../../hooks/accounts/useAccountChampion'
import SearchInput from '../SearchInput'
import EmptySection from '../EmptySection'

type Props = { accountMarket: AccountMarket }

export default function SkinsSection({ accountMarket }: Props) {
  const { skins, isLoadingSkins, setSearch, search } = accountMarket
  return (
    <div className={'flex flex-col w-full grow  md:overflow-y-auto'}>
      <SearchInput search={search} setSearch={setSearch} />

      {isLoadingSkins ? (
        <div />
      ) : skins.length === 0 ? (
        <div className={'mt-5'}>
          <EmptySection type={'champion'} />
        </div>
      ) : (
        <div
          className={
            'grid grid-cols-5 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 w-full  items-start md:overflow-y-auto  mt-5  grow  md:h-128  content-start pr-2 '
          }
        >
          {skins.map((item, index) => (
            <SkinItem key={item.id + item.skin} lolAccountSkin={item} />
          ))}
        </div>
      )}
    </div>
  )
}
