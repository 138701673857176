import { useGetCategoriesTag } from '../application/useGetCategoryTag'
import { useEffect } from 'react'
import groupBy from 'lodash.groupby'
import { useRecoilState } from 'recoil'
import { categoryGroupTagsState } from '../../common/atoms/checkout'
import { selectedGameState } from '../../common/atoms/atoms'

export function useTags() {
  const [game] = useRecoilState(selectedGameState)
  const { data: tags } = useGetCategoriesTag(game?.key)
  const [, setGroupedTags] = useRecoilState(categoryGroupTagsState)

  useEffect(() => {
    if (tags && tags.length > 0) {
      setGroupedTags(groupBy(tags, (item) => item.parent.name))
    }
  }, [tags])
}
