import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { getGiftingMessages } from '../../api/tips/tips'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { tipsFeedbackFromTeammateState } from '../../common/atoms/atoms'

export const useGetGiftingMessages = () => {
  const { data, status } = useSession()
  const [tippingMessage, setTippingMessage] = useRecoilState(
    tipsFeedbackFromTeammateState,
  )

  const { data: messages } = useQuery<
    { message: string; teammateId: number }[]
  >(
    ['tipping_messages'],
    //@ts-ignore
    async () => getGiftingMessages(data?.accessToken),

    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )

  useEffect(() => {
    if (messages && messages.length > 0 && !tippingMessage) {
      setTippingMessage({
        id: messages[0]?.teammateId,
        message: messages[0].message,
      })
    }
  }, [messages])
}
