import { useGetCurrencies } from './useGetCurrencies'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { defaultCurrencyState } from '../../common/atoms/atoms'
import Cookies from 'js-cookie'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useUpdateUser } from '../users/useUpdateUser'
import { useSession } from 'next-auth/react'
import { useUser } from '../users/useUser'

export function useDefaultCurrency() {
  const [, setCurrentCurrency] = useRecoilState(defaultCurrencyState)
  const { mutate, isSuccess, isError } = useUpdateUser()
  const { data: currencies, remove: refetchCurrencies } = useGetCurrencies(
    Cookies.get('currency'),
  )
  const { status } = useSession()
  const { refetch: refetchUser } = useUser()

  useEffect(() => {
    if (isSuccess || isError) {
      refetchCurrencies()
      if (status === SessionStatusEnum.AUTHENTICATED) refetchUser()
    }
  }, [isSuccess, isError])

  useEffect(() => {
    if (currencies) {
      const defaultCurrency = currencies.find((currency) => currency.isDefault)
      if (defaultCurrency) {
        setCurrentCurrency(defaultCurrency)
      } else {
        setCurrentCurrency(currencies[0])
      }
    }
  }, [currencies])

  return {
    updateCurrency: mutate,
  }
}
