import { useUser } from '../users/useUser'
import { useRecoilState } from 'recoil'
import {
  productsState,
  promoState,
  quantityState,
} from '../../common/atoms/checkout'
import { useEffect } from 'react'
import { UserReferralCodeTypeEnum } from '../../common/enums/user-referral-code-type.enum'
import { UserResponse } from '../../types/users/user-response'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import isString from 'lodash.isstring'
import { usePostCreateReferralCode } from '../users/usePostCreateReferralCode'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useGetPromoCode } from '../stripe/useGetPromoCode'
import { PromoCodeResponse } from '../../types/stripe/responses/promo-code-response'
import { useGetPromoCodeByBatch } from '../stripe/useGetPromoCodeByBatch'
import { toast } from 'react-toastify'
import { useGetPromoCodeByBatchAndCode } from '../stripe/useGetPromoCodeByBatchAndCode'
import { readableError } from '../../types/errors/responses/error-response'
import {
  getStorePromoCodeV2,
  setStoredPromoCodeV2,
} from '../cache/promo-code-storage-v2'
import { usePostCreateEvent } from '../events/usePostCreateEvent'

function parseReferralCode(user: UserResponse | undefined | null) {
  if (user && user.userReferralCodes && user.userReferralCodes.length > 0) {
    return user.userReferralCodes.find((item) => {
      return item.type === UserReferralCodeTypeEnum.UNUSED
    })
  }
  return null
}

export function usePromoCodes() {
  const { user, refetch: refetchUser } = useUser()
  const [promoCode, setPromoCode] = useRecoilState(promoState)
  const [products] = useRecoilState(productsState)
  const [, setQuantity] = useRecoilState(quantityState)
  const {
    mutate: postReferralCode,
    data,
    error: postReferralCodeError,
    variables,
  } = usePostCreateReferralCode()
  const router = useRouter()
  const { status } = useSession()
  const { mutate: getPromoCode } = useGetPromoCode()
  const { mutate: getPromoCodeByBatch, error: getPromoCodeByBatchError } =
    useGetPromoCodeByBatch()
  const {
    mutate: getPromoCodeByBatchAndCode,
    error: getPromoCodeByBatchAndCodeError,
  } = useGetPromoCodeByBatchAndCode()
  const { mutate: postClickReferralLinkEvent } = usePostCreateEvent()

  function getPromoCodeByBatchAndStore(batch?: string) {
    if (!batch) return
    getPromoCodeByBatch(batch, {
      onSuccess: (data: PromoCodeResponse) => {
        if (data) {
          setPromoCode(data)
          setStoredPromoCodeV2(data)
          toast.success('Promo code applied.')
        }
      },
    })
  }

  function getPromoCodeByBatchAndCodeAndStore(batch?: string, code?: string) {
    if (!batch || !code) return
    getPromoCodeByBatchAndCode(
      { batch, code },
      {
        onSuccess: (data: PromoCodeResponse) => {
          if (data) {
            setPromoCode(data)
            setStoredPromoCodeV2(data)
            toast.success('Promo code applied!')
          }
        },
      },
    )
  }

  function getPromoCodeAndStore(newPromoCode?: string, type?: string) {
    if (!newPromoCode) return
    getPromoCode(
      { promoCode: newPromoCode },
      {
        onSuccess: (data: PromoCodeResponse) => {
          if (data) {
            if (promoCode?.code !== data.code) {
              setPromoCode(data)
              setStoredPromoCodeV2(data)
              toast.success('Promo code applied!')
            }

            if (newPromoCode && type === 'referral_game') {
              postClickReferralLinkEvent({
                type: 'click_referral_link',
                status: 'succeeded',
                promoCode: newPromoCode,
              })
            }
          }
        },
      },
    )
  }

  useEffect(() => {
    if (getPromoCodeByBatchError) {
      toast.error(readableError(getPromoCodeByBatchError))
    }
  }, [getPromoCodeByBatchError])

  useEffect(() => {
    if (getPromoCodeByBatchAndCodeError) {
      toast.error(readableError(getPromoCodeByBatchAndCodeError))
    }
  }, [getPromoCodeByBatchAndCodeError])

  useEffect(() => {
    if (status === SessionStatusEnum.UNAUTHENTICATED && promoCode) {
      setPromoCode(null)
    }
  }, [status])

  useEffect(() => {
    const referralCode = parseReferralCode(user)
    if (
      referralCode?.referralCode &&
      referralCode?.referralCode.isBlocked !== true
    ) {
      setPromoCode(referralCode.referralCode)

      toast.success('Promo code applied.')
    }
  }, [user])

  useEffect(() => {
    if (promoCode) {
      if (promoCode.minQuantity && promoCode.minQuantity > 0) {
        setQuantity(promoCode.minQuantity)
      }
    }
  }, [promoCode, products])

  useEffect(() => {
    if (data?.promoCode) {
      setStoredPromoCodeV2(data.promoCode)
      setPromoCode(data.promoCode)
      refetchUser()

      toast.success('Promo code applied.')

      postClickReferralLinkEvent({
        type: 'click_referral_link',
        status: 'succeeded',
        promoCode: data?.promoCode?.code,
      })
    }
  }, [data])

  useEffect(() => {
    if (postReferralCodeError) {
      postClickReferralLinkEvent({
        type: 'click_referral_link',
        status: 'failed',
        promoCode: variables?.code,
      })
    }
  }, [postReferralCodeError])

  useEffect(() => {
    if (status === SessionStatusEnum.LOADING) return

    const storedPromoCode = getStorePromoCodeV2()
    if (router.isReady && isString(router.query?.marketing)) {
      postClickReferralLinkEvent({
        type: 'click_email_link',
        status: 'succeeded',
        data: {
          marketing: router.query.marketing,
        },
      })
    }

    if (router.isReady && isString(router.query?.campaign)) {
      if (status === SessionStatusEnum.AUTHENTICATED) {
        getPromoCodeByBatchAndStore(router.query.campaign)
      } else if (status === SessionStatusEnum.UNAUTHENTICATED) {
        if (isString(router.query?.code)) {
          getPromoCodeByBatchAndCodeAndStore(
            router.query.campaign,
            router.query.code,
          )
        } else {
          toast.error('Please log in or sign up to apply this promo code.')
        }
      }
    } else if (router.isReady && isString(router.query?.r)) {
      if (status === SessionStatusEnum.AUTHENTICATED) {
        postReferralCode({
          code: router.query.r ?? storedPromoCode,
        })
      } else if (status === SessionStatusEnum.UNAUTHENTICATED) {
        getPromoCodeAndStore(router.query.r, 'referral_game')
      }
    } else if (router.isReady && isString(router.query?.c)) {
      getPromoCodeAndStore(router.query.c, 'campaign')
    } else if (storedPromoCode) {
      if (status === SessionStatusEnum.AUTHENTICATED) {
        if (storedPromoCode.type !== 'referral_game') {
          getPromoCodeAndStore(storedPromoCode.code, storedPromoCode.type)
        } else {
          postReferralCode({ code: storedPromoCode.code })
        }
      } else if (status === SessionStatusEnum.UNAUTHENTICATED) {
        getPromoCodeAndStore(storedPromoCode.code, storedPromoCode.type)
      }
    }
  }, [status, router])

  return {
    getPromoCodeAndStore,
  }
}
