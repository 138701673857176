import React, { useEffect, useRef, useState } from 'react'
import { ChampionResponse } from '../../../../../types/users/responses/champion-response'
import ChampionOption from './ChampionOption'
import ChampionMenu from './ChampionMenu'
import { useClickAway } from 'react-use'

type Props = {
  champions: ChampionResponse[]
  current?: ChampionResponse
  onChange: (selected: ChampionResponse) => void
}

export default function ChampionSelector({
  champions,
  current,
  onChange,
}: Props) {
  const openState = useState<boolean>(false)
  const [open, setOpen] = openState
  const selectedState = useState<ChampionResponse>()
  const [selectedChampion, setSelected] = selectedState

  const ref = useRef(null)
  useClickAway(ref, () => {
    setOpen(false)
  })

  useEffect(() => {
    if (current) {
      setSelected(current)
    }
  }, [current])

  useEffect(() => {
    if (selectedChampion) {
      onChange(selectedChampion)
    }
  }, [selectedChampion])

  return (
    <div ref={ref} key={'selector'}>
      <ChampionOption
        champion={selectedChampion}
        onClick={() => {
          setOpen(!open)
        }}
      />

      <ChampionMenu
        openState={openState}
        champions={champions}
        selectedState={selectedState}
      />
    </div>
  )
}
