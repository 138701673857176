import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { getMarketplaceAccountsChampion } from '../../api/account/account'

import { LolAccountChampion } from '../../types/account/lol-account-champion'

export function useGetAccountChampion(id: string) {
  const { data } = useSession()

  return useQuery<LolAccountChampion[]>(
    ['Lol Accounts Champion', id],
    //@ts-ignore
    async () => getMarketplaceAccountsChampion(id, data?.accessToken),
  )
}
