import { authFetch } from '../../common/networking/auth-fetch'
import { CurrencyResponse } from '../../types/currencies/responses/currency-response'

export async function getCurrencies(
  accessToken: any,
  currencyId: string | null | undefined,
): Promise<CurrencyResponse[]> {
  if (currencyId) {
    const response = await authFetch(accessToken).get(
      `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/currencies?currencyId=${currencyId}`,
    )

    return await response.json()
  }
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/currencies`,
  )

  return await response.json()
}
