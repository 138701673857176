import { initializeApp } from 'firebase/app'
import { deleteToken, getMessaging, getToken } from 'firebase/messaging'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { postCreatePushNotificationToken } from '../../api/firebase/firebase'
import { isNotificationEnableState } from '../../common/atoms/dashboard'
import { useRecoilState } from 'recoil'
import { getStoredNotification } from '../cache/notification-storage'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_DOMAIN,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)

export async function firebaseSignOut() {
  try {
    const messaging = getMessaging(app)
    await deleteToken(messaging)
  } catch (e) {}
}

export function fetchFCMToken(action: (token: string) => void) {
  try {
    const messaging = getMessaging(app)
    getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FIREBASE_FCM_KEY,
    }).then((currentToken) => {
      if (currentToken) {
        action(currentToken)
      }
    })
  } catch (e) {}
}

export function useFirebaseMessaging() {
  const { status, data } = useSession()
  const [, setIsNotificationEnable] = useRecoilState(isNotificationEnableState)

  useEffect(() => {
    try {
      if (
        'Notification' in window &&
        status === SessionStatusEnum.AUTHENTICATED
      ) {
        if (Notification.permission === 'default') {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              fetchFCMToken((token) => {
                //@ts-ignore
                postCreatePushNotificationToken(data?.accessToken, token).then()
              })
            } else if (permission === 'denied') {
              const storedNotification = getStoredNotification()
              if (storedNotification?.showNotification !== false) {
                setIsNotificationEnable(false)
              }
            }
          })
        } else if (Notification.permission === 'granted') {
          fetchFCMToken((token) => {
            //@ts-ignore
            postCreatePushNotificationToken(data?.accessToken, token).then()
          })
        } else if (Notification.permission === 'denied') {
          const storedNotification = getStoredNotification()
          if (storedNotification?.showNotification !== false) {
            setIsNotificationEnable(false)
          }
        }
      }
    } catch (e) {}
  }, [status])
}
