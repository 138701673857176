import React from 'react'
import FAQItem from './FAQItem'
import { FAQItems } from '../../../common/constants/faq'
import Divider from '../../divider/Divider'
import { CategoryResponse } from '../../../types/categories/responses/category-response'
import { GameCategories } from '../../../common/enums/game-categories'

type Props = {
  category?: CategoryResponse
  isHome?: boolean
}

export default function FAQSection({ category, isHome = false }: Props) {
  const items = []

  switch (category?.key) {
    case GameCategories.leagueOfLegends:
      if (isHome) {
        items.push({
          questionName: 'Why pricing varies?',
          acceptedAnswerText:
            "Tapin's pricing model is dynamic, adjusting rates based on your League of Legends rank.",
        })

        items.push({
          questionName: 'Where to enter my League of Legends rank?',
          acceptedAnswerText:
            "We'll analyze your gaming profile using your username and region. You don't need to enter your rank manually.",
        })

        items.push({
          questionName: 'Is it safe to play with a Tapin teammate?',
          acceptedAnswerText:
            "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
        })
      } else {
        items.push({
          questionName: 'Where to enter my League of Legends rank?',
          acceptedAnswerText:
            "We'll analyze your gaming profile using your username and region. You don't need to enter your rank manually.",
        })

        items.push({
          questionName: 'Is it safe to play with a Tapin teammate?',
          acceptedAnswerText:
            "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
        })
      }
      break
    case GameCategories.valorant:
      items.push({
        questionName: 'Is it safe to play with a Tapin teammate?',
        acceptedAnswerText:
          "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
      })

      break
    case GameCategories.csgo:
      items.push({
        questionName: 'Is it safe to play with a Tapin teammate?',
        acceptedAnswerText:
          "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
      })

      break
    case GameCategories.fortnite:
      items.push({
        questionName: 'Is it safe to play with a Fortnite coach?',
        acceptedAnswerText:
          "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
      })
      break
    default:
      break
  }

  items.push(...FAQItems)

  return (
    <div className={'mt-4 flex flex-col'}>
      <div className={'flex flex-col'}>
        <h3 className={'text-2xl-normal uppercase text-white'}>
          Frequently asked questions
        </h3>
        <div className={'mt-2'}>
          <Divider backgroundColor={'border-fizz-500'} />
        </div>
      </div>

      <div className={'mt-4 flex flex-col'}>
        {items.map((item, index) => {
          return (
            <div key={item.questionName} className={'mt-4 w-full'}>
              <FAQItem
                title={item.questionName}
                description={item.acceptedAnswerText}
                hasDivider={index !== items.length - 1}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
