import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { getMyAddOns } from '../../api/users/users'
import { AddonResponse } from '../../types/addons/responses/AddonResponse'

export const useGetMyAddOn = () => {
  const { data, status } = useSession()

  return useQuery<AddonResponse[]>(
    ['my-addons'],
    //@ts-ignore
    async () => getMyAddOns(data?.accessToken),
    {
      enabled: status === SessionStatusEnum.AUTHENTICATED,
    },
  )
}
